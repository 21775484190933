import { FieldValidationMessageArgs, ValidationMessage } from './types'

export function getCustomizedMessage(
    customMessage: ValidationMessage | undefined,
    defaultError: string,
    customLocalizations?: {}
) {
    return (args: FieldValidationMessageArgs) => {
        if (typeof customMessage === 'function') {
            return customMessage(args)
        }
        return args.t(customMessage ?? defaultError, { ...customLocalizations })
    }
}
