import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import clientQueries from '@cashii/common/src/queries/clientQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useAsync from '@hypatia/react-utils/hooks/async/useAsync'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import { GridColDef } from '@mui/x-data-grid-premium'
import { omit } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { CustomToolbar } from '../../components/CustomToolbar'
import { Img } from '../../components/Img'
import { useGroups } from '../GroupNotifications/useGroups'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import { OpenInWindow } from '../VerifyUsersIds/OpenInWindow'
import classes from './MarketsPage.module.css'

export default React.memo(MarketsPage)

type Market = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getAllMarkets>
function MarketsPage(): JSX.Element {
    const [groups] = useGroups()

    const [_markets] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllMarkets(), [])
    const [markets, setMarkets] = React.useState<Market[] | null>(null)
    const [businessActivityTypes] = useBusinessActivityTypes()
    const [subBusinessActivityTypes] = useSubBusinessActivityTypes()
    const [cities] = useCities()

    useEffect(() => {
        if (_markets) {
            setMarkets(_markets)
        }
    }, [_markets])

    const alert = useAlertNotification()

    const getRowId = useCallback((row: Market) => row._id, [])
    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            { field: 'name', headerName: 'Name', editable: true, type: 'string' },
            {
                field: 'city',
                headerName: 'City',
                editable: true,
                type: 'singleSelect',
                valueOptions: cities?.map((x) => ({ label: x.name, value: x._id })),
            },
            { field: 'createdAt', headerName: 'Created At' },
            {
                field: 'address',
                headerName: 'Address',
                editable: true,
                type: 'string',
            },
            {
                field: 'activityType',
                headerName: 'Activity Type',
                editable: true,
                type: 'singleSelect',
                valueOptions: businessActivityTypes?.map((x) => ({ label: x.name, value: x._id })),
            },
            {
                field: 'subActivityType',
                headerName: 'Sub Activity Type',
                editable: true,
                type: 'singleSelect',
                valueOptions: ({ row }) =>
                    subBusinessActivityTypes
                        ?.filter((x) => (row ? x.parentActivityTypeId === row?.activityType : true))
                        ?.map((x) => ({ label: x.name, value: x._id })),
            },
            {
                field: 'groupId',
                headerName: 'Group',
                editable: true,
                type: 'singleSelect',
                valueOptions: groups?.map((group) => ({ value: group._id, label: group.name })),
            },
            {
                field: 'active',
                headerName: 'Active',
                editable: true,
                type: 'boolean',
            },
            {
                field: 'businessType',
                headerName: 'Business Type',
                editable: true,
                type: 'string',
            },
            {
                field: 'details',
                headerName: 'Details',
                editable: true,
                type: 'string',
            },
            {
                field: 'showInList',
                headerName: 'Show In List',
                editable: true,
                type: 'boolean',
            },
            {
                field: 'showInDepositList',
                headerName: 'Show In Deposit List',
                editable: true,
                type: 'boolean',
            },
            {
                field: 'logo',
                headerName: 'Logo',
                renderCell: (params) =>
                    params.row.logo ? <Img src={params.row.logo} className={classes['cell-image']} /> : null,
            },
            {
                field: 'frontPhoto',
                headerName: 'Front Photo',
                renderCell: (params) =>
                    params.row.frontPhoto ? (
                        <OpenInWindow href={params.row.frontPhoto}>Front Photo</OpenInWindow>
                    ) : null,
            },
            {
                field: 'licensePhoto',
                headerName: 'License Photo',
                renderCell: (params) =>
                    params.row.licensePhoto ? (
                        <OpenInWindow href={params.row.licensePhoto}>License Photo</OpenInWindow>
                    ) : null,
            },
            {
                field: 'lat',
                headerName: 'lat',
                valueGetter: (params) => params.row.coordinates?.lat,
                valueSetter: (params) => {
                    const { row, value } = params
                    row.coordinates = { ...row.coordinates, lat: value }
                    return row
                },

                editable: true,
                type: 'number',
            },
            {
                field: 'lng',
                headerName: 'lng',
                valueGetter: (params) => params.row.coordinates?.lng,
                valueSetter: (params) => {
                    const { row, value } = params
                    row.coordinates = { ...row.coordinates, lng: value }
                    return row
                },
                editable: true,
                type: 'number',
            },
        ] as GridColDef<Market>[]
    }, [businessActivityTypes, cities, groups, subBusinessActivityTypes])

    const processRowUpdate = useCallback(
        async (newRow: Market) => {
            const [err] = await clientUpdates.controlPanel.updateMarket(newRow._id, omit(newRow, '_id'))
            if (err) {
                throw err
            }
            alert({
                variant: 'success',
                alertText: 'Market updated',
            })
            return newRow
        },
        [alert]
    )

    const handleProcessRowUpdateError = useCallback(
        (error: string) => {
            alert({
                variant: 'error',
                alertText: error,
            })
        },
        [alert]
    )

    if (!markets) {
        return <div>Loading...</div>
    }

    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="markets"
                components={{
                    Toolbar: CustomToolbar,
                }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                className={classes.table}
                getRowId={getRowId}
                rows={markets}
                columns={columns}
            />
        </div>
    )
}

function useBusinessActivityTypes() {
    return useAsync(() => clientQueries.businessActivityTypes.getAll(), [])
}
function useSubBusinessActivityTypes() {
    return useAsync(() => clientQueries.subBusinessActivityTypes.getAll(), [])
}
export function useCities() {
    return useAsync(() => clientQueries.cities.getAll(), [])
}
