import { AnySourceStream, Stream, Subscription } from '../stream'

export class All<V, E = never, C = never> extends Stream<V, E, C, V[]> {
    protected sources = new Map<AnySourceStream<V, E, C>, Subscription<V, E, C>>()
    protected latestValues: V[] = []

    constructor(sources: AnySourceStream<V, E, C>[]) {
        super()

        sources.forEach((source, i) => {
            const sub = {
                value: (n) => {
                    this.latestValues[i] = n
                    this._value(this.latestValues.slice())
                },
                error: (err: E) => this._error(err),
                complete: (complete) => {
                    source.unsubscribe(sub)
                    this.sources.delete(source)
                    if (this.sources.size === 0) {
                        this._complete(complete)
                    }
                },
            } as Subscription<V, E, C>
            this.sources.set(source, sub)
        })
    }

    override connect(): void {
        super.connect()
        for (const [source, sub] of this.sources) {
            source.subscribe(sub)
        }
    }

    override disconnect(): void {
        super.disconnect()
        for (const [source, sub] of this.sources) {
            source.unsubscribe(sub)
        }
    }
}

export const all = <V, E, C>(...sources: AnySourceStream<V, E, C>[]) => new All(sources)

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        with<_DV, _DE, _DC>(...sources: AnySourceStream<_DV, _DE, _DC>[]): All<_DV | DV, _DE | DE, _DC | DC>
    }
}

Stream.prototype.with = function <V, E, C>(this: AnySourceStream<V, E, C>, ...sources: AnySourceStream<V, E, C>[]) {
    return new All([this, ...sources])
}
