/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
import { GroupNotificationId } from '@cashii/common/src/db/db'
import clientInserts from '@cashii/common/src/queries/clientInserts'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Autocomplete, Button, TextField } from '@mui/material'
import {
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    GridToolbarContainer,
    MuiEvent
} from '@mui/x-data-grid-premium'
import { omit } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import classes from './GroupNotificationsPage.module.css'
import { useGroups } from './useGroups'

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props

    const handleClick = () => {
        const _id = prompt('Enter id') as GroupNotificationId
        if (!_id) {
            return
        }
        setRows((oldRows) => [...oldRows, { _id, name: '', age: '', isNew: true }])
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [_id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }))
    }

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    )
}

export default React.memo(GroupNotificationsPage)

type GroupNotification = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getAllGroupNotifications> & { isNew?: boolean }
// eslint-disable-next-line react/no-multi-comp
function GroupNotificationsPage(): JSX.Element {
    const [groups] = useGroups()
    const [_groupNotifications] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllGroupNotifications(), [])
    const [rows, setRows] = React.useState<GroupNotification[] | null>(null)

    useEffect(() => {
        if (_groupNotifications) {
            setRows(oldRows => {
                if (!oldRows) {
                    return _groupNotifications
                }
                const isNew = oldRows.filter(row => row.isNew)
                return [..._groupNotifications, ...isNew]
            })
        }
    }, [_groupNotifications])

    const alert = useAlertNotification()

    const getRowId = useCallback((row: GroupNotification) => row._id, [])
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})

    const handleRowEditStart = useCallback((params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        event.defaultMuiPrevented = true
    }, [])

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        event.defaultMuiPrevented = true
    }, [])

    const handleEditClick = useCallback(
        (id: GroupNotificationId) => () => {
            setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }))
        },
        []
    )

    const handleSaveClick = useCallback(
        (id: GroupNotificationId) => () => {
            setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }))
        },
        []
    )

    const handleCancelClick = useCallback(
        (id: GroupNotificationId) => () => {
            setRowModesModel((rowModesModel) => ({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            }))

            setRows((rows) => {
                const editedRow = rows!.find((row) => row._id === id)
                if (editedRow!.isNew) {
                    return rows!.filter((row) => row._id !== id)
                }
                return rows
            })
        },
        []
    )

    const processRowUpdate = useCallback(
        async (newRow: GroupNotification) => {
            const updatedRow = { ...newRow, isNew: false }
            if (newRow.isNew) {
                const [err] = await clientInserts.controlPanel.insertGroupNotification(omit(newRow, 'isNew'))
                if (err) {
                    alert({
                        variant: 'error',
                        alertText: err,
                    })
                    throw err
                }
                alert({
                    variant: 'success',
                    alertText: 'Offer created',
                })
            } else {
                const [err] = await clientUpdates.controlPanel.updateGroupNotification(
                    newRow._id as GroupNotificationId,
                    omit(newRow, '_id', 'isNew')
                )
                if (err) {
                    alert({
                        variant: 'error',
                        alertText: err,
                    })
                    throw err
                }

                alert({
                    variant: 'success',
                    alertText: 'Offer updated',
                })
            }
            setRows((rows) => rows!.map((row) => (row._id === newRow._id ? updatedRow : row)))
            return updatedRow
        },
        [alert]
    )

    const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }, [])

    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            { field: 'title', headerName: 'Title', editable: true, type: 'string' },
            { field: 'body', headerName: 'Body', editable: true, type: 'string' },
            { field: 'icon', headerName: 'Icon', editable: true, type: 'string' },
            { field: 'iconColor', headerName: 'Icon Color', editable: true, type: 'string' },
            {
                field: 'iconFamily',
                headerName: 'Icon Family',
                editable: true,
                type: 'singleSelect',
                valueOptions: ['material', 'material-community', 'cashii'] as const,
            },
            { field: 'image', headerName: 'Image', editable: true, type: 'string' },
            { field: 'link', headerName: 'Link', editable: true, type: 'string' },
            { field: 'smsBody', headerName: 'Sms Body', editable: true, type: 'string' },
            { field: 'emailSubject', headerName: 'Email Subject', editable: true, type: 'string' },
            { field: 'emailBody', headerName: 'Email Body', editable: true, type: 'string' },
            { field: 'active', headerName: 'Active', editable: true, type: 'boolean' },
            { field: 'notifiedAt', headerName: 'Notified At', editable: true, type: 'datetime' },
            { field: 'inAppNotification', headerName: 'In App Notification', editable: true, type: 'boolean' },
            { field: 'pushNotification', headerName: 'Push Notification', editable: true, type: 'boolean' },
            { field: 'smsNotification', headerName: 'Sms Notification', editable: true, type: 'boolean' },
            { field: 'emailNotification', headerName: 'Email Notification', editable: true, type: 'boolean' },

            {
                field: 'pushNotificationChannelId',
                headerName: 'Push Notification Channel Id',
                editable: true,
                type: 'singleSelect',
                valueOptions: ['default'] as const,
            },
            {
                field: 'groups',
                headerName: 'Groups',
                editable: true,
                type: 'multiSelect',
                valueOptions: groups?.map((group) => ({ value: group._id, label: group.name })),
                minWidth: 200,
                renderEditCell: (params) => {
                    return (
                        <Autocomplete
                            style={{ width: '100%', height: '100%', overflowY: 'auto' }}
                            multiple
                            options={groups ?? []}
                            value={params.value}
                            onChange={(e, newValue) => {
                                params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue.map(x=>x._id) })
                            }}
                            renderInput={(params) => <TextField {...params} variant="standard" />}
                            getOptionLabel={(option) => option?.name}
                        />
                    )
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                key="save"
                                icon={<SaveIcon />}
                                label="Save"
                                onClick={handleSaveClick(id as GroupNotificationId)}
                            />,
                            <GridActionsCellItem
                                key="cancel"
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id as GroupNotificationId)}
                                color="inherit"
                            />,
                        ]
                    }

                    return [
                        <GridActionsCellItem
                            key="edit"
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id as GroupNotificationId)}
                            color="inherit"
                        />,
                    ]
                },
            },
        ] as GridColDef<GroupNotification>[]
    }, [groups, handleCancelClick, handleEditClick, handleSaveClick, rowModesModel])

    if (!rows) {
        return <div>Loading...</div>
    }

    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="groupNotifications"
                className={classes.table}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                getRowId={getRowId}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
        </div>
    )
}
