import { FieldValidation, ValidationMessage } from './types'
import validateField from './validateField'

export default function all<V = any, O = any>(
    rules: FieldValidation<V, O>[],
    customMessage?: ValidationMessage<V, O>
): FieldValidation<V, O> {
    return {
        message: (args) => {
            if (typeof customMessage === 'function') {
                return customMessage(args)
            }
            if (customMessage) {
                return args.t(customMessage)
            }
            return args.error
        },
        validate: (args) => {
            for (const rule of rules) {
                const res = validateField(args.doc, args.fieldName as any, rule as any)
                if (res === true) {
                    continue
                }
                return res
            }
            return true
        },
    }
}
