import useLocalStorageState from '@hypatia/react-utils/hooks/state/useLocalStorageState'
import {
    DataGridPremium,
    DataGridPremiumProps,
    GridAggregationModel,
    GridColumnVisibilityModel,
    GridFilterModel,
    GridPinnedColumns,
    GridRowGroupingModel,
    GridSortModel,
} from '@mui/x-data-grid-premium'
import React from 'react'
import { useHideTableStamp } from '../../hooks/useHideTableStamp'

export default function StoredDataGridPremium(props: DataGridPremiumProps & { name: string }): JSX.Element {
    const { name } = props
    const [sortModel, setSortModel] = useLocalStorageState<GridSortModel>(name + 'SortModel', [])
    const [filterModel, setFilterModel] = useLocalStorageState<GridFilterModel>(name + 'FilterModel', { items: [] })
    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorageState<GridColumnVisibilityModel>(
        name + 'ColumnVisibilityModel',
        {}
    )
    const [aggregationModel, setAggregationModel] = useLocalStorageState<GridAggregationModel>(
        name + 'AggregationModel',
        {}
    )
    const [rowGroupingModel, setRowGroupingModel] = useLocalStorageState<GridRowGroupingModel>(
        name + 'RowGroupingModel',
        []
    )
    const [pinnedColumns, setPinnedColumns] = useLocalStorageState<GridPinnedColumns>(name + 'PinnedColumns', {})
    
    useHideTableStamp()

    return (
        <DataGridPremium
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={setPinnedColumns}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            aggregationModel={aggregationModel}
            onAggregationModelChange={setAggregationModel}
            rowGroupingModel={rowGroupingModel}
            onRowGroupingModelChange={setRowGroupingModel}
            {...props}
        />
    )
}
