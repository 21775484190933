import moment from 'moment'
import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function dateAfter<O>(
    valueGetter: (doc: O) => Date,
    customMessage?: ValidationMessage
): FieldValidation<unknown, O> {
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.selectAFutureDate'),
        validate: ({ value, doc }) => moment(value as Date).isAfter(moment(valueGetter(doc))),
    }
}
