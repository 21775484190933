import { AnySourceStream, Stream } from '../stream'
import { All } from './all'
import { Flatten, flattenObjectValues } from './flatten-object-values'

type UnpackedArray<A> = A extends Array<infer V> ? V : never

const flatArrayObjectMap = <I, O extends object>(source: AnySourceStream<I[]>, mapper: (value: I) => O) =>
    // TODO, this need to be optimized, since flattenObjectValues(mapper(value)) is probably not changing a lot
    source.flatMap((list) => new All(list.map((value) => flattenObjectValues(mapper(value)))))

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        flatArrayObjectMap<T extends object>(
            mapper: (value: UnpackedArray<DV>) => T
        ): Stream<DV, DE, DC, Flatten<T>[], DE, DC>
    }
}

Stream.prototype.flatArrayObjectMap = function <I, O extends object>(
    this: AnySourceStream<I[], any, any>,
    mapper: (value: I) => O
) {
    return flatArrayObjectMap(this as any, mapper) // tslint:disable-line:no-invalid-this
}
