import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

export function useStateWaitForMounting<S = undefined>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>]
export function useStateWaitForMounting<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>]
export function useStateWaitForMounting<S>(initialState?: S | (() => S)): [S, Dispatch<SetStateAction<S>>] {
    const [value, _setValue] = useState(initialState)
    const isMounted = useRef(false)
    const delayedState = useRef<{ value: S | ((prevState: S) => S) | undefined; hasBeenSet: boolean }>({
        value: undefined,
        hasBeenSet: false,
    })

    useEffect(() => {
        isMounted.current = true
        if (delayedState.current.hasBeenSet) {
            _setValue(delayedState.current.value as any)
        }
        return () => {
            isMounted.current = false
        }
    }, [])
    const setValue = useCallback((newValue: S | ((prevState: S) => S)) => {
        if (isMounted.current) {
            _setValue(newValue as any)
        } else {
            delayedState.current = { value: newValue, hasBeenSet: true }
        }
    }, [])

    return [value as any, setValue]
}
