import React, { Suspense } from 'react'
import SoftSuspense from './SoftSuspense'

interface Props {
    fallback: NonNullable<React.ReactNode> | null
    children: React.ReactNode
    fragmentStyle?: React.CSSProperties
    className?: string
}

const FullSuspense = (props: Props): JSX.Element => {
    const { children, fallback, fragmentStyle, className } = props

    return (
        <SoftSuspense
            className={className}
            fallback={fallback}
            fragmentStyle={{ height: '100%', ...(fragmentStyle ?? {}) }}
        >
            <Suspense fallback={fallback}>{children}</Suspense>
        </SoftSuspense>
    )
}

export default FullSuspense
