import { ClientChangeEvent } from '@hypatia/serverer-common/database/ClientChangeEvent'
import { rpcCallerWithStreamResults } from '@hypatia/serverer-common/rpc/rpcCallerWithStreamResults'
import { RpcType } from '@hypatia/serverer-common/rpc/RpcType'
import { AnySourceStream } from '@hypatia/utils/streams/stream'
import { DOCUMENT_NOT_FOUND } from '@hypatia/utils/communication/errors'

export type SubscribeToQueryArgs = {
    queryName: string
    args: any[]
}

export const SubscribeToQueryRpcName = 'mongoDatabaseProvider.subscribeToQuery'
export type SubscribeToQueryResults = AnySourceStream<{ initialList?: any[]; changes?: ClientChangeEvent[] }>
export type SubscribeToQueryErrors = string | typeof DOCUMENT_NOT_FOUND
export type SubscribeToQueryRpc = RpcType<
    typeof SubscribeToQueryRpcName,
    SubscribeToQueryArgs,
    SubscribeToQueryResults,
    SubscribeToQueryErrors
>

export default rpcCallerWithStreamResults<SubscribeToQueryRpc>(SubscribeToQueryRpcName)
