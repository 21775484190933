import { Stream } from '../stream'

type DisposeFn = () => void

// this was not built as class factory since it will lose generic types if it was do so
export class Build<V, E = never, C = never> extends Stream<V, E, C> {
    constructor(protected onSubscription: (stream: Stream<V, E, C>) => DisposeFn) {
        super()
    }

    override connect(): void {
        super.connect()
        this.dispose = this.onSubscription(this)
    }

    override disconnect(): void {
        super.disconnect()
        this.dispose()
    }

    protected dispose: DisposeFn = () => {
        /*  */
    }
}

export const build = <V, E = never, C = never>(fn: (stream: Stream<V, E, C>) => DisposeFn): Stream<V, E, C> =>
    new Build(fn)
