import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class AwaitedMap<SV, SE = any, SC = any, DV = SV> extends Pass<SV, SE, SC, DV> {
    protected mapper: (value: SV) => Promise<DV>

    constructor(mapper: (value: SV) => Promise<DV>, source?: AnySourceStream<SV, SE, SC>) {
        super(source)
        this.mapper = mapper
    }

    override async value(value: SV): Promise<void> {
        try {
            const mapped = await this.mapper(value)
            this._value(mapped)
        } catch (e) {
            this._error(e as any)
        }
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        awaitedMap<T>(mapper: (value: DV) => Promise<T>): Stream<DV, DE, DC, T, DE, DC>
    }
}

Stream.prototype.awaitedMap = function <SV, SE, SC, DV>(
    this: AnySourceStream<SV, SE, SC>,
    mapper: (value: SV) => Promise<DV>
) {
    return new AwaitedMap(mapper, this) // tslint:disable-line:no-invalid-this
}
