import { AnySourceStream, Stream, Subscription } from '../stream'
import { Pass } from './pass'

export class Flatten<T = any, E = any, C = any> extends Pass<AnySourceStream<T, any, any>, E, C, T> {
    protected streams = new Map<AnySourceStream<T, E, C>, Subscription<T, any, any>>()

    override disconnect(): void {
        super.disconnect()
        for (const [stream, sub] of this.streams) {
            stream.unsubscribe(sub)
        }
        this.streams.clear()
    }

    override value(stream: AnySourceStream<T, E, C>): void {
        if (this.streams.has(stream)) {
            return
        }

        const sub: Subscription<T, any, any> = {
            value: (n: T) => this._value(n),
            error: () => {
                stream.unsubscribe(sub)
                this.streams.delete(stream)
            },
            complete: () => {
                stream.unsubscribe(sub)
                this.streams.delete(stream)
            },
        }

        this.streams.set(stream, sub)
        stream.subscribe(sub)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        flatten<T>(): Stream<DV, DE, DC, T, DE, DC>
    }
}

Stream.prototype.flatten = function <T, V extends AnySourceStream<T, E, C>, E, C>(this: AnySourceStream<V, E, C>) {
    return new Flatten(this) // tslint:disable-line:no-invalid-this
}
