import isFunction from 'lodash/isFunction'
import { AnySourceStream, Stream, Subscription } from '../stream'
import './as-promise'
import { Pass } from './pass'

export class DeliverLast<V, E = never, C = never> extends Pass<V, E, C> {
    public last: V | undefined
    public received = false

    override subscribe(value?: (val: V) => void, error?: (err: E) => void, complete?: (comp: C) => void): () => void
    override subscribe(subscription: Subscription<V, E, C>): () => void
    override subscribe(
        valueOrSubscription?: Subscription<V, E, C> | ((value: V) => void),
        error?: (err: E) => void,
        complete?: (comp: C) => void
    ) {
        const isSubscription = !(error || complete || isFunction(valueOrSubscription))

        const sub = isSubscription
            ? (valueOrSubscription as Subscription<V, E, C>)
            : { value: valueOrSubscription as (value: V) => void, error, complete }

        const unSub = super.subscribe(sub)

        if (this.received && sub.value) {
            sub.value(this.last!)
        }

        return unSub
    }

    valueFromLast(derive: (lastValue: V | undefined) => V): void {
        this.value(derive(this.last))
    }

    override value(value: V): void {
        this.received = true
        this.last = value
        super.value(value)
    }

    private lastAsPromise?: Promise<V>

    getLast(): Promise<V> {
        if (this.received) {
            return Promise.resolve(this.last!)
        }
        if (this.lastAsPromise) {
            return this.lastAsPromise
        }

        this.lastAsPromise = this.asPromise()
        return this.lastAsPromise!
    }
}

export const deliverLast = <V>(initialValue: V): DeliverLast<V> => {
    const stream = new DeliverLast<V>()
    stream.value(initialValue)
    return stream
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        deliverLast(): DeliverLast<DV, DE, DC>
    }
}

Stream.prototype.deliverLast = function <V, E, C>(this: AnySourceStream<V, E, C>): DeliverLast<V, E, C> {
    return new DeliverLast(this) // tslint:disable-line:no-invalid-this
}
