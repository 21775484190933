import i18next from 'i18next'
import { FieldValidation } from './types'

const t = i18next.t.bind(i18next)

export default function validateField<T, K extends keyof T>(
    doc: T,
    key: K,
    validation: FieldValidation<T[K], T> | undefined
): boolean | string {
    const value = doc[key]

    if (!validation) {
        return true
    }

    if ('validate' in validation) {
        const isValid = (validation.validate as any)({ doc, value, fieldName: key, t })
        if (isValid === true) {
            return true
        }
        return (validation.message as any)({ doc, fieldName: key, t, value, error: isValid })
    }

    return true
}
