import { Table } from '@hypatia/serverer-common/database/table'
import type { Filter } from 'mongodb'

export interface SLiveQuery<T, K extends keyof T> {
    tableName: string
    query: Filter<T>
    fields: Readonly<K[]>
}

export function sLiveQuery<T, K extends keyof T>(
    table: Table<T>,
    query: Filter<T>,
    fields: Readonly<K[]>
): SLiveQuery<T, K> {
    return { tableName: table.collectionName, query, fields }
}
