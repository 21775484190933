import { UserId } from '@hypatia/serverer-common/database/nominalTypes'
import { BaseSettings } from '@hypatia/utils/defaultSettings'
import { FileId, FileToken } from '../storage/tables/FileTable'
import { BaseFieldsType } from './baseFields'
import type { Table } from './table'

export interface BaseUserSettings extends BaseSettings {}

export const BaseUserTable: Table<BaseUserType> = {
    collectionName: 'users',
}

export interface BaseUserType extends BaseFieldsType {
    _id: UserId

    firstName: string
    lastName: string
    fullName: string

    email?: string
    countryCode?: string
    phoneNumberWithoutCode?: string
    phoneNumber?: string
    photo?: FileId | FileToken

    password?: string
    salt?: string
    disabled?: boolean

    filesIds?: string[]

    lastSeenAt?: Date
    lastLoginAt?: Date
    settings?: BaseUserSettings

    externalId?: string

    pushNotificationsTokens?: string[]
}
