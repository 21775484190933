import { AnySourceStream, Stream } from '../stream'
import { All } from './all'
import './flat-map'

type UnpackedArray<A> = A extends Array<infer V> ? V : never

const flatArrayMap = <I, O>(source: AnySourceStream<I[]>, mapper: (value: I) => AnySourceStream<O, any, any>) =>
    source.flatMap((list) => new All(list.map(mapper)))

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        flatArrayMap<T>(
            mapper: (value: UnpackedArray<DV>) => AnySourceStream<T, any, any>
        ): Stream<DV, DE, DC, T[], DE, DC>
    }
}

Stream.prototype.flatArrayMap = function <I, E, C, O>(
    this: AnySourceStream<I[], E, C>,
    mapper: (value: I) => AnySourceStream<O, any, any>
) {
    return flatArrayMap(this as any, mapper) // tslint:disable-line:no-invalid-this
}
