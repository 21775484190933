import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Tap<SV, SE = any, SC = any> extends Pass<SV, SE, SC> {
    protected tapper: (value: SV) => any

    constructor(tapper: (value: SV) => any, source?: AnySourceStream<SV, SE, SC>) {
        super(source)
        this.tapper = tapper
    }

    override value(value: SV) {
        this.tapper(value)
        this._value(value as any)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        tap(tapper: (value: DV) => any): Stream<DV, DE, DC, DV, DE, DC>
    }
}

Stream.prototype.tap = function <SV, SE, SC>(this: AnySourceStream<SV, SE, SC>, tapper: (value: SV) => any) {
    return new Tap(tapper, this) // tslint:disable-line:no-invalid-this
}
