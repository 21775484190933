import { GetDownloadUrlFromFileTokenArgs, getter } from '@hypatia/serverer-common/storage/rpc/getDownloadUrlFromFileToken'
import { FileId } from '@hypatia/serverer-common/storage/tables/FileTable'
import { asyncStorage } from '@hypatia/utils/async-storage'
import isReactNative from '@hypatia/utils/os/isReactNative'
import { error, value } from '@hypatia/utils/types'

export const cache: Record<FileId, { url: string; age: number }> = {}
export default async function GetDownloadUrlFromFileToken(
    args: GetDownloadUrlFromFileTokenArgs
): ReturnType<typeof getter> {
    const fileId = args.token.split('|')[0] as FileId
    if (isReactNative()) {
        const cached = JSON.parse(await asyncStorage.get(fileId) || null)
        if (cached && Date.now() - cached.age < 1000 * 60 * 60 * 6) {
            return value(cached.url)
        }
    } else {
        if (cache[fileId] && Date.now() - cache[fileId].age < 1000 * 60 * 60 * 6) {
            return value(cache[fileId].url)
        }
    }


    const [err, val] = await getter(args)
    if (err) {
        return error(err)
    }
    if (isReactNative()) {
        asyncStorage.set(fileId, JSON.stringify({ url: val, age: Date.now() }))
    } else {
        cache[fileId] = {
            url: val,
            age: Date.now(),
        }
    }


    return value(val)
}
