import * as Sentry from '@sentry/core'
import { userRecordStream } from '../containers/userIdStream'

userRecordStream.subscribe((user) => {
    if (user) {
        Sentry.setUser({
            id: user._id,
            username: user.fullName,
            email: user.email,
        })
    } else {
        Sentry.setUser(null)
    }
})
