import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium'
import React from 'react'

export function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    )
}
