import { AnySourceStream, Stream } from '../stream'

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        reduce<T>(accumulator: (acc: T, value: DV) => T, seed?: T): Stream<DV, DE, DC, T, DE, DC>
    }
}

Stream.prototype.reduce = function <V, E, C, T = V>(
    this: AnySourceStream<V, E, C>,
    accumulator: (acc: T, value: V) => T,
    seed?: T
) {
    return this.map((value) => {
        seed = accumulator(seed!, value)
        return seed
    })
}
