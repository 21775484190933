export interface CreatePromiseType<T = void> {
    promise: Promise<T>
    resolve: (value: T) => void
    reject: (e?: unknown) => void
    status: 'pending' | 'rejected' | 'resolved'
}

export default function createPromise<T = void>(): CreatePromiseType<T> {
    let resolve: (val: T) => void
    let reject: (e: unknown) => void
    const promise = new Promise<T>((_resolve, _reject) => {
        resolve = (...args) => {
            obj.status = 'resolved'
            _resolve(...args)
        }
        reject = (...args) => {
            obj.status = 'rejected'
            _reject(...args)
        }
    })

    const obj: CreatePromiseType<T> = {
        promise,
        resolve: resolve!,
        reject: reject!,
        status: 'pending',
    }

    return obj
}
