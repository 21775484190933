import { AnySourceStream, AnyStream, Stream, Subscription } from '../stream'
import { Pass } from './pass'

export class WaitUntilStream<V, E, C> extends Pass<V, E, C> {
    private sub: Subscription<V, E, C>
    private waiting = true

    private queuedValues: V[] = []

    constructor(
        private streamToWait: AnySourceStream<V, E, C>,
        source?: AnySourceStream<V, E, C>,
        private dontQueueValues?: boolean
    ) {
        super(source)

        const error = (err: E): void => this._error(err)

        const start = (): void => {
            this.waiting = false

            this.queuedValues.forEach((v) => {
                this._value(v)
            })
            this.queuedValues = []

            streamToWait.unsubscribe(this.sub)
        }

        this.sub = { value: start, error, complete: start }
    }

    override connect(): void {
        this.queuedValues = []

        super.connect()
        this.streamToWait.subscribe(this.sub)
    }

    override disconnect(): void {
        this.queuedValues = []

        super.disconnect()
        this.streamToWait.unsubscribe(this.sub)
    }

    override value(value: V): void {
        if (this.waiting) {
            if (this.dontQueueValues) {
                return
            }
            this.queuedValues.push(value)
            return
        }

        this._value(value)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        waitUntilStream(streamToWait: AnyStream): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.waitUntilStream = function <V, E, C>(
    this: AnySourceStream<V, E, C>,
    streamToWait: AnyStream,
    dontQueueValues?: boolean
) {
    return new WaitUntilStream(streamToWait, this, dontQueueValues)
}
