import { Currency, Money } from '@cashii/common/src/db/db'
import { getCurrencySymbol } from './getCurrencySymbol'

export function formatAmount(amount: Money, currency?: Currency) {
    const sign = Math.sign(amount)
    const abs = Math.abs(amount)
    const fullNumber = Math.floor(abs / 100)
    const decimal = (abs % 100).toString().padStart(2, '0')
    // put a comma after every 3 digits
    const fullNumberFormatted = fullNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const signStr = sign === -1 ? '-' : ''
    const currencyStr = currency ? getCurrencySymbol(currency) : ''
    return `${signStr}${currencyStr}${fullNumberFormatted}.${decimal}`
}
