/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-multi-comp */
import captureException from '@hypatia/serverer-common/opentelemetry/captureException'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAlertNotification } from '../containers/AlertNotificationContext'
import { useLocation } from '../router/LocationContext'
import { getErrorPage } from './utils/errorPagesRegistry'

export type ErrorFallbackFunction = (args: { onRetry: () => void; error: Error }) => React.ReactNode

interface Props {
    children: React.ReactNode
    fallbackMessage?: string
    fallback?: ErrorFallbackFunction | null
    error?: Error
    clearError?: () => void
}

const Renderer = ({ error, clearError, fallbackMessage, ...props }: Props): React.ReactElement => {
    const [lastError, setLastError] = useState<Error>()

    const alert = useAlertNotification()
    const translation = useTranslation()
    const location = useLocation()

    useEffect(() => {
        setLastError(undefined)
        clearError?.()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname])

    useEffect(() => {
        if (error && error !== lastError) {
            if (fallbackMessage) {
                alert?.({ variant: 'error', alertText: translation?.t(fallbackMessage as any) })
            }
            captureException(error)
            if (process.env.NODE_ENV === 'development') {
                /* eslint-disable no-console */
                // console.error(error)
            }

            setLastError(error)
        }
    }, [alert, error, fallbackMessage, lastError, translation])

    const onRetry = useCallback(() => {
        setLastError(undefined)
        clearError?.()
    }, [clearError])

    const currentError = error || lastError
    if (currentError) {
        if (props.fallback !== undefined) {
            if (props.fallback === null) {
                return props.fallback as any
            }

            return <>{props.fallback({ onRetry, error: currentError })}</>
        }

        const err = currentError instanceof Error ? currentError.message : currentError
        const ErrorComp = getErrorPage(err!)!

        return <ErrorComp onRetry={onRetry} error={currentError!} />
    }

    return <>{props.children}</>
}

interface State {
    error?: Error
}

function Catch<CatchProps>(
    Component: React.ComponentType<CatchProps & { error?: Error }>,
    errorHandler?: (error: Error, info: React.ErrorInfo) => void
): React.ComponentType<CatchProps> {
    return class Boundary extends React.Component<CatchProps, State> {
        override state: { error?: Error } = {
            error: undefined,
        }

        static getDerivedStateFromError(error: Error): State {
            if (process.env.TEST_ENV === 'true') {
                throw error
            }
            return { error }
        }

        override componentDidCatch(error: Error, info: React.ErrorInfo): void {
            if (errorHandler) {
                errorHandler(error, info)
            }
        }

        clearError = (): void => {
            this.setState({ error: undefined })
        }

        override render(): JSX.Element {
            return <Component {...this.props} error={this.state.error} clearError={this.clearError} />
        }
    }
}

const ErrorBoundary = Catch(Renderer)

export default ErrorBoundary

// https://gist.github.com/andywer/800f3f25ce3698e8f8b5f1e79fed5c9c
