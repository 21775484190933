import moment from 'moment'
import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function futureDate<O>(customMessage?: ValidationMessage): FieldValidation<unknown, O> {
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.selectAFutureDate'),
        validate: ({ value }) => moment(value as Date).isAfter(moment()),
    }
}
