import { AlertNotificationContext, AlertValuesType } from '@hypatia/react-utils/containers/AlertNotificationContext'
import { Alert, AlertTitle, Button, Snackbar } from '@mui/material'
import React, { useCallback } from 'react'
// import { useTranslation } from 'react-i18next'

interface Props {
    children: JSX.Element
}

const AlertNotificationProvider = ({ children }: Props): JSX.Element => {
    const [alert, setAlert] = React.useState<AlertValuesType | null>(null)
    const alertProviderValue = useCallback((alertValues: AlertValuesType) => {
        setAlert(alertValues)
    }, [])

    const close = useCallback(() => {
        setAlert(null)
    }, [])

    const actions: JSX.Element[] = []
    if (alert?.firstButton) {
        actions.push(
            <Button color="inherit" size="small" onClick={alert?.handleFirstButton}>
                {alert.firstButton}
            </Button>
        )
    }
    if (alert?.secondButton) {
        actions.push(
            <Button color="inherit" size="small" onClick={alert?.handleSecondButton}>
                {alert.secondButton}
            </Button>
        )
    }

    return (
        <AlertNotificationContext.Provider value={alertProviderValue}>
            <Snackbar open={alert !== null} onClose={close} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                <Alert onClose={close} severity={alert?.variant} sx={{ width: '100%' }} action={actions}>
                    <AlertTitle>{alert?.alertText}</AlertTitle>

                    {alert?.messageText}
                </Alert>
            </Snackbar>
            {children}
        </AlertNotificationContext.Provider>
    )
}

export default AlertNotificationProvider
