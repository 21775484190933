import Cookies from 'universal-cookie'

export const cookies = new Cookies()
const domain = typeof window === 'undefined' ? '' : window.location.hostname.split('.').slice(-2).join('.')

const changeListeners: Record<string, Array<(value: string | null) => void>> = {}
let cachedValues: Record<string, string | null> = cookies.getAll()

function checkChanges(): void {
    Object.keys(changeListeners).forEach((key) => {
        const value = cookies.get(key)
        if (value !== cachedValues[key]) {
            cachedValues[key] = value
            changeListeners[key].forEach((listener) => listener(value))
        }
    })

    cachedValues = cookies.getAll()
}

setInterval(checkChanges, 1000)

export const asyncStorage = {
    async get(key: string) {
        return cookies.get(key)
    },

    async set(key: string, value: string) {
        if (cookies.get(key) === value) {
            return
        }
        return cookies.set(key, value, { domain, path: '/', secure: true, expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) })
    },

    async setIfNotExists(key: string, value: string) {
        if (!cookies.get(key)) {
            return cookies.set(key, value, { domain, path: '/', secure: true, expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) })
        }
    },

    async remove(key: string) {
        return cookies.remove(key, { domain, path: '/', secure: true })
    },

    async clear() {
        return Promise.all(Object.keys(cookies.getAll()).map((key) => cookies.remove(key, { domain, path: '/', secure: true })))
    },

    onchange(key: string, callback: (value: string | null) => void): () => void {
        changeListeners[key] = changeListeners[key] || []
        changeListeners[key].push(callback)
        return () => {
            changeListeners[key] = changeListeners[key].filter((c) => c !== callback)
        }
    },
}
