export const shallowClone = <T>(obj: T) => {
    // tslint:disable-next-line:no-null-keyword
    if (obj === null || typeof obj !== 'object') {
        return obj
    }

    const copy: any = {}

    for (const key of Object.keys(obj)) {
        copy[key] = (obj as any)[key]
    }

    return copy as T
}
