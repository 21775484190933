import isFunction from 'lodash/isFunction'
import { AnySourceStream, Stream, Subscription } from '../stream'
import { Pass } from './pass'

export class DeliverAll<V, E = never, C = never> extends Pass<V, E, C> {
    public values: V[] = []

    override subscribe(value?: (val: V) => void, error?: (err: E) => void, complete?: (comp: C) => void): () => void
    override subscribe(subscription: Subscription<V, E, C>): () => void
    override subscribe(
        valueOrSubscription?: Subscription<V, E, C> | ((value: V) => void),
        error?: (err: E) => void,
        complete?: (comp: C) => void
    ) {
        const isSubscription = !(error || complete || isFunction(valueOrSubscription))

        const sub = isSubscription
            ? (valueOrSubscription as Subscription<V, E, C>)
            : { value: valueOrSubscription as (value: V) => void, error, complete }

        if (sub.value && !this.subscriptions.has(sub)) {
            for (const value of this.values) {
                sub.value(value)
            }
        }

        return super.subscribe(sub)
    }

    override value(value: V): void {
        super.value(value)
        this.values.push(value)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        deliverAll(): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.deliverAll = function <V, E, C>(this: AnySourceStream<V, E, C>) {
    return new DeliverAll(this) // tslint:disable-line:no-invalid-this
}
