import cashiiRPCs from '@cashii/common/src/cashiiRpcs'
import { CashMachineId, Currency, Money } from '@cashii/common/src/db/db'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import { formatAmount } from '@cashii/common/src/utils/formatAmount'
import { getCurrencySymbol } from '@cashii/common/src/utils/getCurrencySymbol'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useIntervalDynamic from '@hypatia/react-utils/hooks/time/useIntervalDynamic'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { Button, MenuItem, Select, TextField } from '@mui/material'
import { orderBy } from 'lodash'
import React, { useCallback } from 'react'
import classes from './OperatorPage.module.css'
interface Props {
    cashMachineId: CashMachineId
}

export default React.memo(OperatorPage)
function OperatorPage(props: Props): JSX.Element {
    const { cashMachineId } = props

    const alert = useAlertNotification()
    const [qr] = useDeliverLast(() => clientLiveQueries.cashMachineInfo(cashMachineId), [cashMachineId])
    const qrItem = qr?.[0]
    const currentOperation = qrItem?.currentOperation

    const [isExpired, setIsExpired] = React.useState(false)
    useIntervalDynamic(() => {
        if (Date.now() - +(currentOperation?.startedAt ?? 0) > 1000 * 60 * 5) {
            setIsExpired(true)
            alert({
                variant: 'error',
                alertText: 'انتهت صلاحية العملية.',
            })
            setAmount1('')
            setAmount2('')
            setCurrency('TRY')
            setSaved(false)
        } else {
            setIsExpired(false)
        }
    }, 1000 * 1)
    const showDetails = currentOperation && !isExpired && !currentOperation.executedAt

    const [amount1, setAmount1] = React.useState('')
    const [amount2, setAmount2] = React.useState('')
    const [currency, setCurrency] = React.useState('TRY')
    const [saved, setSaved] = React.useState(false)

    const updateAmount1 = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = Number(e.target.value)
        const value = Math.round(v * 100) / 100
        if (value !== v) {
            setAmount1(value + '')
        } else {
            setAmount1(e.target.value)
        }
    }, [])

    const updateAmount2 = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = Number(e.target.value)
        const value = Math.round(v * 100) / 100
        if (value !== v) {
            setAmount2(value + '')
        } else {
            setAmount2(e.target.value)
        }
    }, [])

    const save = useCallback(async () => {
        const [err] = await cashiiRPCs.controlPanel.saveCurrentOperation({
            cashMachineId,
            amount: Math.round(Number(amount1) * 100) as Money,
            operationId: currentOperation!.operationId,
            currency: currency as Currency,
        })

        if (err) {
            alert({
                variant: 'error',
                alertText: err,
            })
            return
        }
        setAmount2('')
        setSaved(true)
    }, [alert, amount1, cashMachineId, currency, currentOperation])

    const submit = useCallback(async () => {
        if (!saved) {
            alert({
                variant: 'error',
                alertText: 'يرجى حفظ العملية أولا',
            })
            return
        }
        if (Number(amount1) !== Number(amount2)) {
            alert({
                variant: 'error',
                alertText: 'المبلغ غير متطابق',
            })
            return
        }

        const [err] = await cashiiRPCs.controlPanel.confirmCurrentOperation({
            cashMachineId,
            amount: (Number(amount1) * 100) as Money,
            operationId: currentOperation!.operationId,
            currency: currency as Currency,
        })
        if (err) {
            alert({
                variant: 'error',
                alertText: err,
            })
            return
        }
        setAmount1('')
        setAmount2('')
        setCurrency('TRY')
        setSaved(false)

        alert({
            variant: 'success',
            alertText: 'تمت العملية بنجاح',
        })
    }, [alert, amount1, amount2, cashMachineId, currency, currentOperation, saved])
    const cancel = useCallback(() => {
        cashiiRPCs.controlPanel.cancelCurrentOperation(cashMachineId)
        setAmount1('')
        setAmount2('')
        setCurrency('TRY')
        setSaved(false)
    }, [cashMachineId])

    const selectCurrency = useCallback((e: React.ChangeEvent<{ value: string }>) => {
        setCurrency(e.target.value as string)
    }, [])

    const [latestOperations] = useDeliverLast(
        () => clientLiveQueries.latestCashMachineOperations(cashMachineId),
        [cashMachineId]
    )

    return (
        <>
            <div className={classes.latestOperationsContainer}>
                {orderBy(latestOperations ?? [], 'executedAt', 'desc').map((o) => (
                    <div key={o._id} className={classes.latestOperationsItem}>
                        <p>{o.type === 'deposit' ? 'إيداع' : 'سحب'}</p>
                        <p>
                            {getCurrencySymbol(o.currency)}
                            {formatAmount(o.amount)}
                        </p>

                        <p>
                            {o.type === 'deposit'
                                ? o.toMarket?.name ?? o.toUser?.fullName
                                : o.fromMarket?.name ?? o.fromUser?.fullName}
                        </p>
                        <p>{o.executedAt && new Date(o.executedAt).toLocaleString()}</p>
                    </div>
                ))}
            </div>
            <div className={classes.container}>
                {showDetails ? (
                    <>
                        <h1>{currentOperation.market?.name ?? currentOperation.user.fullName}</h1>
                        <h2>{currentOperation.type === 'deposit' ? 'إيداع' : 'سحب'}</h2>

                        <div className={classes.formContainer}>
                            <Select value={currency} onChange={selectCurrency} className={classes.input}>
                                <MenuItem value="TRY">TRY</MenuItem>
                                <MenuItem value="USD">USD</MenuItem>
                            </Select>
                            <TextField
                                label="Amount"
                                variant="outlined"
                                value={amount1 + ''}
                                onChange={updateAmount1}
                                className={classes.input}
                            />
                            <Button variant="contained" onClick={save}>
                                حفظ
                            </Button>
                            {saved ? (
                                <p>
                                    تم الحفظ {getCurrencySymbol(currentOperation.currency!)}
                                    {formatAmount(currentOperation.amount!)}
                                </p>
                            ) : null}
                            {currentOperation.fee ? (
                                <p>
                                    الرسوم {getCurrencySymbol(currentOperation.currency!)}
                                    {formatAmount(currentOperation.fee)}
                                </p>
                            ) : null}
                        </div>
                        <div className={classes.formContainer}>
                            <TextField
                                label="Confirm Amount"
                                variant="outlined"
                                value={amount2 + ''}
                                onChange={updateAmount2}
                                className={classes.input}
                            />
                            <div className={classes.buttonContainer}>
                                <Button variant="contained" onClick={submit}>
                                    تأكيد
                                </Button>
                                <Button variant="outlined" color="error" onClick={cancel}>
                                    إلغاء
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <p>لا يوجد عملية جارية</p>
                )}
            </div>
        </>
    )
}
