import * as msgpack from '@hypatia/utils/encoding/extendedMsgPack'
import Emitter from 'component-emitter'
import { checkPacket, DecodedPacket } from './msgPackParser'

export class Encoder {
    encode = function (packet: unknown): string[] {
        return [Buffer.from(msgpack.encode(packet)).toString('base64')]
    }
}

export class Decoder extends Emitter<any> {
    add(obj: string): void {
        const data = Buffer.from(obj as any, 'base64')
        const decoded = msgpack.decode(data)
        this.checkPacket(decoded as DecodedPacket)
        this.emit('decoded', decoded)
    }

    checkPacket(decoded: DecodedPacket): void {
        checkPacket(decoded)
    }

    destroy(): void {
        /* */
    }
}
