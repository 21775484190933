import { UserId } from '@hypatia/serverer-common/database/nominalTypes'
import { asyncStorage } from '@hypatia/utils/async-storage'
import getPathName from '@hypatia/utils/window/getPathName'
import setPathName from '@hypatia/utils/window/setPathName'
import { tokenStream } from '../containers/userIdStream'

export default async function storeUserToken(token: string): Promise<void> {
    await asyncStorage.set('opx-token', token)

    if (tokenStream.last !== token) {
        tokenStream.value(token)
    }
}

asyncStorage.onchange('opx-token', (token: string | null) => {
    if (tokenStream.last !== token) {
        if (!tokenStream.last && token) {
            tokenStream.value(token as UserId)
        } else {
            tokenStream.value(undefined)
            if (!getPathName()?.includes('/i/')) {
                setTimeout(() => {
                    setPathName('')
                }, 1000)
            }
        }
    }
})
