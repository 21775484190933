//@ts-ignore
import { Preferences } from '@capacitor/preferences'

const changeListeners: Record<string, Array<(value: string | null) => void>> = {}

export const asyncStorage = {
    async get(key: string) {
        return (await Preferences.get({ key })).value
    },

    async set(key: string, value: string) {
        if ((await Preferences.get({ key })).value === value) {
            return
        }
        await Preferences.set({ key, value })
        if (changeListeners[key]) {
            changeListeners[key].forEach((listener) => listener(value))
        }
    },

    async setIfNotExists(key: string, value: string) {
        if (!(await Preferences.get({ key })).value) {
            this.set(key, value)
        }
    },

    async remove(key: string) {
        await Preferences.remove({ key })
        if (changeListeners[key]) {
            changeListeners[key].forEach((listener) => listener(null))
        }
    },

    async clear() {
        await Preferences.clear()
        Object.keys(changeListeners).forEach((key) => {
            changeListeners[key].forEach((listener) => listener(null))
        })
    },

    onchange(key: string, callback: (value: string | null) => void): () => void {
        changeListeners[key] = changeListeners[key] || []
        changeListeners[key].push(callback)
        return () => {
            changeListeners[key] = changeListeners[key].filter((c) => c !== callback)
        }
    },
}
