import React from 'react'

export interface ErrorComponentProps {
    onRetry?: () => void
    error: Error
}

export const errorPagesRegistry = new Map<string, React.ComponentType<ErrorComponentProps>>()

export const registerErrorPage = (name: string, comp: React.ComponentType<ErrorComponentProps>) => {
    errorPagesRegistry.set(name, comp)
}

export const SOMETHING_WENT_WRONG = 'somethingWentWrong'

export const getErrorPage = (name: string) =>
    errorPagesRegistry.get(name) ?? errorPagesRegistry.get('somethingWentWrong')
