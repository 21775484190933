import { getCustomizedMessage } from './getCustomizedMessage'
import { FieldValidation, ValidationMessage } from './types'

export default function contain(text: string, customMessage?: ValidationMessage): FieldValidation {
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.contain', { text }),
        validate: ({ value }) => {
            if (!value) {
                return true
            }
            switch (typeof value) {
                case 'string':
                    return value.trim().includes(text)
                default:
                    return false
            }
        },
    }
}
