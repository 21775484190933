import { deepClone } from '../../object/deep-clone'
import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class DeepClone<V, E = never, C = never> extends Pass<V, E, C> {
    override value(value: V): void {
        this._value(deepClone(value))
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        deepClone(): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.deepClone = function <SV, SE, SC>(this: AnySourceStream<SV, SE, SC>) {
    return new DeepClone(this) // tslint:disable-line:no-invalid-this
}
