import { FieldValidation, FieldValidationArgs, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function custom<V, O>(
    validator: (args: FieldValidationArgs<V, O>) => string | boolean,
    customMessage?: ValidationMessage<V, O>
): FieldValidation<V, O> {
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.custom'),
        validate: (args) => validator(args),
    }
}
