import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Distinct<V, E = never, C = never> extends Pass<V, E, C> {
    protected seen = new Set<V>()

    constructor(
        protected override source?: AnySourceStream<V, E, C>,
        protected areEqual?: (current: V, old: Set<V>) => boolean
    ) {
        super(source)
    }

    override value(value: V): void {
        if (this.areEqual) {
            if (this.areEqual(value, this.seen)) {
                return
            }
        } else if (this.seen.has(value)) {
            return
        }
        this.seen.add(value)
        this._value(value)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        distinct(areEqual?: (current: DV, old: Set<DV>) => boolean): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.distinct = function <V, E, C>(
    this: AnySourceStream<V, E, C>,
    areEqual?: (current: V, old: Set<V>) => boolean
) {
    return new Distinct(this, areEqual) // tslint:disable-line:no-invalid-this
}
