import { RpcArgs, RpcName, RpcResults } from './RpcType'
import './ServererFrontend'
import { servererFrontend } from './ServererFrontend'

export function rpcCallerWithStreamResults<Rpc>(rpcName: RpcName<Rpc>) {
    return function (getArgs: () => RpcArgs<Rpc>): RpcResults<Rpc> {
        const sfe = servererFrontend.get()

        return sfe.callRpcAndReturnStream(() => ({
            method: rpcName,
            args: getArgs(),
        })) as any
    }
}
