import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Skip<V, E = never, C = never> extends Pass<V, E, C> {
    skipped = 0
    constructor(protected override source?: AnySourceStream<V, E, C>, protected skipCount?: number) {
        super(source)
    }

    override value(value: V): void {
        if (this.skipped === this.skipCount) {
            this._value(value)
        } else {
            this.skipped++
        }
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        skip(skipCount: number): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.skip = function <V, E, C>(this: AnySourceStream<V, E, C>, skipCount: number) {
    return new Skip(this, skipCount) // tslint:disable-line:no-invalid-this
}
