import reportEvent from '@hypatia/serverer-common/usage/reportEvent'
import analytics from './Analytics'

export function initServerSideAnalytics(): void {
    analytics.subscribeToAll({
        track: ({ event, data }) => {
            reportEvent(event, { ...data })
        },
        identify: ({ email, firstName, lastName }) => {
            reportEvent('identify', { email, firstName, lastName })
        },
        registerSuperProperties: ({ data }) => {
            reportEvent('registerSuperProperties', { ...data })
        },
        reset: () => {
            reportEvent('reset')
        },
        trackOnDisconnect: ({ event, data, trackingId }) => {
            reportEvent(event, { ...data }, trackingId)
        },
        removeTrackOnDisconnect: ({ trackingId }) => {
            reportEvent('removeTrackOnDisconnect', {}, trackingId)
        },
    })
}
