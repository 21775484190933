import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class GetAllDistinct<V, E = never, C = never> extends Pass<V, E, C, Set<V>, E, C> {
    public values: Set<V> = new Set()

    override value(value: V): void {
        if (!this.values.has(value)) {
            this.values = new Set(this.values)
            this.values.add(value)
            super._value(this.values)
        }
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        getAllDistinct(): Stream<DV, DE, DC, Set<DV>, DE, DC>
    }
}

Stream.prototype.getAllDistinct = function <V, E, C>(this: AnySourceStream<V, E, C>) {
    return new GetAllDistinct(this) // tslint:disable-line:no-invalid-this
}
