import isReactNative from '@hypatia/utils/os/isReactNative'

export default function decodeToken(token: string): Record<string, any> {
    if (!token) {
        return undefined as any
    }

    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

    const isNative = isReactNative()

    const jsonPayload = decodeURIComponent(
        atob(isNative ? addBase64Padding(base64) : base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

function addBase64Padding(base64: string): string {
    while (base64.length % 3 !== 0) {
        base64 += '='
    }

    return base64
}
