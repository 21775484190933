import forEach from 'lodash/forEach'
import map from 'lodash/map'
import { build } from '../generators/build'
import { AnySourceStream, Stream, Subscription, UnpackedStreamOrPromise } from '../stream'
import { Pass } from './pass'

export type Flatten<O> = { [K in keyof O]: UnpackedStreamOrPromise<O[K]> }

export const flattenObjectValues = <O1 extends object, O2 extends object = {}>(
    streamsToWaitFor: O1,
    streamsWithoutWaiting: O2 = {} as O2,
    options?: { showWaiting?: boolean }
): AnySourceStream<Flatten<O1 & O2>> => {
    type O = O1 & O2

    return build((drain) => {
        const subs: Record<keyof O, Subscription<any> | undefined> = {} as any
        const results: Record<keyof O, any> = {} as any
        const waiting = new Set<keyof O>()

        let finishedMap = false
        map({ ...streamsToWaitFor, ...streamsWithoutWaiting } as any, (streamLike: any, key: keyof O) => {
            if (!(streamLike instanceof Stream) && !(streamLike instanceof Promise)) {
                results[key] = streamLike
                return
            }

            if ((key as any) in streamsToWaitFor) {
                waiting.add(key)
            }

            const onValue = (newValue: any) => {
                results[key] = newValue
                waiting.delete(key)
                if (process.env.NODE_ENV === 'development' && options?.showWaiting) {
                    // eslint-disable-next-line no-console
                    console.log(waiting)
                }
                if (waiting.size === 0 && finishedMap) {
                    drain.value({ ...results })
                }
            }

            if (streamLike instanceof Stream) {
                const sub = {
                    value: onValue,
                    error: (error: any) => {
                        // eslint-disable-next-line no-console
                        console.error(error)
                        drain.error(error)
                    },
                }
                subs[key] = sub

                streamLike.subscribe(sub)
            } else if (streamLike instanceof Promise) {
                streamLike.then(onValue).catch((e) => drain.error(e))
            }
        })

        finishedMap = true

        if (waiting.size === 0) {
            drain.value({ ...results })
        }

        return () => {
            forEach(({ ...streamsToWaitFor, ...streamsWithoutWaiting } as any) || ({} as any), (value, key) => {
                if (value instanceof Stream) {
                    value.unsubscribe(subs[key as keyof O]!)
                }
            })
        }
    }) as any
}
export class FlattenObjectValues<O, E = any, C = any> extends Pass<O, E, C, Flatten<O>> {
    subs: Record<keyof O, Subscription<any> | undefined> = {} as any
    results: Record<keyof O, any> = {} as any
    object: O | undefined = undefined
    waiting = new Set<keyof O>()

    constructor(source?: AnySourceStream<O, E, C>, public waitAllValues = true) {
        super(source)
    }

    override disconnect(): void {
        super.disconnect()

        forEach(this.object || ({} as any), (value, key) => {
            if (value instanceof Stream) {
                value.unsubscribe(this.subs[key as keyof O]!)
            }
        })
    }

    override value(object: O) {
        const oldResults = this.results
        this.results = {} as any

        map(object as any, (value: any, key: keyof O) => {
            const oldValue = this.object?.[key]
            if (oldValue === value) {
                this.results[key] = oldResults[key]
                return
            }

            if (oldValue instanceof Stream) {
                this.waiting.delete(key)
                oldValue.unsubscribe(this.subs[key]!)
            }

            if (!(value instanceof Stream)) {
                this.results[key] = value
                return
            }

            const sub = {
                value: (newValue: any) => {
                    this.results[key] = newValue
                    this.waiting.delete(key)
                    if (!this.waitAllValues || this.waiting.size === 0) {
                        this._value(this.results)
                    }
                },
            }

            this.waiting.add(key)

            this.subs[key] = sub

            // TODO, only subscribe if hot
            value.subscribe(sub)
        })
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        flattenObjectValues(waitAllValues?: boolean): Stream<DV, DE, DC, Flatten<DV>, DE, DC>
    }
}

Stream.prototype.flattenObjectValues = function <V, E, C>(this: AnySourceStream<V, E, C>, waitAllValues?: boolean) {
    return new FlattenObjectValues(this, waitAllValues ?? true) // tslint:disable-line:no-invalid-this
}
