import { isEqualShallow } from '@hypatia/utils/comparators/is-equal-shallow'
import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

export function useEffectRightNow(effect: EffectCallback, deps: DependencyList) {
    const lastDeps = useRef<DependencyList>()
    const disconnecter = useRef<((() => void) | undefined) | void>()

    if (!isEqualShallow(lastDeps.current, deps)) {
        if (disconnecter.current) {
            disconnecter.current()
        }
        disconnecter.current = effect()
    }

    useEffect(
        () => () => {
            if (disconnecter.current) {
                disconnecter.current()
            }
        },
        []
    )

    lastDeps.current = deps
}
