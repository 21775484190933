import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export interface Counted<T> {
    data: T
    count: number
}

export class Count<V, E = never, C = never> extends Pass<V, E, C, Counted<V>> {
    protected i = 0

    override value(value: V): void {
        this._value({
            data: value,
            count: ++this.i,
        })
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        count(): Stream<DV, DE, DC, Counted<DV>, DE, DC>
    }
}

Stream.prototype.count = function <V, E, C>(this: AnySourceStream<V, E, C>) {
    return new Count(this) // tslint:disable-line:no-invalid-this
}
