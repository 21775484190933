import noop from 'lodash/noop'
import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

type DisposeFn = () => void

export class DoOnConnecting<V, E = never, C = never> extends Pass<V, E, C> {
    protected dispose: DisposeFn = noop
    constructor(
        protected onConnecting: (stream: Stream<V, E, C>) => DisposeFn | void,
        source?: AnySourceStream<V, E, C>
    ) {
        super(source)
    }

    override connect(): void {
        this.dispose = this.onConnecting(this) || noop
        super.connect()
    }

    override disconnect(): void {
        super.disconnect()
        this.dispose()
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        doOnConnecting(onConnecting: (stream: Stream<DV, DE, DC>) => DisposeFn | void): Stream<DV, DE, DC, DV, DE, DC>
    }
}

Stream.prototype.doOnConnecting = function <DV, DE, DC>(
    this: AnySourceStream<DV, DE, DC>,
    onSubscription: (stream: Stream<DV, DE, DC>) => DisposeFn | void
) {
    return new DoOnConnecting(onSubscription, this) // tslint:disable-line:no-invalid-this
}
