import { PERMISSION_DENIED } from '../communication/errors'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PermissionDeniedError extends Error {
    constructor(message?: string) {
        super(message)
        this.name = PERMISSION_DENIED
    }
}

export function ensure(val?: boolean, error: string = PERMISSION_DENIED): void {
    if (!val) {
        throw new Error(error)
        // throw new PermissionDeniedError(details)
    }
}
