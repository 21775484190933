import { AnySourceStream, Stream, Subscription } from '../stream'
import { Pass } from './pass'

export class FlatMap<V, E = any, C = any, N2 = V> extends Pass<V, E, C, N2> {
    protected mapper: (value: V) => AnySourceStream<N2, any, any>
    protected streamSub: { stream: AnySourceStream<N2, any, any>; sub: Subscription<N2, any, any> } | undefined

    constructor(mapper: (value: V) => AnySourceStream<N2, any, any>, source?: AnySourceStream<V, E, C>) {
        super(source)
        this.mapper = mapper
    }

    override disconnect(): void {
        super.disconnect()

        this.streamSub?.stream.unsubscribe(this.streamSub.sub)
        this.streamSub = undefined
    }

    override value(value: V): void {
        const stream = this.mapper(value)

        if (this.streamSub?.stream === stream) {
            return
        }

        if (this.streamSub) {
            this.streamSub.stream.unsubscribe(this.streamSub.sub)
            this.streamSub = undefined
        }

        const sub: Subscription<N2, any, any> = {
            value: (n: N2) => this._value(n),
            error: (e) => {
                this.error(e)
            },
            complete: () => {
                stream.unsubscribe(sub)
                this.streamSub = undefined
            },
        }

        this.streamSub = {
            stream,
            sub,
        }
        stream.subscribe(sub)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        flatMap<T>(mapper: (value: DV) => AnySourceStream<T, any, any>): Stream<DV, DE, DC, T, DE, DC>
    }
}

Stream.prototype.flatMap = function <V, E, C, T>(
    this: AnySourceStream<V, E, C>,
    mapper: (value: V) => AnySourceStream<T, any, any>
) {
    return new FlatMap(mapper, this) // tslint:disable-line:no-invalid-this
}
