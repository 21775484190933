import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function oneOf<T>(docs: T[], customMessage?: ValidationMessage): FieldValidation {
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.alreadyExist'),
        validate: ({ value, fieldName, doc }) => {
            if ((doc as any).fieldName === value.trim()) {
                return false
            }
            return docs.some((x) => (x as any)[fieldName] === value?.trim())
        },
    }
}
