import {
    decode as baseDecode,
    decodeAsync as baseDecodeAsync,
    encode as baseEncode,
    ExtensionCodec,
} from '@msgpack/msgpack'
import { ReadableStreamLike } from '@msgpack/msgpack/dist/utils/stream'
import { CustomError, errorsRegistry } from '../communication/errors'
// import { Decimal128 } from 'bson'

const extensionCodec = new ExtensionCodec()

// Set<T>
const SET_EXT_TYPE = 0
extensionCodec.register({
    type: SET_EXT_TYPE,
    encode: (object: unknown): Uint8Array | null => {
        if (object instanceof Set) {
            return baseEncode([...object])
        } else {
            return null
        }
    },
    decode: (data: Uint8Array) => {
        const array = baseDecode(data) as Array<unknown>
        return new Set(array)
    },
})

// Map<T>
const MAP_EXT_TYPE = 1
extensionCodec.register({
    type: MAP_EXT_TYPE,
    encode: (object: unknown): Uint8Array | null => {
        if (object instanceof Map) {
            return baseEncode([...object])
        } else {
            return null
        }
    },
    decode: (data: Uint8Array) => {
        const array = baseDecode(data) as Array<[unknown, unknown]>
        return new Map(array)
    },
})

const BIGINT_EXT_TYPE = 2
extensionCodec.register({
    type: BIGINT_EXT_TYPE,
    encode: (input: unknown) => {
        if (typeof input === 'bigint') {
            return baseEncode(input.toString())
        } else {
            return null
        }
    },
    decode: (data: Uint8Array) => BigInt(baseDecode(data) as any),
})

// forgot about decimal, we don't have any right now
// const DECIMAL_EXT_TYPE = 3
// extensionCodec.register({
//     type: DECIMAL_EXT_TYPE,
//     encode: (input: unknown) => {
//         if (input instanceof Decimal128) {
//             return baseEncode(input.toString())
//         } else {
//             return null
//         }
//     },
//     decode: (data: Uint8Array) => {
//         return Decimal128.fromString(baseDecode(data) as string)
//     },
// })

const CUSTOM_ERROR_EXT_TYPE = 4
extensionCodec.register({
    type: CUSTOM_ERROR_EXT_TYPE,
    encode: (input: unknown) => {
        if (input instanceof CustomError) {
            return baseEncode({ name: input.name, message: input.message, status: input.status, data: input.data })
        } else {
            return null
        }
    },
    decode: (input: Uint8Array) => {
        const { name, message, status, data } = baseDecode(input) as any
        if (errorsRegistry.has(name)) {
            return new (errorsRegistry.get(name)!)(message, data)
        }
        return new CustomError(name, message, status, data)
    },
})

const ERROR_EXT_TYPE = 5
extensionCodec.register({
    type: ERROR_EXT_TYPE,
    encode: (input: unknown) => {
        if (input instanceof Error) {
            return baseEncode(input.message)
        } else {
            return null
        }
    },
    decode: (data: Uint8Array) => new Error(baseDecode(data) as string),
})

export const encode = (x: unknown) => baseEncode(x, { extensionCodec, ignoreUndefined: true })

export const decode = (x: ArrayLike<number> | ArrayBuffer) => baseDecode(x, { extensionCodec })

export const decodeAsync = (x: ReadableStreamLike<ArrayLike<number> | BufferSource>) =>
    baseDecodeAsync(x, { extensionCodec })
