import { FieldValidation } from './types'
import validateField from './validateField'

export default function _if<O>(
    condition: (doc: O) => boolean,
    rule: FieldValidation,
    _else?: FieldValidation
): FieldValidation {
    return {
        message: ({ error }) => error,
        validate: (args) => {
            if (condition(args.doc)) {
                return validateField(args.doc, args.fieldName, rule)
            }
            if (_else) {
                return validateField(args.doc, args.fieldName, _else)
            }
            return true
        },
    }
}
