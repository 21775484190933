export function deepClone<T>(obj: T): T {
    if (typeof obj !== 'object' || !obj) {
        return obj
    }
    if (typeof (obj as any).deepClone === 'function') {
        return (obj as any).deepClone()
    }
    const copy: any = {}

    for (const key of Object.keys(obj)) {
        copy[key] = deepClone((obj as any)[key])
    }

    return copy as T
}
