import useAsRef from '../useAsRef'
import { useEffect } from 'react'

export function useWaitFor<T = any>(process: () => T, waitForList: boolean[]) {
    const processRef = useAsRef(process)
    const stillWaiting = waitForList.some((x) => x)

    useEffect(() => {
        if (stillWaiting) {
            return
        }
        processRef.current()
    }, [stillWaiting, processRef])
}
