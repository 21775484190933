export const INTERNAL_SERVER_ERROR = 'InternalServerError' as const
export const DOCUMENT_NOT_FOUND = 'DocumentNotFound' as const
export const INSTITUTE_NOT_FOUND = 'InstituteNotFound' as const
export const USER_DOES_NOT_BELONG_TO_ANY_INSTITUTE = 'userDoesNotBelongToAnyInstitute' as const
export const NESTED_TRANSACTIONS_ARE_NOT_ALLOWED_ANYMORE = 'nestedTransactionsAreNotAllowedAnymore' as const
export const USER_NOT_FOUND = 'UserNotFound' as const
export const PERMISSION_DENIED = 'PermissionDenied' as const
export const METHOD_NOT_DEFINED = 'MethodNotDefined' as const
export const UPDATE_WITHOUT_ID = 'UpdateWithoutId' as const
export const DELETE_WITHOUT_ID = 'DeleteWithoutId' as const
export const INSERT_WITHOUT_ID_IS_NOT_ALLOWED = 'InsertWithoutIdIsNotAllowed' as const
export const SOCKET_CONNECTION_ERROR = 'SocketConnectionError' as const
export const ID_TOKEN_NOT_VALID = 'IdTokenNotValid' as const
export const TOKEN_NOT_VALID = 'IdTokenNotValid' as const
export const BAD_REQUEST = 'BadRequest' as const
export const USER_DISABLED = 'UserDisabled' as const
export const PASSWORD_MISMATCH = 'PasswordMismatch' as const
export const NOT_AUTHORIZED = 'NotAuthorized' as const
export const NOT_SUPPORTED = 'NotSupported' as const
export const EXPIRED_OR_USED_TOKEN = 'ExpiredOrUsedToken' as const
export const MULTIPLE_OPERATIONS_ON_THE_SAME_DOCUMENT_IN_THE_SAME_TRANSACTION =
    'MultipleOperationsOnTheSameDocumentInTheSameTransaction' as const
export const TIMEOUT = 'Timeout' as const
export const TOO_MANY_REQUESTS = 'TooManyRequests' as const
export const RESOURCE_LOCKED_TO_ANOTHER_SERVER = 'ResourceLockedToAnotherServer' as const
export const INVITE_EXPIRED_OR_NOT_FOUND = 'InviteExpiredOrNotFound' as const
export const INVITE_ALREADY_ACCEPTED = 'InviteAlreadyAccepted' as const
export const INVITE_ALREADY_REJECTED = 'InviteAlreadyRejected' as const
export const STUDENT_ALREADY_IN_GROUP = 'studentAlreadyInGroup' as const
export const FAILED_TO_ADD_STUDENT = 'failedToAddStudent' as const
export const DUPLICATED_EMAILS = 'DuplicatedEmails' as const
export const STUDENT_IS_ORG_MEMBER_YOU_CANT_ADD_MEMBER_AS_STUDENT =
    'studentIsOrgMemberYouCantAddMemberAsStudent' as const
export const STUDENT_ANSWERS_HAVE_ALREADY_BEEN_UPLOADED = 'studentAnswersHaveAlreadyBeenUploaded' as const
export const COULDNT_GET_URL_FROM_FILE_TOKEN = `couldntGetUrlFromFileToken` as const
export const FILE_TOO_LARGE = 'FileTooLarge' as const

type Status = 400 | 401 | 403 | 404 | 409 | 429 | 500 | 503

export const legacyServerErrorsRegistry = new Map<string, Status>()

legacyServerErrorsRegistry.set(EXPIRED_OR_USED_TOKEN, 400)
legacyServerErrorsRegistry.set(METHOD_NOT_DEFINED, 400)
legacyServerErrorsRegistry.set(UPDATE_WITHOUT_ID, 400)
legacyServerErrorsRegistry.set(DELETE_WITHOUT_ID, 400)
legacyServerErrorsRegistry.set(TOKEN_NOT_VALID, 400)
legacyServerErrorsRegistry.set(ID_TOKEN_NOT_VALID, 400)
legacyServerErrorsRegistry.set(DOCUMENT_NOT_FOUND, 404)
legacyServerErrorsRegistry.set(USER_NOT_FOUND, 404)
legacyServerErrorsRegistry.set(INTERNAL_SERVER_ERROR, 500)
legacyServerErrorsRegistry.set(PERMISSION_DENIED, 403)
legacyServerErrorsRegistry.set(FILE_TOO_LARGE, 400)
legacyServerErrorsRegistry.set(USER_DISABLED, 400)
legacyServerErrorsRegistry.set(PASSWORD_MISMATCH, 400)
legacyServerErrorsRegistry.set(BAD_REQUEST, 400)
legacyServerErrorsRegistry.set(INSERT_WITHOUT_ID_IS_NOT_ALLOWED, 400)
legacyServerErrorsRegistry.set(MULTIPLE_OPERATIONS_ON_THE_SAME_DOCUMENT_IN_THE_SAME_TRANSACTION, 400)
legacyServerErrorsRegistry.set(INVITE_EXPIRED_OR_NOT_FOUND, 400)

interface CustomErrorConstructor<D = any> extends CustomError<D> {
    new (message: string, data?: D): CustomError<D>
}
export const errorsRegistry = new Map<string, CustomErrorConstructor>()

export class CustomError<D> extends Error {
    constructor(
        public override readonly name: string,
        public override readonly message: string,
        public readonly status: Status,
        public readonly data?: D
    ) {
        super(message)
    }
}

export class InternalServerError extends CustomError<never> {
    constructor(message: string) {
        super(INTERNAL_SERVER_ERROR, message, 500)
    }
}

export class DocumentNotFoundError extends CustomError<never> {
    constructor(message: string) {
        super(DOCUMENT_NOT_FOUND, message, 404)
    }
}

export class UserNotFoundError extends CustomError<never> {
    constructor(message: string) {
        super(USER_NOT_FOUND, message, 404)
    }
}

export class PermissionDeniedError extends CustomError<never> {
    constructor(message: string) {
        super(PERMISSION_DENIED, message, 403)
    }
}

export class MethodNotDefinedError extends CustomError<never> {
    constructor(message: string) {
        super(METHOD_NOT_DEFINED, message, 400)
    }
}
