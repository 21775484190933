import { AnySourceStream, Stream, Subscription } from '../stream'

declare module '../stream' {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        asPromiseOnComplete(): Promise<DC>
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}

Stream.prototype.asPromiseOnComplete = function <C>(this: AnySourceStream<any, any, C>) {
    return new Promise<C>((resolve, reject) => {
        const subscription: Subscription<C> = {
            complete: (val: C) => {
                this.unsubscribe(subscription)
                resolve(val)
            },
            error: (err: any) => {
                this.unsubscribe(subscription)
                reject(err)
            },
        }
        this.subscribe(subscription)
    })
}
