import { AccountingNumber } from '@cashii/common/src/db/db'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import { GridColDef } from '@mui/x-data-grid-premium'
import { pick, sumBy } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { CustomToolbar } from '../../components/CustomToolbar'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import classes from './AccountsPage.module.css'

export default React.memo(AccountsPage)

type Account = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getAllAccounts>
function AccountsPage(): JSX.Element {
    const [_accounts] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllAccounts(), [])
    const [accounts, setAccounts] = React.useState<Account[] | null>(null)

    const alert = useAlertNotification()
    
    useEffect(() => {
        if (_accounts) {
            setAccounts(_accounts)
        }
    }, [_accounts])

    const getRowId = useCallback((row: Account) => row._id, [])
    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            { field: 'accountingNumber', headerName: 'Accounting Number' },
            { field: 'accountingParentNumber', headerName: 'Accounting Parent Number', type: 'string', editable: true },
            {
                field: 'minimumBalance',
                headerName: 'Minimum Balance',
                type: 'number',
                editable: true,
                valueGetter: ({ row }) => (row.minimumBalance || 0) / 100,
                valueSetter: ({ row, value }) => {
                    return { ...row, minimumBalance: Math.round(value * 100) }
                },
            },
            { field: 'nickname', headerName: 'Nickname', editable: true, type: 'string' },
            { field: 'userId', headerName: 'User Id' },
            { field: 'user.fullName', headerName: 'Full Name', valueGetter: (doc) => doc.row.user?.fullName },
            { field: 'market.name', headerName: 'Market Name', valueGetter: (doc) => doc.row.market?.name },
            { field: 'accountType', headerName: 'Type' },
            { field: 'systemAccount', headerName: 'System Account', type: 'boolean' },
            { field: 'balance', headerName: 'Balance', type: 'number', valueGetter: (doc) => doc.row.balance / 100 },
            {
                field: 'lockedBalance',
                headerName: 'Locked Balance',
                type: 'number',
                valueGetter: (doc) => sumBy(doc.row.lockedBalance, (x) => x.amount) / 100,
            },
            { field: 'currency', headerName: 'Currency' },
            { field: 'createdAt', headerName: 'Created At', type: 'date' },
        ] as GridColDef<Account>[]
    }, [])

    const processRowUpdate = useCallback(
        async (newRow: Account) => {
            const [err] = await clientUpdates.controlPanel.updateAccount(newRow._id, {
                ...pick(newRow, 'minimumBalance', 'nickname', 'accountingParentNumber'),
                accountingNumber: (newRow.accountingParentNumber + newRow._id) as AccountingNumber,
            })
            if (err) {
                throw err
            }
            alert({
                variant: 'success',
                alertText: 'Account updated',
            })
            return newRow
        },
        [alert]
    )

    const handleProcessRowUpdateError = useCallback(
        (error: string) => {
            alert({
                variant: 'error',
                alertText: error,
            })
        },
        [alert]
    )

    if (!accounts) {
        return <div>Loading...</div>
    }
    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="accounts"
                components={{
                    Toolbar: CustomToolbar,
                }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                initialState={{
                    aggregation: {
                        model: {
                            balance: 'sum',
                        },
                    },
                }}
                className={classes.table}
                getRowId={getRowId}
                rows={accounts}
                columns={columns}
            />
        </div>
    )
}
