import Router from '@hypatia/react-utils/router/Router'
import React from 'react'
import useRouteContext from './Routing/useRouteContext'

export default React.memo(App)

function App(): JSX.Element {
    const routeContext = useRouteContext()
    return <Router routeContext={routeContext} fallback={<p>{'Loading...'}</p>} />
}
