import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Filter<V, E = never, C = never> extends Pass<V, E, C> {
    protected filterer: (value: V) => boolean

    constructor(filter: (value: V) => boolean, source?: AnySourceStream<V, E, C>) {
        super(source)
        this.filterer = filter
    }

    override value(value: V): void {
        if (this.filterer(value)) {
            this._value(value)
        }
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        filter(filter: (value: DV) => boolean): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.filter = function <V, E, C>(this: AnySourceStream<V, E, C>, filter: (value: V) => boolean) {
    return new Filter(filter, this) // tslint:disable-line:no-invalid-this
}
