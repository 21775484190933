import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function maxLength(length: number, customMessage?: ValidationMessage): FieldValidation {
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.maxLength', { length }),
        validate: ({ value }) => {
            if (value && value instanceof Array) {
                return value.length <= length
            }
            switch (typeof value) {
                case 'string':
                    return value.length <= length
                default:
                    return false
            }
        },
    }
}
