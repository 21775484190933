import _Emitter from 'component-emitter'
import forEach from 'lodash/forEach'

export type EventsSubscribers<T> = {
    // @ts-expect-error
    [K in keyof T]: (...args: T[K]) => void
}

export class Emitter<T> extends _Emitter<T> {
    subscribeToAll(allEvents: EventsSubscribers<T>): () => void {
        forEach(allEvents, (event, name) => {
            this.on(name as keyof T, event)
        })

        return () => {
            forEach(allEvents, (event, name) => {
                this.off(name as keyof T, event)
            })
        }
    }
}
