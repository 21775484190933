import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function email(customMessage?: ValidationMessage): FieldValidation {
    const re = /\S+@\S+\.\S+/
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.notAnEmail'),
        validate: ({ value }) => {
            if (!value) {
                return true
            }
            switch (typeof value) {
                case 'string':
                    return re.test(value)
                default:
                    return false
            }
        },
    }
}
