import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Take<V, E = never, C = never> extends Pass<V, E, C, V, E, C | undefined> {
    protected remaining: number

    constructor(count: number, source?: AnySourceStream<V, E, C>) {
        super(source)
        this.remaining = count
    }

    override value(value: V): void {
        this._value(value)
        this.remaining--
        if (this.remaining === 0) {
            this._complete(undefined)
        }
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        take(count: number): Stream<DV, DE, DC, DV, DE, DC | undefined>
    }
}

Stream.prototype.take = function <V, E, C>(this: AnySourceStream<V, E, C>, count: number) {
    return new Take(count, this) // tslint:disable-line:no-invalid-this
}
