import { RPCRequest } from '@hypatia/serverer-common/rpc/RPCRequest'
import { RPCResponse } from '@hypatia/serverer-common/rpc/RPCResponse'
import { RpcArg, ServererFrontend } from '@hypatia/serverer-common/rpc/ServererFrontend'
import { StreamToClientEvent } from '@hypatia/serverer-common/rpc/StreamToClientEvent'
import unsubscribeFromStream from '@hypatia/serverer-common/rpc/unSubscribeFromStream'
import generateUniqueId from '@hypatia/utils/generators/generateUniqueId'
import tracer from '@hypatia/utils/loggers/tracer'
import { build } from '@hypatia/utils/streams/generators/build'
import '@hypatia/utils/streams/operators'
import { Stream } from '@hypatia/utils/streams/stream'
import * as opentelemetry from '@opentelemetry/api'
import connectionStatus$ from './connectionStatus$'

const TIMEOUT_DURATION = (1 * 60 + 10) * 1000

export abstract class BaseSocketServererFrontend implements ServererFrontend {
    eventStream$ = new Stream<StreamToClientEvent>()
    pendingRequests = new Map<string, RPCRequest>()

    callRPC(req: RpcArg): Promise<any> {
        const requestId = generateUniqueId()

        const request: RPCRequest = {
            requestId,
            ...req,
        }

        const span = tracer.startSpan(req.method)
        const context = opentelemetry.trace.setSpan(opentelemetry.context.active(), span)
        opentelemetry.propagation.inject(context, request)

        this.send(request)

        return new Promise<any>((resolve, reject) => {
            this.requestIdsToResolvers.set(requestId, {
                resolve,
                reject,
                request,
                span,
            })
            setTimeout(() => {
                if (this.requestIdsToResolvers.has(requestId)) {
                    this.requestIdsToResolvers.delete(requestId)
                }
            }, TIMEOUT_DURATION)
        })
    }

    callRpcAndReturnStream<T>(getReq: () => RpcArg<string, any>): Stream<any, never, never, any, never, never> {
        return build<T>((drain) => {
            const streamId = generateUniqueId()

            const unSub = this.eventStream$
                .filter((event) => event.streamId === streamId)
                .subscribe((res) => {
                    if ('error' in res) {
                        drain.error(res.error)
                        return
                    }
                    if ('data' in res) {
                        drain.value(res.data)
                    }
                })

            // TODO, signal that subscription is done successfully maybe?
            const unSubOnReconnection = this.callOnReconnection(() => {
                this.callRPC({ ...getReq(), streamId }).catch(drain.error.bind(drain))
            })

            return () => {
                unSub()
                unSubOnReconnection()
                unsubscribeFromStream({ streamId: streamId })
            }
        })
    }

    isConnected = false
    isConnecting = false
    _onConnectFunctions = new Set<() => void>()

    callOnReconnection(fn: () => void): () => void {
        if (this.isConnected) {
            fn()
        }
        this._onConnectFunctions.add(fn)

        return () => {
            this._onConnectFunctions.delete(fn)
        }
    }

    onMessage = (data: any): void => {
        if (Object.entries(data).length !== 0) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.debug('Message from server', data)
            }
        }

        if (data.requestId) {
            this.pendingRequests.delete(data.requestId)
        }

        this.responseHandler(data)
    }

    requestIdsToResolvers = new Map<
        string,
        {
            resolve: (value?: any) => void
            reject: (reason?: any) => void
            request: RPCRequest
            span: opentelemetry.Span
        }
    >()

    responseHandler(res: RPCResponse<any> | StreamToClientEvent): void {
        if (res.messageType === 'RPCResponse') {
            const { requestId, results } = res
            if (!this.requestIdsToResolvers.has(requestId)) {
                return
            }

            const { reject, resolve, span } = this.requestIdsToResolvers.get(requestId)!

            this.requestIdsToResolvers.delete(requestId)
            // transaction?.addLabels({ status: res.status + '' })

            if (res.status === 200) {
                if (span) {
                    ;(span as any).outcome = 'success'
                }
                span.setStatus({ code: opentelemetry.SpanStatusCode.OK })
                resolve(results)
            } else {
                span.setStatus({ code: opentelemetry.SpanStatusCode.ERROR })

                // transaction.addLabels({
                //     errorCode: res.errorCode + '',
                //     req: JSON.stringify(request),
                //     res: JSON.stringify(res),
                // })

                // apm?.captureError(res.errorCode!)
                reject(res.errorCode)
            }

            span.end()
        }

        if (res.messageType === 'StreamUpdateEvent') {
            this.eventStream$.value(res)
        }
    }

    onConnect(): void {
        this._onConnectFunctions.forEach((fn) => fn())

        this.pendingRequests.forEach((request) => {
            this.send(request)
        })

        this.isConnected = true
        this.isConnecting = false

        connectionStatus$.value('online')
    }

    async send(req: RPCRequest): Promise<void> {
        this.pendingRequests.set(req.requestId, req)

        if (!this.isConnected || this.isConnecting) {
            return
        }

        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.debug('Sending message to server', req)
        }

        this.emit(req)
    }

    onDisconnect(): void {
        this.isConnected = false
        this.isConnecting = false
        connectionStatus$.value('offline')
    }

    abstract emit(req: RPCRequest): void
}
