import { FieldValidation, FieldValidationArgs, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function lessThan<O = any>(
    lessThanValue: number | ((args: FieldValidationArgs<number | undefined, O>) => number),
    customMessage?: ValidationMessage
): FieldValidation<number | undefined, O> {
    return {
        message: getCustomizedMessage(customMessage, 'auth.latencyAllowedShouldNotBeGraterThanExamDuration', {
            lessThanValue,
        }),
        validate: (args) => {
            if (args.value === undefined) {
                return false
            }
            let n: number

            if (typeof lessThanValue === 'function') {
                n = lessThanValue(args)
            } else {
                n = lessThanValue
            }

            return args.value < n
        },
    }
}
