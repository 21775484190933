import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export interface Stamped<T> {
    data: T
    timestamp: number
}

export class Timestamp<V, E = never, C = never> extends Pass<V, E, C, Stamped<V>> {
    // protected source?: AnySourceStream<V, E, C>

    override value(value: V): void {
        this._value({
            data: value,
            timestamp: Date.now(),
        })
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        timestamp(): Stream<DV, DE, DC, Stamped<DV>, DE, DC>
    }
}

Stream.prototype.timestamp = function <V, E, C>(this: AnySourceStream<V, E, C>) {
    return new Timestamp(this) // tslint:disable-line:no-invalid-this
}
