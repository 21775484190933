import { TrackingId } from '@hypatia/serverer-common/usage/TrackingId'
import generateUniqueId from '@hypatia/utils/generators/generateUniqueId'
import { Emitter } from './Emitter'

export type BasicType = string | number | boolean | undefined
export type Properties = Record<string, BasicType | BasicType[]>

export interface EventsMap {
    identify: [{ email: string; firstName?: string; lastName?: string }]
    track: [{ event: string; data?: Properties }]
    registerSuperProperties: [{ data: Properties }]
    reset: [void]
    trackOnDisconnect: [{ event: string; data?: Properties; trackingId: TrackingId }]
    removeTrackOnDisconnect: [{ trackingId: TrackingId }]
}

class Analytics extends Emitter<EventsMap> {
    identify(email: string, { firstName, lastName }: { firstName?: string; lastName?: string }): void {
        this.emit('identify', { email, firstName, lastName })
    }

    track(event: string, data: Properties): void {
        this.emit('track', { event, data })
    }

    registerSuperProperties(data: Properties): void {
        this.emit('registerSuperProperties', { data })
    }

    reset(): void {
        this.emit('reset')
    }

    trackOnDisconnect(event: string, data: Properties, trackingId?: TrackingId): void {
        if (!trackingId) {
            trackingId = generateUniqueId<TrackingId>()
        }
        this.emit('trackOnDisconnect', { event, data, trackingId })
    }

    removeTrackOnDisconnect(trackingId: TrackingId): void {
        this.emit('removeTrackOnDisconnect', { trackingId })
    }
}

const analytics = new Analytics()
export default analytics
