// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gobtm0F_lsJ3aK_YVkW7 {\n    direction: ltr;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n}\n\n.KHD0Jq7w68HCnnx6LSNR {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Accounts/AccountsPage.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".container {\n    direction: ltr;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n}\n\n.table {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "gobtm0F_lsJ3aK_YVkW7",
	"table": "KHD0Jq7w68HCnnx6LSNR"
};
export default ___CSS_LOADER_EXPORT___;
