// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".os5PoBC4C3b7p3P3Yasy {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    width: 350px;\n}\n\n.os5PoBC4C3b7p3P3Yasy .VVw7II6WpXUNRwrEBp_1 {\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.wQjA0EDmdc0psD5c5srw li {\n    list-style: none;\n}\n.wQjA0EDmdc0psD5c5srw li a {\n    background: #eee;\n    margin-block: 8px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 8px 16px;\n    text-decoration: none;\n    color: #2870B8;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/CashMachine/CashMachinePage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    width: 350px;\n}\n\n.form .input {\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.list li {\n    list-style: none;\n}\n.list li a {\n    background: #eee;\n    margin-block: 8px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 8px 16px;\n    text-decoration: none;\n    color: #2870B8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "os5PoBC4C3b7p3P3Yasy",
	"input": "VVw7II6WpXUNRwrEBp_1",
	"list": "wQjA0EDmdc0psD5c5srw"
};
export default ___CSS_LOADER_EXPORT___;
