import { createContext, useContext } from '../hooks/useContextSelector'

export const AlertNotificationContext = createContext<AlertNotificationContextType>()
export type NotificationVariant = 'error' | 'success' | 'warning' | 'info'
export type NotificationPriority = 'low' | 'high'
export interface AlertValuesType {
    variant: NotificationVariant
    priority?: NotificationPriority
    messageText?: string
    alertText: string
    firstButton?: string
    secondButton?: string
    // eslint-disable-next-line spellcheck/spell-checker
    dismissible?: boolean
    handleFirstButton?: () => void
    handleSecondButton?: () => void
    onDismiss?: () => void
}

export type AlertNotificationContextType = (alertValues: AlertValuesType) => void

export const useAlertNotification = () => useContext(AlertNotificationContext)
