import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function onlyDigits(customMessage?: ValidationMessage): FieldValidation {
    const re = /^[0-9]*$/
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.onlyDigits'),
        validate: ({ value }) => {
            if (!value) {
                return true
            }
            switch (typeof value) {
                case 'string':
                    return re.test(value)
                default:
                    return false
            }
        },
    }
}
