import moment from 'moment'
import { getCustomizedMessage } from './getCustomizedMessage'
import { FieldValidation, ValidationMessage } from './types'

export default function atLeastXYearsOld<O>(
    x: number,
    customMessage?: ValidationMessage
): FieldValidation<unknown, O> {
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.atLeastXYearsOld', { x }),
        validate: ({ value }) => moment(value as Date).isBefore(moment().subtract(x, 'years'))
    }
}
