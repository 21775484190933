// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QYtWP57g7jYCzYnAz8lj {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    width: 350px;\n}\n\n.QYtWP57g7jYCzYnAz8lj .EyZvOOtudhx5VQnwzDwe {\n    width: 100%;\n    margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Login/LoginPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    width: 350px;\n}\n\n.form .input {\n    width: 100%;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "QYtWP57g7jYCzYnAz8lj",
	"input": "EyZvOOtudhx5VQnwzDwe"
};
export default ___CSS_LOADER_EXPORT___;
