import { Stream } from '../stream'

export class FromPromiseLazy<T, E = any> extends Stream<undefined, undefined, undefined, T, E> {
    constructor(protected fn: () => Promise<T>) {
        super()
    }

    override connect(): void {
        this.fn().then(
            (result: T) => this._value(result),
            (err: E) => this._error(err)
        )
    }
}

export const $fromPromiseLazy = <T, E = any>(fn: () => Promise<T>) => new FromPromiseLazy<T, E>(fn)
