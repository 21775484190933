import { getRpcCaller } from './rpcCaller'
import { RpcType } from './RpcType'

export interface UnsubscribeFromStreamArgs {
    streamId: string
}

export const UnsubscribeFromStreamRpcName = 'serverer.unsubscribeFromStream'
export type UnsubscribeFromStreamResults = void
export type UnsubscribeFromStreamErrors = void
export type UnsubscribeFromStreamRpc = RpcType<
    typeof UnsubscribeFromStreamRpcName,
    UnsubscribeFromStreamArgs,
    UnsubscribeFromStreamResults,
    UnsubscribeFromStreamErrors
>

export default getRpcCaller<UnsubscribeFromStreamRpc>(UnsubscribeFromStreamRpcName)
