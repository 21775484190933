import subscribeToQueryRpc from '@hypatia/serverer-common/database/rpc/subscribeToQuery'
import '@hypatia/utils/streams/operators/filter'
import '@hypatia/utils/streams/operators/reduce'
import { AnySourceStream } from '@hypatia/utils/streams/stream'
import queriesStreamsUpdater from '../databaseEvents/queriesStreamsUpdater'

export type MongoObject = { query: any; fields: string[] }
export interface LiveServerQueryArgs {
    queryName: string
    args: any[]
}

export default function subscribeToQuery(args: LiveServerQueryArgs): AnySourceStream<any[]> {
    return subscribeToQueryRpc(() => ({ queryName: args.queryName, args: args.args })).reduce((lastSnapshot, event) => {
        if (event.initialList) {
            return event.initialList
        }

        for (const change of event.changes || []) {
            const newSnapshot = queriesStreamsUpdater(change, lastSnapshot)
            if (newSnapshot) {
                lastSnapshot = newSnapshot
            }
        }

        return lastSnapshot
    }, [] as any[])
}
