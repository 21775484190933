export default function promiseWithTimeout<T>(promise: Promise<T>, timeoutInMs: number, name?: string) {
    return new Promise<T>((resolve, reject) => {
        let resolved = false
        setTimeout(() => {
            if (resolved) {
                return
            }
            resolved = true
            reject(name ? `${name} TIMEOUT` : 'TIMEOUT')
        }, timeoutInMs)

        promise
            .then((result) => {
                if (resolved) {
                    return
                }
                resolved = true
                resolve(result)
            })
            .catch((e) => {
                if (resolved) {
                    return
                }
                resolved = true
                reject(e)
            })
    })
}
