import { history } from '@hypatia/react-utils/router/history'
import React, { useCallback } from 'react'

export function A(props: Props) {
    const onClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
        if (event.metaKey || event.ctrlKey) {
            return
        }
        
        event.preventDefault()
        const href = event.currentTarget.getAttribute('href')
        if (href) {
            history.push(href)
        }
    }, [])
    return <a onClick={onClick} {...props} />
}
interface Props extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> { }
