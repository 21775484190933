import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Scan<SV, SE, SC, DV> extends Pass<SV, SE, SC, DV> {
    protected accumulator: (acc: DV, value: SV) => DV
    protected last: DV

    constructor(accumulator: (acc: DV, value: SV) => DV, last: DV, source: AnySourceStream<SV, SE, SC>) {
        super(source)
        this.accumulator = accumulator
        this.last = last
    }

    override value(value: SV): void {
        this.last = this.accumulator(this.last, value)
        this._value(this.last)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        scan<T>(accumulator: (acc: T, value: DV) => T, last: T): Stream<DV, DE, DC, T, DE, DC>
    }
}

Stream.prototype.scan = function <DV, DE, DC, T>(
    this: AnySourceStream<DV, DE, DC>,
    accumulator: (acc: T, value: DV) => T,
    last: T
) {
    return new Scan(accumulator, last, this) // tslint:disable-line:no-invalid-this
}
