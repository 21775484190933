import moment from 'moment'
import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function date(customMessage?: ValidationMessage): FieldValidation {
    return {
        message: getCustomizedMessage(customMessage, 'auth.thisFieldIsRequired'),
        validate: ({ value }) => {
            if (!value) {
                return false
            }
            return moment(value).isValid()
        },
    }
}
