import React from 'react'
import { AnimationsTypes } from '../components/Animate'
import { UserRecord } from '../containers/UserContext'

export const createRoutesDict = <T extends RouteDict>(routes: T): T => {
    return routes
}

export interface RouteAuthRuleContext {
    user?: UserRecord
}
export type RouteAuthRule = (context: RouteAuthRuleContext) => boolean
export interface RouteObj<P = any> {
    key?: string
    passKeyFromParam?: string
    path: string
    component: React.ComponentType<P>
    auth?: AuthMode
    children?: RouteDict
    exact?: boolean
    extraProps?: Partial<P>
    authRule?: RouteAuthRule
    animation?: AnimationsTypes
    title?: (props: P) => Promise<string>
    trackAsEvent?: string
}
export type RouteDict = Record<string, RouteObj>
export type AuthMode = 'authenticated' | 'unauthenticated'
