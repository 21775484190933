import { AnySourceStream, Stream } from '../stream'

export class StartWith<V, E = undefined, C = undefined> extends Stream<V, E, C> {
    protected source?: AnySourceStream<V, E, C>
    protected first = true
    protected start?: V

    constructor(start: V, source?: AnySourceStream<V, E, C>) {
        super()
        this.source = source
        this.start = start
    }

    override connect(): void {
        super.connect()
        this.value(this.start!)
        if (this.source) {
            this.source.subscribe(this)
        }
    }

    override disconnect(): void {
        super.disconnect()
        if (this.source) {
            this.source.unsubscribe(this)
        }
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        startWith<NewValue>(start: NewValue): Stream<DV, DE, DC, DV | NewValue, DE, DC>
    }
}

Stream.prototype.startWith = function <V, E, C>(this: AnySourceStream<V, E, C>, start: V) {
    return new StartWith(start, this) // tslint:disable-line:no-invalid-this
}
