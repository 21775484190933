import { BaseUserSettings } from '@hypatia/serverer-common/database/BaseUserTable'
import { asyncStorage } from '@hypatia/utils/async-storage'
import removeUndefined from '@hypatia/utils/object/removeUndefined'
import SettableValue from '@hypatia/utils/SettableValue'
import { AsyncErrorOrValue } from '@hypatia/utils/types'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { SettingsContext, SettingsContextType } from '../containers/SettingsContext'
import { useContext } from '../hooks/useContextSelector'
import useDeliverLastOld from '../hooks/useDeliverLastOld'
import { localSettings$, settings$, settingsKey, tokenStream, userSettings$ } from './userIdStream'

export const updateUsersSettings = new SettableValue<(newSettings: BaseUserSettings) => AsyncErrorOrValue<any, any>>(
    'updateUsersSettings'
)

interface Props {
    children: React.ReactChild
}

export const useSettings = (): SettingsContextType => useContext(SettingsContext)

export const saveSettings = async (newSettings: Partial<BaseUserSettings>): Promise<void> => {
    const overAllSettings: BaseUserSettings = { ...settings$.last, ...(newSettings as BaseUserSettings) }

    if (isEqual(userSettings$.last, overAllSettings)) {
        return
    }

    asyncStorage.set(settingsKey, JSON.stringify(removeUndefined(overAllSettings)))

    if (tokenStream.last) {
        updateUsersSettings.get()(overAllSettings)
    }

    localSettings$.value(overAllSettings)

    if (tokenStream.last) {
        userSettings$.value({ ...userSettings$.last, ...newSettings } as BaseUserSettings)
    }
}

export default function SettingsProvider({ children }: Props): JSX.Element {
    const { value } = useDeliverLastOld(settings$)

    return (
        <SettingsContext.Provider value={[value as BaseUserSettings, saveSettings]}>
            {children}
        </SettingsContext.Provider>
    )
}
