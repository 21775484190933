import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class OnError<SV, SE = any, SC = any> extends Pass<SV, SE, SC> {
    protected onErrorFn: (value: SE) => any

    constructor(onErrorFn: (value: SE) => any, source?: AnySourceStream<SV, SE, SC>) {
        super(source)
        this.onErrorFn = onErrorFn
    }

    override error(error: SE): void {
        try {
            this.onErrorFn(error)
        } catch (e) {
            this._error(e as any)
        }
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        onError(onError: (error: SE) => any): Stream<DV, DE, DC, DV, DE, DC>
    }
}

Stream.prototype.onError = function <SV, SE, SC>(this: AnySourceStream<SV, SE, SC>, onError: (error: SE) => any) {
    return new OnError(onError, this) // tslint:disable-line:no-invalid-this
}
