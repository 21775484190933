import { registerClientLiveQueries } from '@hypatia/serverer-common/database/queries/clientLiveQueryRegistry'
// import directById from '@hypatia/serverer-common/database/queries/directById'
import { sLiveQuery as squery } from '@hypatia/serverer-common/database/queries/SLiveQuery'
import { ensure } from '@hypatia/utils/permissions/ensure'
// import directQuery from '@hypatia/serverer-server/database/serverFunctions/directQuery'
// import { ensure } from '@hypatia/utils/permissions/ensure'
import moment from 'moment'
import {
    AccountNumber,
    AccountTable,
    AppNotificationTable,
    BusinessActivityTypeId,
    BusinessActivityTypeTable,
    CashMachineId,
    CashMachineTable,
    CityTable,
    FeesAndLimitsTable,
    GroupId,
    GroupNotificationTable,
    GroupTable,
    MarketId,
    MarketTable,
    MovementTable,
    OfferTable,
    OrderFillTable,
    OrderTable,
    PackageTable,
    QrOperationEnumType,
    QrOperationId,
    QrOperationTable,
    ResetPinCodeRequestTable,
    SubBusinessActivityTypeTable,
    TelegramTokenTable,
    TransactionTable,
    UserTable
} from '../db/db'

export const currentUserFields = [
    '_id',
    'phone',
    'settings',
    'firstName',
    'lastName',
    'fullName',
    'email',
    'createdAt',
    'photo',
    'birthDate',
    'fullNameInEnglish',
    'accounts',
    'uploadedIdPhotos',
    'nationalNumber',
    'city',
    'idVerified',
    'referredBy',
    'referralCode',
    'appSettings',
    'groupId',
    'systemPermissions',
    'qrUser',
    'ownedByUserId',
    'ownedByMarketId',
    'password',
] as const

const clientLiveQueries = registerClientLiveQueries('general', {
    currentUser: async (user) => {
        return squery(UserTable, { _id: user._id }, currentUserFields)
    },
    unReadNotifications: async (user) => {
        return squery(AppNotificationTable, { userId: user._id, read: { $ne: true } }, ['_id'])
    },
    myLastTransactions: async (user, accountNumbers: AccountNumber[]) => {
        // TODO: add limit
        // TODO reenable once we move clientLiveQueries to server
        // accountNumbers = accountNumbers.filter((x) => user.accounts?.some((a) => a._id === x))
        return squery(TransactionTable, {
            $or: [
                { from: { $in: accountNumbers } }, { to: { $in: accountNumbers } }]
        }, [
            '_id',
            'amount',
            'currency',
            'type',
            'createdAt',
            'from',
            'to',
            'fromUser',
            'toUser',
            'fromMarket',
            'toMarket',
            'note',
            'feeFromReceiver',
            'feeFromSender',
            'giftCardSecret'
        ])
    },
    liveQrByOperationId: async (user, _id: QrOperationId, type: QrOperationEnumType) => {
        return squery(QrOperationTable, { _id: _id, type }, [
            '_id',
            'liveQrCode',
            'executedAt',
            'forUser',
            'forMarket',
            'canceled',
            'amount',
            'currency',
            'to',
            'from',
            'confirmedEndUser',
            'market',
            'fee',
            'feeFromReceiver',
            'feeFromSender',
            'cashbackToSender',
            'cashbackToReceiver',
        ])
    },
    cashMachineInfo: async (user, cashMachineId: CashMachineId) => {
        return squery(CashMachineTable, { _id: cashMachineId, accessibleBy: { $in: [user._id] } }, [
            '_id',
            'liveQrCode',
            'currentOperation',
            'type',
        ])
    },
    atmInfo: async (user, cashMachineId: CashMachineId) => {
        return squery(CashMachineTable, { _id: cashMachineId, accessibleBy: { $in: [user._id] } }, [
            '_id',
            'liveQrCode',
            'atm'
        ])
    },
    myMarkets: async (user) => {
        return squery(MarketTable, {
            $or: [
                { userId: user._id },
                // @ts-ignore
                { ['users.userId']: user._id }
            ], active: true
        }, [
            '_id',
            'name',
            'createdAt',
            'accounts',
            'users',
            'appSettings',
            'userId',
            'activityType',
            'logo',
            'groupId',
        ])
    },
    telegramTokenRegistered: async (user, phone: string) => {
        return squery(TelegramTokenTable, { phone, deactivatedAt: null }, ['_id'])
    },
    offers: async (user, type: 'market' | 'personal', activityType?: BusinessActivityTypeId) => {
        // @ts-ignore
        return squery(OfferTable, { active: true, accountType: type, ...(type === 'market' ? { businessActivity: { $in: [activityType] } } : {}) }, [
            '_id',
            'title',
            'description',
            'icon',
            'iconFamily',
            'iconColor',
            'tag',
            'createdAt'
        ])
    },
    offersV2: async (user, groupId: GroupId) => {
        return squery(OfferTable, { active: true, groups: { $in: [groupId] } }, [
            '_id',
            'title',
            'description',
            'icon',
            'iconFamily',
            'iconColor',
            'tag',
            'createdAt'
        ])
    },
    latestCashMachineOperations: async (user, cashMachineId: CashMachineId) => {
        ensure(user.systemUser)
        // ensure((await directById(CashMachineTable, cashMachineId, ['accessibleBy']))?.accessibleBy.includes(user._id))
        return squery(
            TransactionTable,
            {
                cashMachineId: cashMachineId,
                executedAt: {
                    $gt: new Date(Date.now() - 1000 * 60 * 60)
                }
            },
            ['_id', 'amount', 'currency', 'toUser', 'fromUser', 'toMarket', 'fromMarket', 'type', 'executedAt']
        )
    },
    openOrders: async () => {
        return squery(OrderTable, {
            status: { $in: ['open', 'partially-filled'] }
        }, [
            '_id',
            'remainingAmount',
            'price',
            'type'
        ])
    },

    filledOrders: async () => {
        return squery(OrderFillTable, {
            createdAt: {
                $gt: new Date(Date.now() - 1000 * 60 * 60 * 24)
            }
        }, [
            '_id',
            'price',
            'amount',
            'actingSide',
            'createdAt',
        ])
    },

    myOpenOrders: async (user, marketId?: MarketId) => {
        // ensure user is in market
        const filter = marketId ? { marketId } : { userId: user._id }
        return squery(OrderTable, {
            status: { $in: ['open', 'partially-filled'] },
            ...filter,
        }, [
            '_id',
            'amount',
            'filledAmount',
            'remainingAmount',
            'price',
            'type',
            'marketId',
            'userId',
            'createdAt',
            'status',
            'feeRate'
        ])
    },
    controlPanel_resetPinRequestsList: async (user,) => {
        ensure(user.systemUser)

        return squery(ResetPinCodeRequestTable, {}, [
            '_id',
            'userId',
            'idPhotos',
            'createdAt',
            'status'
        ])
    },
    controlPanel_getAllOffers: async (user,) => {
        ensure(user.systemUser)

        return squery(OfferTable, {}, [
            '_id',
            'title',
            'description',
            'icon',
            'iconFamily',
            'iconColor',
            'tag',
            'active',
            'groups',
        ])
    },
    controlPanel_getAllUsers: async (user,) => {
        ensure(user.systemUser)
        return squery(UserTable, {}, [
            '_id',
            'phone',
            'fullName',
            'fullNameInEnglish',
            'createdAt',
            'uploadedIdPhotos',
            'idVerified',
            'nationalNumber',
            'city',
            'photo',
            'idPhotos',
            'gotInitialCredit',
            'groupId',
            'idVerificationNote',
            'sex',
            'disabled',
        ])
    },
    controlPanel_verifyUsersIdsList: async (user,) => {
        ensure(user.systemUser)
        return squery(UserTable, {}, [
            '_id',
            'fullName',
            'fullNameInEnglish',
            'createdAt',
            'uploadedIdPhotos',
            'idVerified',
            'nationalNumber',
            'city',
            'photo',
            'idPhotos',
            'idVerificationNote',
        ])
    },
    controlPanel_getSystemUsers: async (user,) => {
        ensure(user.systemUser)
        return squery(UserTable, { systemUser: true }, [
            '_id',
            'username',
            'disabled',
            'systemPermissions',
        ])
    },
    controlPanel_getAllPackages: async (user,) => {
        ensure(user.systemUser)
        return squery(PackageTable, {}, [
            '_id',
            'provider',
            'name',
            'description',
            'cost',
            'price',
            'currency',
            'type',
            'operatorPackageType',
            'balance',
            'durationInDays',
            'internetGb',
            'internetMb',
            'dk',
            'sms',
            'code',
            'active',
            'costToAccount',
            'profitToAccount',
        ])
    },
    controlPanel_getAllMarkets: async (user,) => {
        ensure(user.systemUser)
        return squery(MarketTable, {}, [
            '_id',
            'name',
            'city',
            'createdAt',
            'address',
            'activityType',
            'subActivityType',
            'active',
            'businessType',
            'details',
            'coordinates',
            'logo',
            'licensePhoto',
            'frontPhoto',
            'showInList',
            'showInDepositList',
            'groupId',
        ])
    },
    controlPanel_getAllTransactions: async (user,) => {
        ensure(user.systemUser)
        const startOfToday = moment().startOf('day').toDate()
        return squery(TransactionTable, {/*  createdAt: { $gt: startOfToday } */ }, [
            '_id',
            'createdAt',
            'executedAt',
            'amount',
            'from',
            'to',
            'fromUser',
            'toUser',
            'fromMarket',
            'toMarket',
            'currency',
            'feeFromReceiver',
            'feeFromSender',
            'type',
            'note',
            'refundForTransactionId',
            'refundedInTransactionId',
            'feeAccountFromReceiver',
            'feeAccountFromSender',
            'otherExchangeTransactionId',
            'cashMachineId',
        ])
    },
    controlPanel_getAllMovements: async (user,) => {
        ensure(user.systemUser)
        const startOfToday = moment().startOf('day').toDate()
        return squery(MovementTable, { /* createdAt: { $gt: startOfToday } */ }, [
            '_id',
            'createdAt',
            'executedAt',
            'amount',
            'accountId',
            'transactionId'
        ])
    },
    controlPanel_getAllAccounts: async (user,) => {
        ensure(user.systemUser)
        return squery(AccountTable, {}, [
            '_id',
            'userId',
            'createdAt',
            'balance',
            'accountType',
            'currency',
            'lockedBalance',
            'expectedBalance',
            'user',
            'market',
            'systemAccount',
            'nickname',
            'accountingNumber',
            'minimumBalance',
            'accountingParentNumber',
        ])
    },
    controlPanel_getAllSystemAccounts: async (user,) => {
        ensure(user.systemUser)
        return squery(AccountTable, { systemAccount: true }, [
            '_id',
            'createdAt',
            'balance',
            'currency',
            'lockedBalance',
            'expectedBalance',
            'nickname',
            'accountingParentNumber',
            'accountingNumber',
            'minimumBalance',
        ])
    },
    controlPanel_getAllGroupNotifications: async (user,) => {
        ensure(user.systemUser)
        return squery(GroupNotificationTable, {}, [
            '_id',
            'title',
            'body',
            'icon',
            'iconColor',
            'iconFamily',
            'groups',
            'image',
            'link',
            'active',
            'pushNotification',
            'pushNotificationChannelId',
            'notifiedAt',
            'emailBody',
            'emailNotification',
            'emailSubject',
            'smsBody',
            'smsNotification',
            'inAppNotification',
        ])
    },
    controlPanel_getAllGroups: async (user,) => {
        ensure(user.systemUser)
        return squery(GroupTable, {}, ['_id', 'name', 'features'])
    },
    controlPanel_getAllCities: async (user,) => {
        ensure(user.systemUser)
        return squery(CityTable, {}, ['_id', 'name', 'listed'])
    },
    controlPanel_getAllBusinessActivityTypes: async (user,) => {
        ensure(user.systemUser)
        return squery(BusinessActivityTypeTable, {}, ['_id', 'name', 'assignToGroup', 'listed'])
    },
    controlPanel_getAllSubBusinessActivityTypes: async (user,) => {
        ensure(user.systemUser)
        return squery(SubBusinessActivityTypeTable, {}, ['_id', 'name', 'assignToGroup', 'listed', 'parentActivityTypeId'])
    },
    controlPanel_getAllCashMachines: async (user,) => {
        ensure(user.systemUser)
        return squery(CashMachineTable, {}, ['_id', 'systemName', 'active', 'accessibleBy', 'createdAt', 'accounts', 'type', 'modelNumber'])
    },
    controlPanel_getAccessibleCashMachines: async (user,) => {
        ensure(user.systemUser)
        return squery(CashMachineTable, { accessibleBy: { $in: [user._id] } }, ['_id', 'systemName', 'active', 'accessibleBy', 'createdAt', 'accounts'])
    },
    controlPanel_getAllFees: async (user,) => {
        ensure(user.systemUser)
        return squery(FeesAndLimitsTable, {}, [
            '_id',
            'senderGroup',
            'receiverGroup',
            'side',
            'operation',
            'currency',
            'feePercentage',
            'minimumFee',
            'maximumFee',
            'minLimitPerOperation',
            'maxLimitPerOperation',
            'maxLimitPerDay',
            'cashbackPercentage',
            'maxDailyCashback',
            'feeAccount',
            'cashbackAccount',
        ])
    },
})

export default clientLiveQueries
