import { FieldValidation } from '@hypatia/utils/validations/types'
import v8n from '@hypatia/utils/validations/v8n'
import every from 'lodash/every'
import forEach from 'lodash/forEach'
import mapValues from 'lodash/mapValues'
import some from 'lodash/some'
import uniq from 'lodash/uniq'
import {
    EditorBlurredFields,
    EditorErrors,
    EditorErrorsToDisplay,
    EditorHasErrors,
    EditorOptions,
    EditorProps,
    FieldBlurHandlers,
    FieldSetters,
    FieldSettersFromEvent,
} from './types'
export default class Editor<T extends object = any> {
    public triedSubmit = false
    public triedSubmitFields: (keyof T)[] = []

    public initialValues: T = {} as T
    public values: T = {} as T
    public dirty: EditorBlurredFields<T> = {}
    public isDirty = false
    public hasErrors: EditorHasErrors<T> = {}
    public errors: EditorErrors<T> = {}
    public errorsToDisplay: EditorErrorsToDisplay<T> = {}

    protected blurred: Partial<Record<keyof T, boolean>> = {}

    constructor(protected options: EditorOptions<T>) {
        if (options.initialValues) {
            this.initialValues = options.initialValues
            this.values = this.initialValues as any
        }
    }

    protected subscribers = new Set<() => void>()
    subscribe = (onChange: () => void): (() => void) => {
        this.subscribers.add(onChange)
        return () => this.subscribers.delete(onChange)
    }

    unsubscribe = (onChange: () => void): void => {
        this.subscribers.delete(onChange)
    }

    _onChange(): void {
        this.subscribers.forEach((subscriber) => subscriber())
    }

    hasChanged = true
    lastEditorProps?: EditorProps<T>

    getImmutableEditorProps(): EditorProps<T> {
        if (!this.lastEditorProps || this.hasChanged) {
            this.hasChanged = false
            this.lastEditorProps = {
                triedSubmit: this.triedSubmit,
                handleChange: this.handleChange,
                handleChangeEvent: this.handleChangeEvent,
                dirty: this.dirty,
                isDirty: this.isDirty,
                setValue: this.setValue,
                values: this.values,
                triedSubmitFields: this.triedSubmitFields,
                submitField: this.markFieldSubmit,
                hasErrors: this.hasErrors,
                markSubmit: this.markSubmit,
                markUnSubmit: this.markUnSubmit,
                isFormValid: this.isFormValid,
                disableSubmit: this.disableSubmit,
                errorsToDisplay: this.errorsToDisplay,
                initialValues: this.initialValues,
                discard: this.discard,
                errors: this.errors,
                handleBlur: this.handleBlur,
                setBlurred: this.setBlurred,
                addValidation: this.addValidation,
                removeValidation: this.removeValidation,
                removeAllValidations: this.removeAllValidations,
                markSaved: this.markSaved,
            }
        }
        return this.lastEditorProps
    }

    protected onChange(): void {
        this.updateErrors()
        this.hasChanged = true
        this._onChange()
    }

    public setValue = <K extends keyof T>(key: K, value: T[K], skipOnChange = false): void => {
        const processedValue = this.options?.processors?.[key]?.(value, this.values[key], this.values) ?? value

        if (this.values[key] === processedValue) {
            return
        }

        this.values = { ...this.values, [key]: processedValue }
        this.dirty = { ...this.dirty, [key]: this.initialValues[key] !== processedValue }
        this.isDirty = some(this.dirty, (v) => !!v)

        if (skipOnChange) {
            this.updateErrors()
        } else {
            this.onChange()
        }
    }

    protected updateError = (key: keyof T): void => {
        const valid = v8n.validateField(this.values, key, this.options.validations?.[key] as FieldValidation)
        const err = valid === true ? undefined : valid

        this.overrideError(key, err)
    }

    protected overrideError(key: keyof T, err: any): void {
        this.hasErrors = { ...this.hasErrors, [key]: !!err }
        this.errors = { ...this.errors, [key]: err }

        if (this.triedSubmitFields.includes(key) || this.triedSubmit || this.blurred[key]) {
            this.errorsToDisplay = { ...this.errorsToDisplay, [key]: err }
        }
    }

    public addValidation = <K extends keyof T>(key: K, validation: FieldValidation<T[K], T>): void => {
        if (!this.options.validations) {
            this.options.validations = {}
        }
        const oldValidation = this.options.validations[key]
        const oldValidationAry: any[] = oldValidation ? [oldValidation] : []
        ;(this.options.validations as any)[key] = v8n.all([validation, ...oldValidationAry])
        this.onChange()
    }
    public removeValidation = <K extends keyof T>(key: K, validation: FieldValidation<T[K], T>): void => {
        if (!this.options.validations) {
            return
        }
        const oldValidation = this.options.validations[key]
        const oldValidationAry: any[] = oldValidation ? [oldValidation] : []
        ;(this.options.validations as any)[key] = v8n.all(oldValidationAry.filter((v) => v !== validation))
        this.onChange()
    }
    public removeAllValidations = (): void => {
        if (!this.options.validations) {
            return
        }
        ;(this.options.validations as any) = {}
        this.onChange()
    }

    protected isValid = (key: keyof T): boolean =>
        v8n.validateField(this.values, key, this.options.validations?.[key] as FieldValidation) === true

    protected updateErrors = (): void => {
        forEach(this.initialValues, (_, field) => this.updateError(field as keyof T))
    }
    public isFormValid = (): boolean => every(this.initialValues, (_, field) => this.isValid(field as keyof T))

    public disableSubmit = (): boolean => this.triedSubmit && !this.isFormValid()

    public markSubmit = (): void => {
        this.triedSubmit = true
        this.triedSubmitFields = Object.keys(this.values) as (keyof T)[]
        this.onChange()
    }

    public markUnSubmit = (): void => {
        this.triedSubmit = false
        this.triedSubmitFields = []
        this.onChange()
    }

    public markFieldSubmit = (fields: (keyof T)[]): void => {
        this.triedSubmitFields = uniq([...this.triedSubmitFields, ...fields])

        if (this.triedSubmitFields.length === Object.keys(this.values).length) {
            this.triedSubmit = true
        }
        this.onChange()
    }

    protected fieldSetters: FieldSetters<T> = {}
    public handleChange = <K extends keyof T>(field: K): NonNullable<FieldSetters<T>[K]> => {
        if (!this.fieldSetters[field]) {
            this.fieldSetters[field] = (newValue: T[K]) => {
                this.setValue(field, newValue)
            }
        }

        return this.fieldSetters[field]!
    }

    protected fieldEventSetters: FieldSettersFromEvent<T> = {}
    public handleChangeEvent = <K extends keyof T>(field: K): NonNullable<FieldSettersFromEvent<T>[K]> => {
        if (!this.fieldEventSetters[field]) {
            this.fieldEventSetters[field] = (e: React.ChangeEvent) => {
                this.setValue(field, (e.target as any).value)
            }
        }

        return this.fieldEventSetters[field]!
    }

    protected fieldBlur: FieldBlurHandlers<T> = {}
    public handleBlur = (field: keyof T): NonNullable<FieldBlurHandlers<T>[keyof T]> => {
        if (!this.fieldBlur[field]) {
            this.fieldBlur[field] = () => {
                this.setBlurred(field)
            }
        }

        return this.fieldBlur[field]!
    }

    public setBlurred(field: keyof T): void {
        this.blurred[field] = true
        this.onChange()
    }

    public setInitialValues = (values: Partial<T>): void => {
        this.initialValues = { ...(this.initialValues ?? {}), ...values }
        this.values = { ...this.initialValues, ...mapValues(this.dirty, (_, key: keyof T) => this.values[key]) }

        this.onChange()
    }

    public discard = (): void => {
        this.values = this.initialValues as any
        this.dirty = {}
        this.isDirty = false
        this.errors = {}
        this.hasErrors = {}
        this.triedSubmitFields = []
        this.errorsToDisplay = {}
        this.triedSubmit = false
        this.onChange()
    }

    public markSaved = (): void => {
        this.initialValues = this.values as any
        this.dirty = {}
        this.isDirty = false
        this.errors = {}
        this.hasErrors = {}
        this.triedSubmitFields = []
        this.errorsToDisplay = {}
        this.onChange()
    }
}
