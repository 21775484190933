import SettableValue from '@hypatia/utils/SettableValue'
import { Stream } from '@hypatia/utils/streams/stream'

export interface RpcArg<M extends string = string, Args = any> {
    method: M
    args: Args
    streamId?: string
}

export interface ServererFrontend {
    callRPC(req: RpcArg): Promise<any>
    callRpcAndReturnStream(req: () => RpcArg): Stream<any>
    callOnReconnection(fn: () => void): () => void
}

export const servererFrontend = new SettableValue<ServererFrontend>('servererFrontend')
