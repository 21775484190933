// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vtRvEb72fG4ckn24vchw {\n    direction: ltr;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n}\n\n.mHRcng4aYHeE8lGPJEkV {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n}\n\n.ElM6_uhvqAyq7kigDo29 {\n    height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Markets/MarketsPage.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".container {\n    direction: ltr;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n}\n\n.table {\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n}\n\n.cell-image {\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vtRvEb72fG4ckn24vchw",
	"table": "mHRcng4aYHeE8lGPJEkV",
	"cell-image": "ElM6_uhvqAyq7kigDo29"
};
export default ___CSS_LOADER_EXPORT___;
