import queryString from 'query-string'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Animate from '../components/Animate'
import useAsync from '../hooks/async/useAsync'
import FullSuspense from '../hooks/FullSuspense'
import { webRoutesGetter } from './getPathForRoute'
import { useLocation } from './LocationContext'
import matchPath from './matchPath'
import { RouteAuthRuleContext, RouteObj } from './RouteObj'
import useAuthMode from './useAuthMode'

interface Props {
    fallback: JSX.Element
    routeContext: RouteAuthRuleContext
}

interface TreeItem {
    key: string
    routeKey: string
    route: RouteObj
    params: Record<string, any>
}

export default function Router({ fallback, routeContext }: Props): JSX.Element | null {
    const routes = webRoutesGetter.get()()
    const authMode = useAuthMode()

    const { search, pathname } = useLocation()

    const tree: TreeItem[] = []
    const renderRoute = (route: RouteObj, key: string): JSX.Element | null => {
        if (route.auth && route.auth !== authMode) {
            return null
        }
        if (route.authRule && !route.authRule(routeContext)) {
            return null
        }

        const { children, component: Component, extraProps, passKeyFromParam, exact, path } = route

        const match = matchPath(pathname, { exact, path })
        if (!match) {
            return null
        }

        const allParams: Record<string, string | (null | string)[] | null> = {
            ...queryString.parse(search),
            ...match.params,
        }

        const keyToUse = allParams[passKeyFromParam ?? ''] + key

        tree.push({
            key: keyToUse,
            routeKey: key,
            route: route,
            params: allParams,
        })

        let child
        for (const [childKey, childRoute] of Object.entries(children ?? {})) {
            child = renderRoute(childRoute, childKey)
            if (child) {
                break
            }
        }

        return (
            <Animate changeKey={keyToUse} animation={route.animation}>
                <div style={{ flex: 1, maxWidth: '100%', height: '100%' }}>
                    <FullSuspense
                        fragmentStyle={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                        fallback={fallback}
                    >
                        <Component {...allParams} {...extraProps}>
                            {child}
                        </Component>
                    </FullSuspense>
                </div>
            </Animate>
        )
    }

    let res: JSX.Element | null = null

    for (const [key, route] of Object.entries(routes)) {
        res = renderRoute(route, key)
        if (res) {
            break
        }
    }

    useTitle(tree)

    return res
}

function useTitle(tree: TreeItem[]): void {
    const { t } = useTranslation()
    const reversedTree = tree.reverse()
    const withTitleIdx = reversedTree.findIndex((x) => x.route.title)
    const key =
        withTitleIdx === -1
            ? ''
            : reversedTree
                  .slice(withTitleIdx)
                  .map((x) => x.key)
                  .join(',')

    const withTitle = withTitleIdx === -1 ? null : reversedTree[withTitleIdx]

    const [title] = useAsync(() => (withTitle ? withTitle.route.title!(withTitle.params) : undefined), [key, t])
    useEffect(() => {
        if (title) {
            document.title = title
        }
    }, [title])
}
