const locals = {
    server: {
        messages: {
            yourTelegramVerificationCodeIs: `رمز التحقق الخاص بك هو\n<b>{{code}}</b>\n<a href="https://cashii.app/verify?code={{code}}">أو اضغط هنا للتأكيد</a>`,
            yourSmsVerificationCodeIs: `رمز التحقق الخاص بك بتطبيق كاشي هو: {{code}}\n\n{{hash}}`,
        },
        emailVerificationCode: {
            subject: 'رمز التحقق من البريد الإلكتروني',
            bodyHtml: `رمز التحقق الخاص بك هو: {{code}}`,

        },
        transactionEmails: {
            template:
                `<div dir="ltr" style="background-color: #f6f6f7; padding-top: 48px; padding-bottom: 48px">
    <div style="width: 100%; direction: rtl; font-size: 14px; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color emoji'; max-width: 600px; margin: auto; border: 1px solid rgba(25, 29, 45, 0.12); border-radius: 12px; background: #fff;">
      <div>
        <img src="https://cashii.app/logo.png" style="max-width: 20em; margin: auto; display: inherit"/>
      </div>
      <p style="background-color: rgb(238, 247, 252); line-height: 1.6; padding: 2em;">
        السيد <b>{{fullName}}</b>
        <br />
        <br />
        {{- body}}
      </p>
      <p style="line-height: 1.6; padding: 2em">
        أطيب التحيات
        <br />
        فريق كاشي
      </p>
    </div>
  </div>
  `,
            incoming:
                `لقد تلقيت اموالاً لحسابك رقم <b>{{accountNumber}}</b> في تطبيق كاشي
<br />
نوع العملية: <b>{{type}}</b>
<br />
تاريخ العملية: <b>{{date}}</b>
<br />
المبلغ: <b>{{amount}}</b>
<br />
الرسوم: <b>{{fees}}</b>
<br />
الباقي: <b>{{amountAfterFees}}</b>
<br />
المرسل: <b>{{sender}}</b>
<br />
رقم حساب المرسل: <b>{{senderAccountNumber}}</b>
<br />
الرقم المرجعي: <b>{{transactionNumber}}</b>
<br />
الملاحظات: <b>{{notes}}</b>
<br />
الرصيد التراكمي: <b>{{balance}}</b>
<br />`
            , outgoing:
                `لقد أرسلت اموالاً من حسابك رقم <b>{{accountNumber}}</b> في تطبيق كاشي
<br />
نوع العملية: <b>{{type}}</b>
<br />
تاريخ العملية: <b>{{date}}</b>
<br />
المبلغ: <b>{{amount}}</b>
<br />
الرسوم: <b>{{fees}}</b>
<br />
المجموع: <b>{{amountAfterFees}}</b>
<br />
المستلم: <b>{{receiver}}</b>
<br />
رقم حساب المستلم: <b>{{receiverAccountNumber}}</b>
<br />
الرقم المرجعي: <b>{{transactionNumber}}</b>
<br />
الملاحظات: <b>{{notes}}</b>
<br />
الرصيد التراكمي: <b>{{balance}}</b>
<br />`,
            outgoingSubject: 'تم إرسال مبلغ من حسابك في تطبيق كاشي',
            incomingSubject: 'تم تلقي مبلغ في حسابك في تطبيق كاشي',

        }
    },
    common: {
        loading: 'جاري التحميل',
        cashii: 'كاشي',
        continue: 'متابعة',
        whatsapp: 'واتساب',
        telegram: 'تليجرام',
        sms: 'رسالة نصية',
        change: 'تغيير',
        phone: 'رقم الهاتف',
        birthDate: 'تاريخ الميلاد',
        cancel: 'إلغاء',
        date: 'التاريخ',
        accountNumber: 'رقم الحساب',
        somethingWentWrong: 'حدث خطأ ما',
        somethingWentWrongDetails: 'نعتذر عن هذا الخطأ، سيقوم فريقنا بتحليل الخطأ وإصلاحه في أقرب وقت ممكن',
        start: 'بدء',
        all: 'الكل',
        USD: 'دولار أمريكي',
        TRY: 'ليرة تركية',

        city: 'المدينة',

        USDSymbol: '$',
        TRYSymbol: '₺',

        soon: 'قريباً',

        confirm: 'تأكيد',
        save: 'حفظ',

        youLostInternetConnection: 'يبدو أنك فقدت الاتصال بالانترنت',
        noInternetConnection: 'لا يوجد اتصال بالانترنت',
        pleaseCheckYourInternetConnection: 'من فضلك تأكد من اتصالك بالانترنت للمتابعة',

        undefined: 'غير محدد',

        savedSuccessfully: 'تم الحفظ بنجاح',
        removedSuccessfully: 'تم الإزالة بنجاح',
        remove: 'إزالة',

        fromCamera: 'من الكاميرا',
        fromGallery: 'من المعرض',
        fromFiles: 'اختر ملف',
        fromDocument: 'من المستندات',
        pleaseSelectImageSource: 'الرجاء اختيار مصدر الصورة',

        cameraPermissionsDenied: 'تعذر الوصول إلى الكاميرا',
        cameraPermissionsDeniedMessage: 'يرجى السماح بالوصول إلى الكاميرا لتحديث الصورة',
        galleryPermissionsDenied: 'تعذر الوصول إلى المعرض',
        galleryPermissionsDeniedMessage: 'يرجى السماح بالوصول إلى المعرض لتحديث الصورة',
        exchangeRate: 'سعر الصرف',

        male: 'ذكر',
        female: 'أنثى',

        lockedBalance: 'رصيد مجمد',
        lockedBalanceExplanation: 'الرصيد المجمد هو رصيد مجمد مؤقتا لعمليات قيد التنفيذ',
        search: 'بحث',
    },
    welcome: {
        title: 'مرحباً بك في كاشي',
        subTitle: 'كاشي تطبيق محفظة الكترونية يتيح للمستخدمين التحكم في الأموال وإجراء عمليات مالية من التطبيق مباشرة عبر الانترنت مثل الإيداعات والسحب والتحويلات وتسديد الفواتير والشراء عبر الإنترنت إضافة للمتاجر والمحلات من خلال الرصيد المتوفر في المحفظة.',
        signup: 'إنشاء حساب',
        login: 'تسجيل الدخول'
    },
    signup: {
        mainInfoTitle: 'إنشاء حساب جديد',
        mainInfoSubTitle: 'الرجاء إدخال بياناتك',

        selectPhoneConfirmationMethodTitle: 'تأكيد رقم الهاتف',
        selectPhoneConfirmationMethodSubTitle: 'يرجى اختيار طريقة تأكيد رقم الهاتف',

        phoneConfirmationCodeTitle: 'تأكيد رقم الهاتف',
        phoneConfirmationCodeSubTitle: 'يرجى إدخال رمز التأكيد الذي تم إرساله إلى رقم الهاتف',

        pinSetupTitle: 'تعريف رمز الدخول',
        pinSetupSubTitle: 'يرجى إدخال رقم من ٦ خانات لتعريف رمز الدخول الخاص بك',

        pinSetupConfirmationTitle: 'تأكيد رمز الدخول',
        pinSetupConfirmationSubTitle: 'يرجى تأكيد رمز الدخول الخاص بك',

        telegramInstructionsTitle: 'الانضمام إلى بوت التليجرام',
        telegramInstructionsSubTitle: 'يرجى الانضمام إلى بوت التليجرام للحصول على رمز التحقق الخاص بك',

        successTitle: 'تم إنشاء حسابك بنجاح',
        successSubTitle: 'يمكنك الآن المتابعة للصفحة الرئيسية',

        nationalNumber: 'الرقم الشخصي (حسب هوية المجلس المحلي)',
        fullName: 'الاسم الكامل (بالعربية)',
        fullNameInEnglish: 'الاسم الكامل (بالانجليزية)',

        whatsappMessageSent: 'تم إرسال رسالة واتساب',
        telegramMessageSent: 'تم إرسال رسالة تليجرام',
        smsMessageSent: 'تم إرسال رسالة نصية',

        verificationCode: 'رمز التأكيد',
        resendCode: 'إعادة إرسال الرمز',

        errorSendingTheCode: 'حدث خطأ أثناء إرسال الرمز',
        pinCodeMismatch: 'رمز الدخول غير متطابق',
        pinCodeNotAllowed: 'هذا الرمز غير مسموح به',
        invalidPhoneVerificationCode: 'رمز التحقق غير صحيح',

        openTelegram: 'فتح تليجرام',
        phoneAlreadyExists: 'رقم الهاتف موجود بالفعل, الرجاء تسجيل الدخول',
        nationalNumberAlreadyExists: 'الرقم الشخصي موجود بالفعل, الرجاء تسجيل الدخول',

        youMustAcceptTerms: 'يجب الموافقة على الشروط والأحكام',
        acceptTerms: 'أوافق على',
        termsAndConditions: 'الشروط والأحكام',

        sex: 'الجنس',


    },
    resetPin: {
        forgotPinTitle: 'نسيت رمز الدخول',

        selectPhoneConfirmationMethodTitle: 'تأكيد رقم الهاتف',
        selectPhoneConfirmationMethodSubTitle: 'يرجى اختيار طريقة تأكيد رقم الهاتف',

        phoneConfirmationCodeTitle: 'تأكيد رقم الهاتف',
        phoneConfirmationCodeSubTitle: 'يرجى إدخال رمز التأكيد الذي تم إرساله إلى رقم الهاتف',

        pinSetupTitle: 'تعريف رمز الدخول',
        pinSetupSubTitle: 'يرجى إدخال رقم من ٦ خانات لتعريف رمز الدخول الخاص بك',

        pinSetupConfirmationTitle: 'تأكيد رمز الدخول',
        pinSetupConfirmationSubTitle: 'يرجى تأكيد رمز الدخول الخاص بك',

        telegramInstructionsTitle: 'الانضمام إلى بوت التليجرام',
        telegramInstructionsSubTitle: 'يرجى الانضمام إلى بوت التليجرام للحصول على رمز التحقق الخاص بك',

        uploadIdTitle: 'تحميل صورة الهوية',
        uploadIdSubTitle: 'يرجى تحميل صورة الهوية الخاصة بك',

        idVerificationDescription:
            'لاستعادة رمز الدخول, يجب عليك تأكيد هويتك, للمتابعة يرجى احضار هوية المجلس المحلي والضغط على زر المتابعة لالتقاط صورة الهوية',
        successTitle: 'تم إرسال الطلب بنجاح',
        successSubTitle: 'سيقوم فريق الدعم بالتأكد من معلوماتك والرد عليك في أقرب وقت ممكن',
    },
    login: {
        qrLoginRequestTitle: 'تسجيل الدخول باستخدام QR',
        qrLoginRequestSubTitle: 'يرجى مسح ال QR لتسجيل الدخول',

        enterPhoneNumberTitle: 'تسجيل الدخول',
        enterPhoneNumberSubTitle: 'الرجاء إدخال رقم الهاتف الخاص بك',

        enterPinCodeTitle: 'رمز الدخول',
        enterPinCodeSubTitle: 'الرجاء إدخال رمز الدخول الخاص بك',

        selectPhoneConfirmationMethodTitle: 'تأكيد رقم الهاتف',
        selectPhoneConfirmationMethodSubTitle: 'يرجى اختيار طريقة تأكيد رقم الهاتف',

        verifyPhoneNumberTitle: 'تأكيد رقم الهاتف',
        verifyPhoneNumberSubTitle: 'يرجى إدخال رمز التأكيد الذي تم إرساله إلى رقم الهاتف',

        telegramInstructionsTitle: 'الانضمام إلى بوت التليجرام',
        telegramInstructionsSubTitle: 'يرجى الانضمام إلى بوت التليجرام للحصول على رمز التحقق الخاص بك',

        invalidPinCode: 'رمز الدخول غير صحيح',
        userNotFound: 'رقم الهاتف غير مسجل',
        phoneNotFound: 'رقم الهاتف غير مسجل',
        userDisabled: 'هذا الحساب معطل, الرجاء التواصل مع الدعم للمزيد من المعلومات',
    },
    scan: {
        scanQrCode: 'مسح رمز الاستجابة السريعة'
    },
    validationErrors: {
        thisFieldIsRequired: 'هذا الحقل مطلوب',
        maxLength: 'الحد الأقصى لعدد الحروف هو {{length}}',
        minLength: 'الحد الأدنى لعدد الحروف هو {{length}}',
        onlyArabicLetters: 'يرجى استخدام أحروف عربية فقط',
        onlyEnglishLetters: 'يرجى استخدام أحروف انجليزية فقط',
        phone: 'يرجى إدخال رقم هاتف صحيح',
        atLeastXYearsOld: 'يجب أن يكون عمرك على الأقل {{x}} سنة',
        youMustEnterYourFullName: 'يجب عليك إدخال اسمك الكامل',
        onlyDigits: 'يرجى إدخال أرقام فقط'
    },
    menu: {
        back: 'رجوع',
        home: 'الرئيسية',
        deposit: 'إيداع',
        performDeposit: 'استلام اموال',
        withdraw: 'سحب',
        performWithdraw: 'تسليم اموال',
        transfer: 'تحويل',
        payments: 'تسديد',
        buy: 'شراء',
        sell: 'بيع',
        settings: 'الإعدادات',
        help: 'المساعدة',
        allTransactions: 'الحركات المالية',
        markets: 'وين فيك تدفع عالماشي',
        depositPoints: 'اودع واسحب عالماشي',
        remotePayments: 'دفعات عن بعد',
        newRemotePaymentRequest: 'طلب دفعة عن بعد',
        fees: 'الرسوم',
        changeEmail: 'تعديل البريد الإلكتروني',

        offers: 'العروض',

        marketUsersManagement: 'إدارة المستخدمين',
        marketDevicesManagement: 'إدارة الأجهزة',

        lock: 'قفل',

        requestMarketAccount: 'طلب حساب تجاري',

        contactUs: 'تواصل معنا',

        changePin: 'تغيير رمز الدخول',
        transactionDetails: 'تفاصيل العملية',
        referral: 'دعوة أصدقاء',
        referredFriends: 'الأصدقاء المدعوين',

        redeemGiftCardShort: 'بطاقة',
        redeemGiftCard: 'صرف بطاقة',

        notifications: 'الإشعارات',

        exchange: 'سوق العملات',
        terms: 'الشروط والأحكام',
    },
    home: {
        yourCurrentBalance: 'رصيدك الحالي ',
        accountNumber: 'رقم الحساب {{accountNumber}}',
        areYouSureYouWantToExit: 'هل أنت متأكد من أنك تريد الخروج؟',
        exit: 'خروج',

    },
    transactions: {
        fees: 'الرسوم',
        negativeFees: "الاجور",
        cashback: 'كاش باك',
        amount: 'المبلغ',
        transactionCanceled: 'تم إلغاء العملية',
        transactionCanceledDetail: 'تم إلغاء العملية دون إتمامها',
        transactionComplete: 'تم الإرسال بنجاح',
        transactionCompleteDetail: 'تمت عملية تحويل المبلغ بنجاح',
        transactionFailed: 'لم تتم عملية التحويل',
        transactionFailedDetail: 'حدث خطأ ما خلال عملية التحويل',
        latestTransactions: 'آخر الحركات',
        showAllTransactions: 'عرض جميع الحركات',
        willBeChargedFromBuyer: 'سيتم اقتطاعها من المشتري',
        willBeDepositedToUser: 'سيتم إيداعها في حساب المستلم',
        willBeGivenCashToUser: 'سيتم تسليمها نقداً للمستلم',
        waitingForScanner: 'بإنتظار عملية المسح',
        sendMoney: 'إرسال أموال',
        unregisteredAccount: 'حساب غير مسجل',
        receiveMoney: 'إستلام أموال',
        sendTo: 'إرسال الى',
        noTransactions: 'الحركات المالية لهذا الحساب ستظهر هنا',
        transactionNo: 'رقم العملية',
        note: 'ملاحظة',
        giftCardSecret: 'رمز البطاقة',
        placedByUser: 'تم من قبل',
        account: 'الحساب',
        seller: 'البائع',
        buyer: 'المشتري',
        exportReceipt: 'مشاركة الإيصال',
        sender: 'المرسل',
        giver: 'المسلم',
        receiver: 'المستلم',

        total: 'المجموع',
        totalSubtraction: 'المتبقي',
        refundFor: 'إسترداد لـ',
        refundedBy: 'تم الإسترداد بـ',

        receivedCashAmount: 'المبلغ المستلم نقداً',
        paidCashAmount: 'المبلغ المسلم نقداً',
        noNotes: 'لا يوجد ملاحظات',

        types: {
            all: 'الكل',
            deposit: 'إيداع',
            performDeposit: 'استلام اموال',
            performWithdraw: 'تسليم اموال',
            gift: 'هدية',
            depositGift: 'هدية إيداع',
            withdraw: 'سحب',
            transfer: 'حوالة',
            incomingTransfer: 'تحويل وارد',
            incomingTransfers: 'تحويلات واردة',
            outgoingTransfer: 'تحويل صادر',
            outgoingTransfers: 'تحويلات صادرة',
            transferFrom: 'تحويل من {{fullName}}',
            transferTo: 'تحويل إلى {{fullName}}',
            buyFromMarket: 'شراء',
            buyPackage: 'شراء باقة',
            refundPackage: 'استرداد باقة',
            sell: 'بيع',
            sellTo: 'بيع إلى {{fullName}}',
            buy: 'شراء',
            buyFrom: 'شراء من {{fullName}}',
            cashback: 'كاش باك',
            initialCredit: 'هدية ترحيبية',
            referralCredit: 'هدية دعوة أصدقاء',
            redeemGiftCard: 'صرف بطاقة هدية',
            exchange: 'صرف عملة',
            receivingRemotePayment: 'استلام دفعة عن بعد',
            remotePayment: 'دفع عن بعد',
        }
    },
    formats: {
        excelFullDate: 'DD/MM/YYYY',
        fullDate: '{{date, DD/MM/YYYY}}',
        fullDateFullTime: '{{date, DD/MM/YYYY HH:mm A}}',
        fullDateTime: '{{date, DD/MM/YYYY HH:mm}}',
        halfFullDateTime: '{{date, DD/MM/YY HH:mm}}',
        niceShortDate: '{{date, ddd, MMM D}}',
        shortDateTime: '{{date, DD/MM HH:mm}}',
        textDate: '{{date, MMM DD, YYYY}}',
        textDateTime: '{{date, DD MMM HH:mm A}}',
        time: '{{date, HH:mm}}',
        time12hours: '{{date, HH:mm A}}',
        timeWithSeconds: '{{date, HH:mm:ss A}}'
    },
    payments: {
        mobilePackages: 'باقات الجوال',
        storeCards: 'بطاقات المتاجر',
        mobilePackagesOnlyAvailableForTurkey: 'باقات الجوال متاحة فقط للارقام التركية',
        mobilePackagesOnlyAvailableForPersonalAccount: 'باقات الجوال متاحة فقط للحسابات الشخصية',
        invoices: 'فواتير'
    },
    accounts: {
        personal: 'شخصي'
    },
    transfer: {
        transferToUnRegisteredNumber: 'رقم غير مسجل',
        contactsPermissionMessage: 'كاشي يحتاج الوصول إلى جهات الاتصال لتفعيل ميزة التحويل عن طريق رقم الهاتف',
        contactsPermissionButton: 'السماح بالوصول إلى جهات الاتصال',
        unRegisteredNumberNotFound: 'لا يوجد حساب مسجل بهذا الرقم',
        notEnoughBalance: 'رصيدك غير كافٍ',
        savedContacts: 'جهات الاتصال المحفوظة',
        note: 'ملاحظة (اختياري)',
        // used in transfer and users management
        youCannotTransferToYourself: 'لا يمكنك اختيار نفسك',
        betweenMyAccounts: 'بين حساباتي',
        toExternalAccount: 'لحساب خارجي',
        from: 'من',
        to: 'إلى',
        cannotTransferToSameAccount: 'لا يمكنك التحويل إلى نفس الحساب',
        phoneNumber: 'رقم الهاتف',
        accountNumber: 'رقم الحساب',
        minimumAmountError: 'الحد الأدنى للتحويل هو {{amount}}'
    },
    settings: {
        account: 'الحساب',

        fullName: 'الاسم الكامل (بالعربية)',
        fullNameInEnglish: 'الاسم الكامل (بالانجليزية)',
        birthDate: 'تاريخ الميلاد',
        email: 'البريد الإلكتروني',
        mobileNumber: 'رقم الجوال',
        pinCode: 'رمز الدخول',
        city: 'المدينة',
        nationalNumber: 'الرقم الشخصي',

        logout: 'تسجيل الخروج',
        logoutMessage: 'هل أنت متأكد من تسجيل الخروج؟',

        requestMarketAccount: 'طلب حساب تجاري',

        changeProfilePicture: 'تغيير الصورة الشخصية',
        changeLogo: 'تغيير صورة الشعار',

        profilePictureUpdated: 'تم تحديث الصورة الشخصية بنجاح',
        logoUpdated: 'تم تحديث صورة الشعار بنجاح',

        version: 'الإصدار',

        removeEmail: 'إزالة البريد الإلكتروني',
        areYouSureYouWantToRemoveYourEmail: 'هل أنت متأكد من إزالة البريد الإلكتروني؟',
        confirmationCodeWasSentToYourEmail: 'يرجى إدخال رمز التحقق الذي ارسل إلى بريدك الإلكتروني',
        confirmationCode: 'رمز التحقق',
        sendConfirmationCode: 'إرسال رمز التحقق',
        confirmationCodeNotCorrect: 'رمز التحقق غير صحيح',
        invalidEmail: 'البريد الإلكتروني غير صحيح',
        emailAlreadyExists: 'البريد الإلكتروني مسجل مسبقاً لحساب آخر',
        emailAlreadySaved: 'البريد الإلكتروني مسجل مسبقاً لحسابك',
        emailUpdated: 'تم تحديث البريد الإلكتروني بنجاح',
        emailRemoved: 'تم إزالة البريد الإلكتروني بنجاح',
        notificationsSettings: 'إعدادات الإشعارات',
        notificationsSettingsList: {
            transactions: 'الحركات المالية',
        }
    },
    changePin: {
        invalidCurrentPinCode: 'رمز الدخول الحالي غير صحيح',
        invalidCurrentPinCodeMessage: 'الرجاء التأكد من رمز الدخول الحالي',
        pinCodeMismatch: 'رمز الدخول غير متطابق',
        pinCodeMismatchMessage: 'الرجاء إدخال رمز الدخول مرتين بشكل متطابق',
        unallowedPinCode: 'رمز الدخول غير مسموح به',
        unallowedPinCodeMessage: 'الرجاء إدخال رمز الدخول مكون من 6 أرقام غير متطابقة او متتالية',
        pinCodeChanged: 'تم تغيير رمز الدخول بنجاح',

        currentPinTitle: 'رمز الدخول الحالي',
        newPinTitle: 'رمز الدخول الجديد',
        confirmNewPinTitle: 'تأكيد رمز الدخول الجديد'
    },
    help: {
        contactUs: 'تواصل معنا',
        termsOfService: 'الشروط والأحكام'
    },
    requestMarketAccount: {
        title: 'يرجى تعبئة البيانات التالية لطلب حساب تجاري',
        name: 'اسم المتجر',
        address: 'عنوان المتجر',
        businessActivityType: 'نوع النشاط التجاري',
        subBusinessActivityType: 'نوع النشاط التجاري الفرعي',
        businessType: 'نوع النشاط التجاري',
        details: 'تفاصيل النشاط التجاري',
        submit: 'إرسال',
        success: 'تم إرسال طلبك بنجاح',
        successMessage: 'سيقوم فريق العمل بالتواصل معك في أقرب وقت',
        city: 'المدينة',
        getLocation: 'الحصول على الموقع',
        uploadLogo: 'رفع صورة الشعار',
        uploadLicensePhoto: 'رفع صورة الترخيص التجاري',
        uploadFrontPhoto: 'رفع صورة واجهة المتجر',
        locationSuccess: 'تم تحديد الموقع بنجاح',
        locationPermissionDeniedMessage: 'يرجى تفعيل صلاحية الوصول للموقع من الإعدادات',
        unableToAccessLocation: 'تعذر الحصول للموقع',
        licenseSuccess: 'تم رفع صورة الترخيص التجاري بنجاح',
        uploadFrontPhotoSuccess: 'تم رفع صورة واجهة المتجر بنجاح',
        uploadLogoSuccess: 'تم رفع صورة الشعار بنجاح',
    },
    accountsSelector: {
        select: 'يرجى اختيار حساب',
        personal: 'شخصي',
        market: 'تجاري'
    },
    buyFromMarket: {
        cannotBuyFromYourself: 'لا يمكنك الشراء من نفسك',
        notEnoughBalance: 'رصيدك غير كافٍ',
        minimumAmountError: 'الحد الأدنى للشراء هو {{amount}}'
    },
    buyPackages: {
        unsupportedOperator: 'المخدم غير مدعوم بعد',
        units: {
            minute: 'دقيقة',
            migabyte: 'ميجابايت',
            gigabyte: 'جيجابايت',
            day: 'يوم',
            message: 'رسالة'
        },
        filters: {
            packet: 'باقات',
            balance: 'رصيد'
        }
    },
    identityVerification: {
        title: 'التحقق من الهوية',
        description:
            'لاستخدام كاشي, يجب عليك تأكيد هويتك, للمتابعة يرجى احضار هوية المجلس المحلي والضغط على زر المتابعة لالتقاط صورة الهوية',
        captureFrontSide: 'التقاط الجانب الأمامي',
        captureFrontSideDescription:
            'يرجى التقاط صورة الجانب الأمامي من الهوية, والتأكد أن النصوص والأرقام مرئية بوضوح',
        captureBackSide: 'التقاط الجانب الخلفي',
        captureBackSideDescription:
            'يرجى التقاط صورة الجانب الخلفي من الهوية, والتأكد أن النصوص والأرقام مرئية بوضوح'
    },
    cities: {
        undefined: 'غير محدد',
    },
    businessActivityTypes: {
        undefined: 'غير محدد',
        all: 'الكل'
    },
    referral: {
        alreadyReferredBySomeone: 'هذا الحساب مدعو من قبل شخص آخر',
        referralSuccess: 'تم تسجيل رمز الدعوة بنجاح',

        step1: 'قم بدعوة أصدقائك لتحميل التطبيق',
        step2: 'شارك رابط الدعوة مع أصدقائك واطلب منهم الضغط عليه',
        step3: 'يقوم أصدقائك بإنشاء حساب وإيداع أول مبلغ لهم',
        step4: 'تحصل على {{amount}} لكل {{count}} أصدقاء يتمون عملية الإيداع',

        copyAppLink: 'نسخ رابط التطبيق',
        copyInviteLink: 'نسخ رابط الدعوة',
        referredFriends: 'الأصدقاء المدعوون',
        referralInProgress: 'الدعوة قيد التقدم',
        youHaveXReferralInProgress: 'لديك {{count}} دعوات قيد التقدم',
        moneyEarned: 'تم الحصول على الهدية',
        cannotReferYourself: 'لا يمكنك دعوة نفسك',
        invalidReferralCode: 'رمز الدعوة غير صحيح',

        inviteFriends: 'دعوة أصدقاء',
        winAmountForEveryXFriends: 'اربح {{amount}} لكل {{count}} أصدقاء',

        signedUpAndClickedOnLink: 'قام بالتسجيل والضغط على الرابط',
        deposited: 'قام بإيداع أول مبلغ',
    },
    giftCard: {
        invalidBarcode: 'رمز كرت الهدية غير صحيح',
        invalidBarcodeMessage: 'يرجى التأكد من رمز الكرت والمحاولة مرة أخرى',
        alreadyRedeemedToThisAccount: 'تم صرف هذا الكرت بالفعل',
        alreadyRedeemedToThisAccountMessage: 'تم صرف هذا الكرت بالفعل في هذا المركز منذ اكثر من 4 ساعات',
        alreadyRedeemedToThisAccountAFewHoursAgo: 'تم صرف هذا الكرت بالفعل',
        alreadyRedeemedToThisAccountAFewHoursAgoMessage: 'تم صرف هذا الكرت بالفعل في هذا المركز منذ قليل',
        alreadyRedeemedInDifferentAccount: 'تم صرف هذا الكرت بالفعل',
        alreadyRedeemedInDifferentAccountMessage: 'تم صرف هذا الكرت بالفعل في مركز آخر',
        inactive: 'كرت الهدية غير فعال',
        inactiveMessage: 'كرت الهدية ملغى أو غير فعال',
        expired: 'كرت الهدية منتهي الصلاحية',
        expiredMessage: 'كرت الهدية منتهي الصلاحية'
    },
    withdraw: {
        waitingUserConfirmation: 'في انتظار تأكيد المستخدم',
        insufficientFunds: 'رصيد المستخدم غير كافٍ',
        waitingProviderToEnterAmount: 'في انتظار المزود لإدخال المبلغ',
        waitingProviderConfirmation: 'في انتظار المزود للتأكيد',
    },
    deposit: {
        waitingUserConfirmation: 'في انتظار تأكيد المستخدم',
        insufficientFunds: 'رصيدك غير كافٍ',
        waitingProviderToEnterAmount: 'في انتظار المزود لإدخال المبلغ',
        waitingProviderConfirmation: 'في انتظار المزود للتأكيد',
    },
    usersManagement: {
        addUser: 'إضافة مستخدم',
        noUsers: 'لا يوجد مستخدمين',
        noUsersDescription: 'يمكنك إضافة مستخدمين إلى حسابك التجاري من هنا',
    },
    devicesManagement: {
        addDevice: 'إضافة جهاز',
        noDevices: 'لا يوجد أجهزة',
        noDevicesDescription: 'يمكنك إضافة أجهزة إلى حسابك التجاري من هنا',
        qrExpired: 'انتهت صلاحية الكود',
        qrAlreadyUsed: 'تم استخدام الكود مسبقاً',
        myDevices: 'أجهزتي',
    },
    permissions: {
        title: 'الصلاحيات',
        transfer: 'تحويل',
        buy: 'شراء',
        sell: 'بيع',
        deposit: 'إيداع',
        withdraw: 'سحب',
        preformWithdraw: 'تسليم اموال',
        preformDeposit: 'استلام اموال',
        redeemGiftCard: 'صرف بطاقة',
        viewTransactions: 'استعراض الحركات',
        exchange: 'سوق العملات',
        manageRemotePayments: 'إدارة الدفعات عن بعد',
    },
    exchange: {
        simpleMode: 'النمط البسيط',
        advancedMode: 'النمط المتقدم',
        buy: 'شراء',
        sell: 'بيع',
        buyCurrency: 'شراء {{currency}}',
        sellCurrency: 'بيع {{currency}}',
        openOrders: 'الطلبات المفتوحة',
        orderHistory: 'سجل الطلبات',
        amount: 'المبلغ',
        amountInUSD: 'المبلغ (بالدولار)',
        price: 'السعر',
        priceTRYPerUSD: 'السعر (ليرة للدولار)',
        insufficientFunds: 'رصيدك غير كافٍ',
        close: 'إغلاق',
        exchange: 'سوق العملات',
        total: 'المجموع',
        side: 'الجانب',
        orderPlaced: 'تم حفظ الطلب بنجاح',
        orderClosed: 'تم إغلاق الطلب بنجاح',
        fees: 'الرسوم',
        operation: 'العملية',
        currentBalance: 'رصيدك الحالي',
        noOpenOrders: 'لا يوجد طلبات مفتوحة',
        estimatedPrice: 'السعر التقريبي',
        estimatedPriceTRYPerUSD: 'السعر التقريبي (ليرة للدولار)',
        cannotFullFillAmount: 'لا يمكن تغطية المبلغ بالكامل',
        orderExecuted: 'تم تنفيذ الطلب بنجاح',
        areYouSureToExecuteOrder: 'هل أنت متأكد من تنفيذ الطلب بسعر {{price}} ليرة للدولار؟',
    },

    fees: {
        negativeFees: '(اجور عائدة لك)',
        depositInCashiiCenter: 'إيداع في مركز كاشي',
        withdrawFromCashiiCenter: 'سحب من مركز كاشي',
        depositInAtm: 'إيداع في الصراف الآلي',
        withdrawFromAtm: 'سحب من الصراف الآلي',
        depositInMarket: 'إيداع في مركز معتمد',
        withdrawFromMarket: 'سحب من مركز معتمد',
        preformDepositInMarket: 'استلام اموال',
        preformWithdrawFromMarket: 'تسليم اموال',
        transferBetweenMyAccounts: 'تحويل بين حساباتي',
        transferToOtherAccount: 'تحويل إلى حساب خارجي',
        transferToPersonalAccount: 'تحويل إلى حساب شخصي',
        transferToBusinessAccount: 'تحويل إلى حساب تجاري',
        exchange: 'سوق العملات',
        buy: 'شراء',
        sell: 'بيع',
        buyMobilePackage: 'شراء باقة هاتف',
        redeemGiftCard: 'صرف بطاقة هدية',

        free: 'مجانا',
        percentage: 'النسبة',
        minimum: 'الحد الأدنى للرسوم',
        minimumNegativeFees: 'الحد الأدنى للاجور',
        cashback: 'كاش باك',
    },
    markets: {
        noLocation: 'لا يوجد موقع',
    },
    depositPoints: {
        atm: 'صراف آلي',
        cashiiOffice: 'مكتب كاشي',
        market: 'مركز معتمد',
    },
    notifications: {
        title: 'الإشعارات',
        noNotifications: 'لا يوجد إشعارات',
    },
    updateScreens: {
        update: 'تحديث',
        apkUpdateTitle: 'إصدار جديد متوفر',
        apkUpdateMessage: 'يرجى تحديث التطبيق للاستمرار',
        maintenanceTitle: 'التطبيق قيد الصيانة',
        appUpdateTitle: 'إصدار جديد متوفر',
        appUpdateMessage: 'يرجى تحديث التطبيق للاستمرار',
        maintenanceMessage: 'التطبيق قيد الصيانة حالياً، سنعود قريباً',
    },
    updateStatus: {
        upToDate: 'التطبيق محدث بالفعل',
        updateInstalled: 'جاري إعادة تشغيل التطبيق',
        updateIgnored: 'تم تجاهل التحديث',
        unknownError: 'حدث خطأ غير معروف',
        syncInProgress: 'عملية التحديث قيد التنفيذ',
        checkingForUpdate: 'جاري التحقق من وجود تحديث',
        downloadingPackage: 'جاري تحميل التحديث',
        installingUpdate: 'جاري تثبيت التحديث',
    },
    remotePayments: {
        requestPayment: 'طلب دفعة',
        managePayments: 'إدارة الطلبات',
        payRemotePayment: 'دفع دفعة',
    }
}
export default locals
