import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Map<SV, SE = any, SC = any, DV = SV> extends Pass<SV, SE, SC, DV> {
    protected mapper: (value: SV) => DV

    constructor(mapper: (value: SV) => DV, source?: AnySourceStream<SV, SE, SC>) {
        super(source)
        this.mapper = mapper
    }

    override value(value: SV): void {
        try {
            const mapped = this.mapper(value)
            this._value(mapped)
        } catch (e) {
            this._error(e as any)
        }
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        map<T>(mapper: (value: DV) => T): Stream<DV, DE, DC, T, DE, DC>
    }
}

Stream.prototype.map = function <SV, SE, SC, DV>(this: AnySourceStream<SV, SE, SC>, mapper: (value: SV) => DV) {
    return new Map(mapper, this) // tslint:disable-line:no-invalid-this
}
