import getStoredToken from '@hypatia/serverer-client/auth/getStoredToken'
import { BaseUserSettings } from '@hypatia/serverer-common/database/BaseUserTable'
import { UserId } from '@hypatia/serverer-common/database/nominalTypes'
import { asyncStorage } from '@hypatia/utils/async-storage'
import { defaultUserSettings } from '@hypatia/utils/defaultSettings'
import getDirFromLocale from '@hypatia/utils/localization/getDirFromLocale'
import removeUndefined from '@hypatia/utils/object/removeUndefined'
import SettableValue from '@hypatia/utils/SettableValue'
import { $of } from '@hypatia/utils/streams/generators/of'
import '@hypatia/utils/streams/operators'
import { all } from '@hypatia/utils/streams/operators/all'
import { deliverLast, DeliverLast } from '@hypatia/utils/streams/operators/deliver-last'
import { Stream } from '@hypatia/utils/streams/stream'
import noop from 'lodash/noop'
import type { UserRecord } from './UserContext'

export const liveUserStreamGetter = new SettableValue<() => DeliverLast<UserRecord | undefined, any, any>>(
    'liveUserStreamGetter'
)

export const tokenStream = new DeliverLast<string | undefined>()

export const userRecordStreamSource: Stream<UserId, unknown, never, UserRecord | undefined> = tokenStream
    .distinctUntilChanged()
    .flatMap((token) => {
        if (!token) {
            return $of(undefined)
        }

        return liveUserStreamGetter.get()()
    })

export const userRecordStream: DeliverLast<UserRecord | undefined, unknown> = userRecordStreamSource.deliverLast()

const emptySettings: BaseUserSettings = {}

export const userSettings$ = userRecordStream
    .map((user) => user?.settings ?? emptySettings)
    .startWith(emptySettings)
    .deliverLast()

export const localSettings$ = deliverLast<BaseUserSettings>(defaultUserSettings.get())
export const settings$ = all(localSettings$, userSettings$)
    .map(([localSettings, userSettings]) => {
        const settings = { ...localSettings, ...userSettings }

        return {
            ...settings,
            direction: getDirFromLocale(settings.locale!),
        }
    })
    .deliverLast()

const getLoggedUser = async (): Promise<void> => {
    const token = await getStoredToken()
    if (!token) {
        tokenStream.value(undefined)
        return
    }

    if (tokenStream.last !== token) {
        tokenStream.value(token)
    }
}

getLoggedUser()
export const settingsKey = 'settings'

userSettings$.subscribe(noop)
settings$.subscribe(noop)

const loadSettings = async (): Promise<void> => {
    const doc = await asyncStorage.get(settingsKey)
    if (!doc) {
        return
    }
    if (!doc) {
        return
    }
    const savedSettings = removeUndefined(doc)
    const newSettings = { ...liveUserStreamGetter.get(), ...savedSettings }
    localSettings$.value(newSettings)
}

loadSettings()
