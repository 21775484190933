import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import { GridColDef } from '@mui/x-data-grid-premium'
import React, { useCallback, useMemo } from 'react'
import { CustomToolbar } from '../../components/CustomToolbar'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import classes from './TransactionsPage.module.css'

export default React.memo(TransactionsPage)

type Transaction = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getAllTransactions>
function TransactionsPage(): JSX.Element {
    const [transactions] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllTransactions(), [])

    const getRowId = useCallback((row: Transaction) => row._id, [])
    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            { field: 'createdAt', headerName: 'Created At' },
            { field: 'executedAt', headerName: 'Executed At' },
            { field: 'amount', headerName: 'Amount', type: 'number', valueGetter: (doc) => doc.row.amount / 100 },
            { field: 'from', headerName: 'From' },
            { field: 'to', headerName: 'To' },
            { field: 'fromUser', headerName: 'From User', valueGetter: (params) => params.row.fromUser?.fullName },
            { field: 'toUser', headerName: 'To User', valueGetter: (params) => params.row.toUser?.fullName },
            { field: 'fromMarket', headerName: 'From Market', valueGetter: (params) => params.row.fromMarket?.name },
            { field: 'toMarket', headerName: 'To Market', valueGetter: (params) => params.row.toMarket?.name },
            { field: 'currency', headerName: 'Currency' },
            {
                field: 'feeFromReceiver',
                headerName: 'Fee From Receiver',
                type: 'number',
                valueGetter: (doc) => (doc.row.feeFromReceiver ?? 0) / 100,
            },
            {
                field: 'feeFromSender',
                headerName: 'Fee From Sender',
                type: 'number',
                valueGetter: (doc) => (doc.row.feeFromSender ?? 0) / 100,
            },
            { field: 'type', headerName: 'Type' },
            { field: 'note', headerName: 'Note' },
            { field: 'cashMachineId', headerName: 'Cash Machines' },
            { field: 'feeAccountFromReceiver', headerName: 'Fee Account From Receiver' },
            { field: 'feeAccountFromSender', headerName: 'Fee Account From Sender' },
            { field: 'otherExchangeTransactionId', headerName: 'Other Exchange Transaction Id' },
            { field: 'refundForTransactionId', headerName: 'Refund For Transaction Id' },
            { field: 'refundedInTransactionId', headerName: 'Refunded In Transaction Id' },
        ] as GridColDef<Transaction>[]
    }, [])

    if (!transactions) {
        return <div>Loading...</div>
    }
    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="transactions"
                components={{
                    Toolbar: CustomToolbar,
                }}
                initialState={{
                    aggregation: {
                        model: {
                            amount: 'sum',
                            feeFromReceiver: 'sum',
                            feeFromSender: 'sum',
                        },
                    },
                }}
                className={classes.table}
                getRowId={getRowId}
                rows={transactions}
                columns={columns}
            />
        </div>
    )
}
