import * as msgpack from '@hypatia/utils/encoding/extendedMsgPack'
import Emitter from 'component-emitter'

export const protocol = 5

/**
 * Packet types (see https://github.com/socketio/socket.io-protocol)
 */

export const PacketType = {
    CONNECT: 0,
    DISCONNECT: 1,
    EVENT: 2,
    ACK: 3,
    CONNECT_ERROR: 4,
}

const isInteger =
    Number.isInteger ||
    function (value) {
        return typeof value === 'number' && isFinite(value) && Math.floor(value) === value
    }

function isString(value: unknown): boolean {
    return typeof value === 'string'
}

function isObject(value: unknown): boolean {
    return Object.prototype.toString.call(value) === '[object Object]'
}

function typedArrayToBuffer(encoded: Uint8Array): ArrayBuffer {
    return typeof window === 'undefined'
        ? Buffer.from(encoded.buffer, encoded.byteOffset, encoded.byteLength)
        : encoded.buffer.slice(encoded.byteOffset, encoded.byteLength + encoded.byteOffset)
}
export class Encoder {
    encode = function (packet: unknown) {
        return [typedArrayToBuffer(msgpack.encode(packet))]
    }
}

export interface DecodedPacket {
    type: typeof PacketType[keyof typeof PacketType]
    data: any
    nsp: string
    id: string
}

export class Decoder extends Emitter<any> {
    add(obj: ArrayBuffer) {
        const decoded = msgpack.decode(obj)
        this.checkPacket(decoded as DecodedPacket)
        this.emit('decoded', decoded)
    }

    checkPacket(decoded: DecodedPacket) {
        checkPacket(decoded)
    }

    destroy() {
        /* */
    }
}

export function checkPacket(decoded: DecodedPacket) {
    const isTypeValid =
        isInteger(decoded.type) && decoded.type >= PacketType.CONNECT && decoded.type <= PacketType.CONNECT_ERROR

    if (!isTypeValid) {
        throw new Error('invalid packet type')
    }

    if (!isString(decoded.nsp)) {
        throw new Error('invalid namespace')
    }

    if (!isDataValid(decoded)) {
        throw new Error('invalid payload')
    }

    const isAckValid = decoded.id === undefined || isInteger(decoded.id)
    if (!isAckValid) {
        throw new Error('invalid packet id')
    }
}

export function isDataValid(decoded: DecodedPacket) {
    switch (decoded.type) {
        case PacketType.CONNECT:
            return decoded.data === undefined || isObject(decoded.data)
        case PacketType.DISCONNECT:
            return decoded.data === undefined
        case PacketType.CONNECT_ERROR:
            return isString(decoded.data) || isObject(decoded.data)
        default:
            return Array.isArray(decoded.data)
    }
}
