export default class SettableValue<T> {
    private value: T | undefined
    constructor(private name: string) {}

    get(): T {
        if (!this.value) {
            throw new Error(`trying to get settable value before registering it ${this.name}`)
        }
        return this.value
    }

    set(value: T): void {
        this.value = value
    }
}
