import React from 'react'

interface Props {
    hidden?: boolean
    children: React.ReactNode
    style: any
    className?: string
}

const Fragment = (props: Props) => {
    const { hidden, children, style, className } = props

    return (
        <div
            className={className}
            style={{ display: hidden ? 'none' : undefined, width: hidden ? undefined : '100%', ...style }}
        >
            {children}
        </div>
    )
}

export default Fragment
