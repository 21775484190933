import { getRpcCaller } from '@hypatia/serverer-common/rpc/rpcCaller'
import { RpcType } from '@hypatia/serverer-common/rpc/RpcType'
import { NOT_AUTHORIZED } from '@hypatia/utils/communication/errors'

export type DeleteFCMTokenArgs = {
    token: string
}

export const DeleteFCMTokenRpcName = 'notification.deleteFCMToken'
export type DeleteFCMTokenResults = void
export type DeleteFCMTokenErrors = typeof NOT_AUTHORIZED
export type DeleteFCMTokenRpc = RpcType<
    typeof DeleteFCMTokenRpcName,
    DeleteFCMTokenArgs,
    DeleteFCMTokenResults,
    DeleteFCMTokenErrors
>

export default getRpcCaller<DeleteFCMTokenRpc>(DeleteFCMTokenRpcName)
