import { BaseUserType } from '@hypatia/serverer-common/database/BaseUserTable'
import { createContext } from '../hooks/useContextSelector'

export interface UserRecord
    extends Pick<
        BaseUserType,
        '_id' | 'settings' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'createdAt' | 'photo' | 'phoneNumber'
    > {}

export interface UserContextType {
    loading: boolean
    user: UserRecord | undefined
}

export const UserContext = createContext<UserContextType>({
    loading: true,
    user: undefined,
})
