import { AnySourceStream, Stream, Subscription } from '../stream'

declare module '../stream' {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        asPromise(): Promise<DV>
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}

Stream.prototype.asPromise = function <V>(this: AnySourceStream<V>) {
    return new Promise<V>((resolve, reject) => {
        const subscription: Subscription<V> = {
            value: (val: V) => {
                setTimeout(() => {
                    if (this.subscriptions.has(subscription)) {
                        this.unsubscribe(subscription)
                    }
                }, 0)
                resolve(val)
            },
            error: (err: any) => {
                setTimeout(() => {
                    if (this.subscriptions.has(subscription)) {
                        this.unsubscribe(subscription)
                    }
                }, 0)
                reject(err)
            },
        }
        this.subscribe(subscription)
    })
}
