import { shallowDiff } from '../../comparators/shallow-diff'
import { AnySourceStream, Stream } from '../stream'

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        changes(): Stream<DV, DE, DC, Partial<DV>, DE, DC>
    }
}

Stream.prototype.changes = function <V extends object, E, C>(this: AnySourceStream<V, E, C>) {
    return this.reduce(
        ({ lastValue }, currentValue) => ({
            changes: shallowDiff(lastValue, currentValue),
            lastValue: currentValue,
        }),
        { changes: {}, lastValue: {} as V }
    ).map((x) => x.changes)
}
