import { getRpcCaller } from '@hypatia/serverer-common/rpc/rpcCaller'
import { RpcType } from '@hypatia/serverer-common/rpc/RpcType'
import { TrackingId } from '../TrackingId'

export type ReportEventsArgs = {
    events: {
        eventName: string
        args?: any
        trackOnDisconnect?: TrackingId
    }[]
}

export const ReportEventsRpcName = 'usage.reportEvents'
export type ReportEventsResults = void
export type ReportEventsErrors = void
export type ReportEventsRpc = RpcType<
    typeof ReportEventsRpcName,
    ReportEventsArgs,
    ReportEventsResults,
    ReportEventsErrors
>

export default getRpcCaller<ReportEventsRpc>(ReportEventsRpcName)
