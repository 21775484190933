import useIntervalDynamic from '@hypatia/react-utils/hooks/time/useIntervalDynamic'

export function useHideTableStamp() {
    useIntervalDynamic(() => {
        const tables = Array.from(document.getElementsByClassName('MuiDataGrid-main'))
        for (const table of tables) {
            const stamp = Array.from(table.children).find((x) => x.innerText === 'MUI X Missing license key')
            if (stamp) {
                stamp.style.display = 'none'
            }
        }
    }, 100)
}
