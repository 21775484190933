import { FieldValidation, ValidationMessage } from './types'
import validateField from './validateField'

export default function any(rules: FieldValidation[], customMessage?: ValidationMessage): FieldValidation {
    return {
        message: (args) => {
            if (typeof customMessage === 'function') {
                return customMessage(args)
            }
            if (customMessage) {
                return args.t(customMessage)
            }
            return args.error
        },
        validate: (args) => {
            let res: boolean | string = true
            for (const rule of rules) {
                res = validateField(args.doc, args.fieldName, rule)
                if (res === true) {
                    return true
                }
            }
            return res
        },
    }
}
