import GlobalLoadingProvider from '@hypatia/react-utils/connect/GlobalLoadingProvider'
import ErrorBoundary from '@hypatia/react-utils/containers/ErrorBoundary'
import LocaleProvider from '@hypatia/react-utils/containers/LocaleProvider'
import SettingsProvider from '@hypatia/react-utils/containers/SettingsProvider'
import UserProvider from '@hypatia/react-utils/containers/UserProvider'
import FullSuspense from '@hypatia/react-utils/hooks/FullSuspense'
import LocationProvider from '@hypatia/react-utils/router/LocationProvider'
import { useTrackUserId } from '@hypatia/serverer-client/analytics/useTrackUserId'
import React from 'react'
import AlertNotificationProvider from './AlertNotificationProvider'
import App from './App'
import GlobalLoadingSupplier from './GlobalLoadingSupplier'

export default function Root(): JSX.Element {
    useTrackUserId()
    return (
        <GlobalLoadingProvider>
            <AlertNotificationProvider>
                <LocationProvider>
                    <ErrorBoundary>
                        <FullSuspense fallback={<p>{'loading...'}</p>} fragmentStyle={{ height: '100%' }}>
                            <SettingsProvider>
                                <GlobalLoadingSupplier>
                                    <LocaleProvider>
                                        <UserProvider>
                                            <App />
                                        </UserProvider>
                                    </LocaleProvider>
                                </GlobalLoadingSupplier>
                            </SettingsProvider>
                        </FullSuspense>
                    </ErrorBoundary>
                </LocationProvider>
            </AlertNotificationProvider>
        </GlobalLoadingProvider>
    )
}
