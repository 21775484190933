import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

type DisposeFn = () => void

export class DoOnDisconnecting<V, E = never, C = never> extends Pass<V, E, C> {
    constructor(
        protected onDisconnecting: (stream: Stream<V, E, C>) => DisposeFn | void,
        source?: AnySourceStream<V, E, C>
    ) {
        super(source)
    }

    override connect(): void {
        super.connect()
    }

    override disconnect(): void {
        super.disconnect()
        this.onDisconnecting(this)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        doOnDisconnecting(
            onDisconnecting: (stream: Stream<DV, DE, DC>) => DisposeFn | void
        ): Stream<DV, DE, DC, DV, DE, DC>
    }
}

Stream.prototype.doOnDisconnecting = function <DV, DE, DC>(
    this: AnySourceStream<DV, DE, DC>,
    onDisconnecting: (stream: Stream<DV, DE, DC>) => DisposeFn | void
) {
    return new DoOnDisconnecting(onDisconnecting, this) // tslint:disable-line:no-invalid-this
}
