import type { Table } from './table'
import { BaseUserTable } from './BaseUserTable'

export const globalIndices = [
    '_id',
    '__versionId',
    'cachedFieldsToUpdate',
    'relatedCachedFieldsToUpdate',
    'flaggedForDeletion',
    'foreignFieldsUpdated',
    'shouldRecalculateFiles',
    'recalculateFiles',
]

export const tableMap = new Map<string, Table>()

export const addTable = (table: Table): void => {
    if (!table.additionalIndices) {
        table.additionalIndices = []
    }
    table.additionalIndices.push(...globalIndices.map((index) => [index]))
    tableMap.set(table.collectionName, table)
}

addTable(BaseUserTable)
