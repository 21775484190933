import useAsyncFp from '@hypatia/react-utils/hooks/async/useAsyncFp'
import getDownloadUrlFromFileToken from '@hypatia/serverer-client/storage/GetDownloadUrlFromFileToken'
import { FileId, FileToken } from '@hypatia/serverer-common/storage/tables/FileTable'
import { Obj, OnlyString, value } from '@hypatia/utils/types'
import React from 'react'
import { Overwrite } from 'ts-toolbelt/out/Object/Overwrite'

interface Options {
    throwOnError?: boolean
    errorValue?: string
    loadingValue?: string
    defaultValue?: string
}

export function withFileUrl<P extends object, K extends keyof P>(
    Comp: React.ComponentType<P>,
    prop: K,
    options?: Options
): React.ComponentType<Overwrite<P, Obj<OnlyString<K>, FileToken | FileId | undefined>>> {
    function WithFileUrl(props: Overwrite<P, Obj<OnlyString<K>, FileToken | FileId | undefined>>): JSX.Element {
        const token = props[prop] as FileToken
        const fileId = token?.split('|')[0] as FileId
        const [fileUrl, loading, error] = useAsyncFp(
            () => (token ? getDownloadUrlFromFileToken({ token }) : Promise.resolve(value(undefined))),
            [fileId]
        )

        const urlValue = error
            ? options?.errorValue
            : loading
            ? options?.loadingValue
            : fileUrl ?? options?.defaultValue

        if (error && options?.throwOnError) {
            throw error
        }

        const newProps = { ...props, [prop]: urlValue } as P
        return <Comp {...newProps} />
    }

    return WithFileUrl
}
