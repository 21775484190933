import cashiiRPCs from '@cashii/common/src/cashiiRpcs'
import { CashMachineId, Currency, Money, TransactionId } from '@cashii/common/src/db/db'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import { formatAmount } from '@cashii/common/src/utils/formatAmount'
import { getCurrencySymbol } from '@cashii/common/src/utils/getCurrencySymbol'
import useAsyncFp from '@hypatia/react-utils/hooks/async/useAsyncFp'
import useAsRef from '@hypatia/react-utils/hooks/useAsRef'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import classNames from 'classnames'
import { orderBy, sumBy, uniq } from 'lodash'
import QRCodeStyling from 'qr-code-styling'
import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { Img } from '../../../components/Img'
import classes from './Atm.module.css'
import logo from './assets/atm-logo.png'
import atmNotReady from './assets/atmNotReady.png'
import avatar from './assets/avatar.png'
import cover from './assets/cover.jpg'
import deposit from './assets/deposit.png'
import errorSign from './assets/error-sign.png'
import error from './assets/error.png'
import loadingPng from './assets/loading.png'
import logo2 from './assets/logo-2.png'
import market from './assets/market.png'
import success from './assets/success.png'
import { getAtmMessage } from './getAtmMessage'
interface Props {
    cashMachineId: CashMachineId
}

export function getCurrencyNameFromCurrency(currency: Currency): string {
    switch (currency) {
        case 'TRY':
            return 'ليرة تركية'
        case 'USD':
            return 'دولار أمريكي'
        default:
            return 'ليرة تركية'
    }
}

export default React.memo(Atm)
function Atm(props: Props): JSX.Element {
    const { cashMachineId } = props

    useEffect(() => {
        function cancelZooming(e: TouchEvent) {
            if (e.touches.length > 1) {
                e.preventDefault()
            }
            return false
        }

        document.body.addEventListener('touchmove', cancelZooming, true)
        return () => {
            document.body.removeEventListener('touchmove', cancelZooming, true)
        }
    }, [])

    const message = getAtmMessage()

    const [_info] = useDeliverLast(() => clientLiveQueries.atmInfo(cashMachineId), [cashMachineId])

    const info = _info?.[0]

    const qrRef = React.useRef<HTMLDivElement>(null)
    const liveQrCode = info?.liveQrCode
    const showQr = info?.atm?.status === 'qr'

    const [loading, setLoading] = React.useState(false)

    useLayoutEffect(() => {
        if (!qrRef.current || !showQr) {
            return
        }
        const qrCode = new QRCodeStyling({
            width: window.innerWidth * 0.35,
            height: window.innerWidth * 0.35,
            backgroundOptions: {
                color: 'transparent',
            },

            type: 'svg',
            data: liveQrCode ?? 'no data',
        })
        qrRef.current!.innerHTML = ''
        qrCode.append(qrRef.current!)
    }, [liveQrCode, info, showQr])

    // TODO, sorry this operation is un available right now please try again later
    // ATM will go come back in few minutes

    const [currency, setCurrency] = React.useState<Currency>('TRY')
    const [amount, setAmount] = React.useState(0)

    const TRYAccount = info?.atm?.accounts?.find((x) => x.currency === 'TRY')
    const currentTRYBalance = TRYAccount?.balance || (0 as Money)
    const USDAccount = info?.atm?.accounts?.find((x) => x.currency === 'USD')
    const currentUSDBalance = USDAccount?.balance || (0 as Money)

    const currentBalance = currency === 'TRY' ? currentTRYBalance : currentUSDBalance

    const channels = orderBy(info?.atm?.channels ?? [], 'value', 'asc')
    const currentCurrencyChannels = channels.filter((x) => x.currency === currency)

    const maxByMachine = sumBy(currentCurrencyChannels, (x) => x.value * x.storedInPayout) / 100
    const minByMachine = (currentCurrencyChannels.find((x) => x.storedInPayout > 0)?.value ?? 0) / 100

    const account = info?.atm?.accounts?.find((x) => x.currency === currency)?._id
    const [feesObj] = useAsyncFp(
        () =>
            cashiiRPCs.transactions.getPrices({
                amount: 0 as Money,
                currency: currency,
                operation: 'withdrawFromAtm',
                side: 'sender',
                senderAccountId: account!,
            }),
        [!account],
        [account, currency]
    )
    const fee = Math.ceil(((feesObj?.feePercent || 1) * amount * 100) / 1000) as Money

    const finishOperation = useCallback(async () => {
        setLoading(true)
        const [err] = await cashiiRPCs.atm.atmCommand({
            atmId: cashMachineId,
            command: {
                type: 'finishOperation',
            },
        })
        if (err) {
            if (err === 'insufficientFunds') {
                setErrorMessage(`رصيدك غير كافي للسحب`)
                setLoading(false)
                return
            } else {
                setErrorMessage(`حذث خطأ ما الرجاء المحاولة مرة اخرى`)
                setLoading(false)
            }
        } else {
            setAmount(0)
            setCurrency('TRY')
        }
    }, [cashMachineId])
    const withdraw = useCallback(async () => {
        if (amount === 0) {
            setErrorMessage(`الرجاء ادخال المبلغ المراد سحبه او الضغط على زر الإلغاء`)
            return
        }
        if (currentBalance < amount * 100 + fee) {
            setErrorMessage(`رصيدك غير كافي للسحب`)
            return
        }
        setLoading(true)
        const [err] = await cashiiRPCs.atm.atmCommand({
            atmId: cashMachineId,
            command: {
                type: 'withdraw',
                amount: (amount * 100) as Money,
                currency: currency,
            },
        })
        if (err) {
            // TODO handle error
            // alert(err)
        } else {
            setAmount(0)
            setCurrency('TRY')
        }
    }, [amount, cashMachineId, currency, currentBalance, fee])

    useEffect(() => {
        setAmount(0)
        setCurrency('TRY')
    }, [info?.atm?.market?._id, info?.atm?.user?._id])

    const infoRef = useAsRef(info)
    useEffect(() => {
        if (!['success', 'partialSuccess'].includes(info?.atm?.status ?? '') || info?.atm?.step === 'syncingChannels') {
            return
        }
        const timeout = setTimeout(() => {
            if (
                !['success', 'partialSuccess'].includes(
                    infoRef.current?.atm?.status ?? ''
                ) /*  || infoRef.current?.atm?.step === 'syncingChannels' */
            ) {
                return
            }
            cashiiRPCs.atm.atmCommand({
                atmId: cashMachineId,
                command: {
                    type: 'resetAfterSuccess',
                },
            })
        }, 5000)
        return () => clearTimeout(timeout)
    }, [info?.atm?.status, cashMachineId, infoRef, info?.atm?.step])

    useEffect(() => {
        // if minByMachine is less than current balance then set it to minByMachine
        if (currentBalance / 100 < minByMachine) {
            setAmount(minByMachine)
        }
        if (amount > maxByMachine) {
            setAmount(maxByMachine)
        } else if (amount < minByMachine) {
            setAmount(minByMachine)
        }
    }, [amount, currentBalance, maxByMachine, minByMachine])

    const maxAmountByAccount = Math.max(
        currency === 'TRY'
            ? currentTRYBalance - (TRYAccount?.minimumBalance || 0)
            : currentUSDBalance - (USDAccount?.minimumBalance || 0),
        0
    ) as Money

    const step = minByMachine

    const onPlus = useCallback(() => {
        const newAmount = amount + step
        if (newAmount > maxByMachine) {
            setErrorMessage(`اكبر مبلغ يمكنك سحبه هو ${maxByMachine} ${getCurrencyNameFromCurrency(currency)}`)
            return
        }
        if (newAmount > maxAmountByAccount) {
            setErrorMessage(
                `اكبر مبلغ يمكنك سحبه هو ${formatAmount(maxAmountByAccount)} ${getCurrencyNameFromCurrency(currency)}`
            )
            return
        }
        setAmount((x) => Math.min(x + step, maxAmountByAccount, maxByMachine))
    }, [amount, currency, maxAmountByAccount, maxByMachine, step])

    const onMinus = useCallback(() => {
        const newAmount = amount - step
        if (newAmount < minByMachine) {
            setErrorMessage(`اقل مبلغ يمكنك سحبه هو ${minByMachine} ${getCurrencyNameFromCurrency(currency)}`)
            return
        }
        setAmount((x) => Math.max(x - step, minByMachine))
    }, [amount, currency, minByMachine, step])

    const onCurrencyChange = useCallback(
        (newCurrency: Currency) => {
            const _minByMachine =
                (channels.find((x) => x.currency === newCurrency && x.storedInPayout > 0)?.value ?? 0) / 100

            if (!_minByMachine) {
                if (newCurrency === 'TRY') {
                    setErrorMessage(`سحب ليرة تركية غير متاح حالياً`)
                } else {
                    setErrorMessage(`سحب دولار امريكي غير متاح حالياً`)
                }
                return
            }

            if (
                (newCurrency === 'TRY' && currentTRYBalance / 100 < _minByMachine) ||
                (newCurrency === 'USD' && currentUSDBalance / 100 < _minByMachine)
            ) {
                setErrorMessage(`الحد الادنى للسحب هو ${getCurrencySymbol(newCurrency)}${_minByMachine}`)
                return
            }

            setCurrency(newCurrency)
            setAmount(_minByMachine)
        },
        [channels, currentTRYBalance, currentUSDBalance]
    )

    const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

    useEffect(() => {
        setLoading(false)
    }, [info?.atm?.status])

    const [, setTime] = React.useState(Date.now())
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now())
        }, 100)
        return () => clearInterval(interval)
    }, [])

    const lastReadyAt = info?.atm?.lastReadyAt
    const isLastReadyAtMoreThan20SecondsAgo = lastReadyAt && Date.now() - +lastReadyAt > 60 * 1000
    const [disabledSessions, setDisabledSessions] = React.useState<TransactionId[]>([])
    useEffect(() => {
        if (isLastReadyAtMoreThan20SecondsAgo && info?.atm?.user) {
            if (info.atm!.commandId) {
                setDisabledSessions((x) => uniq([...x, info.atm!.commandId!]))
            }
            cashiiRPCs.atm.atmCommand({
                atmId: cashMachineId,
                command: {
                    type: 'clientTimeoutDuringOperation',
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLastReadyAtMoreThan20SecondsAgo, info?.atm?.commandId])

    let updatedStatus = info?.atm?.status
    if (disabledSessions.includes(info?.atm?.commandId as TransactionId)) {
        updatedStatus = 'errorDuringOperation'
    }
    if (isLastReadyAtMoreThan20SecondsAgo) {
        if (info?.atm?.user) {
            updatedStatus = 'errorDuringOperation'
        } else if (updatedStatus !== 'errorDuringOperation') {
            updatedStatus = 'error'
        }
    }
    const [hideErrorDuringOperation, setHideErrorDuringOperation] = React.useState(false)
    useEffect(() => {
        if (updatedStatus !== 'errorDuringOperation') {
            setHideErrorDuringOperation(false)
            return
        }
        const timeout = setTimeout(() => {
            setHideErrorDuringOperation(true)
        }, 30000)
        return () => clearTimeout(timeout)
    }, [updatedStatus])

    if (!info?.atm) {
        return <div className={classes.centerContentContainer}>no atm</div>
    }

    const src = info.atm.market ? info.atm.market?.logo : info.atm.user?.photo
    const header = (
        <div className={classes.header}>
            <img src={logo2} className={classes.headerLogo} />
            <div className={classes.headerUserInfo}>
                {src ? (
                    <Img className={classes.avatar} src={src} />
                ) : (
                    <img className={classes.avatar} src={info.atm.market ? market : avatar} />
                )}
                <div className={classes.userNameContainer}>
                    <p className={classes.welcomeBack}>اهلاً بعودتك</p>
                    <p className={classes.userName}>{info.atm.market?.name || info.atm.user?.fullName}</p>
                </div>
            </div>
        </div>
    )

    let UI: React.ReactNode

    if (loading) {
        UI = (
            <div className={classes.mainScreen}>
                <div className={classes.headerContainer}>
                    <img className={classes.headerLogo2} src={logo2} />
                </div>
                <div className={classes.centerContentContainer}>
                    <img className={classes.loadingPng} src={loadingPng} />
                    <p className={classes.atmNotAvailable}>جاري تنفيذ العملية</p>
                    <p className={classes.atmLoadingDescription}>من فضلك انتظر هنا ولا تبتعد عن الصراف</p>
                </div>
                <div className={classes.bottomSpacer} />
            </div>
        )
    } else {
        if (updatedStatus === 'errorDuringOperation' && hideErrorDuringOperation) {
            updatedStatus = 'error'
        }
        switch (updatedStatus) {
            case 'error':
            case 'notReady':
                UI = (
                    <div className={classes.mainScreen}>
                        <div className={classes.headerContainer}>
                            <img className={classes.headerLogo2} src={logo2} />
                        </div>
                        <div className={classes.centerContentContainer}>
                            <img className={classes.atmPhoto} src={atmNotReady} />
                            <p className={classes.atmNotAvailable}>الصراف غير متاح حالياً, سنعود للخدمة قريباً</p>
                        </div>
                        <div className={classes.bottomSpacer}>
                            <div className={classes.customerServiceBlock}>
                                <p className={classes.customerServiceTitle}>رقم خدمة العملاء</p>
                                <p className={classes.customerServiceNumber}>+90 506 250 00 02</p>
                            </div>
                        </div>
                    </div>
                )
                break

            case 'errorDuringOperation':
            case 'partialSuccess':
                UI = (
                    <div className={classes.mainScreen}>
                        <div className={classes.headerContainer}>
                            <img className={classes.headerLogo2} src={logo2} />
                        </div>
                        <div className={classes.centerContentContainer}>
                            <img className={classes.errorPhoto} src={error} />
                            <p className={classes.atmNotAvailable}>حدث خطأ أثناء تنفيذ العملية</p>
                            <div className={classes.customerServiceBlock}>
                                <p className={classes.customerServiceTitle}>رقم خدمة العملاء</p>
                                <p className={classes.customerServiceNumber}>+90 506 250 00 02</p>
                            </div>
                        </div>
                        <div className={classes.bottomSpacer} />
                    </div>
                )
                break

            case 'qr':
                UI = (
                    <>
                        <img src={cover} className={classes.cover} />
                        <div className={classes.content}>
                            <div className={classes.homeLogoContainer}>
                                <img src={logo} className={classes.logo} />
                            </div>

                            {UI}
                            <div className={classes.centerContentContainer}>
                                <div className={classes.qrContainer} ref={qrRef} />
                                <div className={classes.welcomeContainer}>{message}</div>
                            </div>
                            <div />
                        </div>
                    </>
                )
                break

            case 'success':
                UI = (
                    <div className={classes.mainScreen}>
                        <div className={classes.headerContainer}>
                            <img className={classes.headerLogo2} src={logo2} />
                        </div>
                        <div className={classes.centerContentContainer}>
                            <img className={classes.successPhoto} src={success} />
                            <p className={classes.atmNotAvailable}>تمت العملية بنجاح</p>
                        </div>
                        <div className={classes.bottomSpacer} />
                    </div>
                )
                break

            case 'deposit':
                {
                    const newTRYBalance = sumBy(
                        info.atm.deposit?.filter((x) => x.currency === 'TRY'),
                        (x) => x.value
                    ) as Money

                    const newUSDBalance = sumBy(
                        info.atm.deposit?.filter((x) => x.currency === 'USD'),
                        (x) => x.value
                    ) as Money

                    UI = (
                        <div className={classes.mainScreen}>
                            {header}
                            <div className={classes.instructionContainer}>
                                <img src={deposit} className={classes.instructionIcon} />
                                <p className={classes.instructionTitle}>إيداع</p>
                                <p className={classes.instructionDescription}>
                                    يرجى ايداع الاوراق النقدية ورقة تلو الاخرى عند مدخل الاموال المضاء باللون الاخضر
                                </p>
                            </div>
                            <div className={classes.balanceMainContainer}>
                                <div className={classes.balanceContainer}>
                                    <p className={classes.currentBalance}>
                                        رصيدك الحالي {formatAmount(currentTRYBalance)}
                                        {getCurrencySymbol('TRY')}
                                    </p>
                                    <div className={classes.balanceCard}>
                                        <p
                                            className={classNames(classes.newBalance, {
                                                [classes.activeNewBalance]: newTRYBalance > 0,
                                            })}
                                        >
                                            <span className={classes.plusSign}>+</span>
                                            {getCurrencySymbol('TRY')}
                                            {newTRYBalance / 100}
                                        </p>
                                        <p
                                            className={classNames(classes.totalBalance, {
                                                [classes.activeTotalBalance]: newTRYBalance > 0,
                                            })}
                                        >
                                            {getCurrencySymbol('TRY')}
                                            {formatAmount((currentTRYBalance + newTRYBalance) as Money)}
                                        </p>
                                    </div>
                                </div>
                                <div className={classes.balanceContainer}>
                                    <p className={classes.currentBalance}>
                                        رصيدك الحالي {formatAmount(currentUSDBalance)}
                                        {getCurrencySymbol('USD')}
                                    </p>
                                    <div className={classes.balanceCard}>
                                        <div>
                                            <p
                                                className={classNames(classes.newBalance, {
                                                    [classes.activeNewBalance]: newUSDBalance > 0,
                                                })}
                                            >
                                                <span className={classes.plusSign}>+</span>
                                                {getCurrencySymbol('USD')}
                                                {newUSDBalance / 100}
                                            </p>
                                        </div>
                                        <p
                                            className={classNames(classes.totalBalance, {
                                                [classes.activeTotalBalance]: newUSDBalance > 0,
                                            })}
                                        >
                                            {getCurrencySymbol('USD')}
                                            {formatAmount((currentUSDBalance + newUSDBalance) as Money)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.buttonContainer}>
                                <button
                                    className={classNames([classes.button, classes.mainButton])}
                                    onClick={finishOperation}
                                >
                                    اتمام العملية
                                </button>
                                {newTRYBalance + newUSDBalance > 0 ? null : (
                                    <button
                                        className={classNames([classes.button, classes.secondaryButton])}
                                        onClick={finishOperation}
                                    >
                                        إلغاء
                                    </button>
                                )}
                            </div>
                        </div>
                    )
                }
                break
            case 'withdraw':
                UI = (
                    <div className={classes.mainScreen}>
                        {header}
                        <div className={classes.instructionContainer}>
                            <img src={deposit} className={classes.instructionIcon} />
                            <p className={classes.instructionTitle}>سحب</p>
                            <p className={classes.instructionDescription}>
                                يرجى اخيار العملة والمبلغ المراد سحبه والضغط على زر اتمام العملية لاستلام النقود
                            </p>
                        </div>
                        <div className={classes.amountOuterContainer}>
                            <div className={classes.switch}>
                                <div
                                    className={classNames(classes.switchItem, {
                                        [classes.switchItemActive]: currency === 'TRY',
                                    })}
                                    onClick={() => onCurrencyChange('TRY')}
                                >
                                    ليرة تركية
                                </div>
                                <div
                                    className={classNames(classes.switchItem, {
                                        [classes.switchItemActive]: currency === 'USD',
                                    })}
                                    onClick={() => onCurrencyChange('USD')}
                                >
                                    دولار أمريكي
                                </div>
                            </div>
                            <p className={classes.currentBalance}>
                                رصيدك الحالي{' '}
                                {formatAmount(currency === 'TRY' ?  currentTRYBalance: currentUSDBalance)}
                                {getCurrencySymbol(currency)}
                            </p>
                            <div className={classes.amountContainer}>
                                <button className={classes.withdrawSignButton} onClick={onPlus}>
                                    <span style={{ marginTop: -8 }}>+</span>
                                </button>
                                <p className={classes.withdrawAmount}>
                                    {getCurrencySymbol(currency)}
                                    {amount}
                                </p>
                                <button className={classes.withdrawSignButton} onClick={onMinus}>
                                    <span style={{ marginTop: -8 }}>-</span>
                                </button>
                            </div>
                            <p className={classes.currentBalance}>
                                الرسوم {formatAmount(fee)}
                                {getCurrencySymbol(currency)}
                            </p>
                        </div>
                        <div className={classes.buttonContainer}>
                            <button className={classNames([classes.button, classes.mainButton])} onClick={withdraw}>
                                اتمام العملية
                            </button>
                            <button
                                className={classNames([classes.button, classes.secondaryButton])}
                                onClick={finishOperation}
                            >
                                إلغاء
                            </button>
                        </div>
                    </div>
                )
                break
        }
    }

    return (
        <div className={classes.container}>
            {errorMessage && (
                <div className={classes.errorOuterContainer}>
                    <div className={classes.errorContainer}>
                        <img src={errorSign} className={classes.errorSign} />
                        <p className={classes.errorTitle}>{errorMessage}</p>
                        <button
                            className={classNames([classes.button, classes.mainButton, classes.smallButton])}
                            onClick={() => setErrorMessage(null)}
                        >
                            موافق
                        </button>
                    </div>
                </div>
            )}
            {UI}
        </div>
    )
}
