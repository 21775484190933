import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class WaitUntil<V, E = never, C = never> extends Pass<V, E, C> {
    protected checker: (value: V) => boolean

    protected waiting = true

    constructor(checker: (value: V) => boolean, source?: AnySourceStream<V, E, C>) {
        super(source)
        this.checker = checker
    }

    override value(value: V): void {
        if (this.waiting) {
            if (this.checker(value)) {
                this.waiting = false
            } else {
                return
            }
        }

        this._value(value)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        waitUntil(checker: (value: DV) => boolean): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.waitUntil = function <V, E, C>(this: AnySourceStream<V, E, C>, waitUntil: (value: V) => boolean) {
    return new WaitUntil(waitUntil, this) // tslint:disable-line:no-invalid-this
}
