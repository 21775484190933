import isReactNative from '@hypatia/utils/os/isReactNative'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import { useEffect, useMemo } from 'react'
import { useSyncExternalStore } from 'use-sync-external-store/shim'
import useBeforeUnloadWarning from '../dom/useBeforeUnloadWarning'
import { useWaitFor } from '../effects/useWaitFor'
import Editor from './Editor'
import { EditorOptions, EditorProps } from './types'

export default function useEditor<T extends object = any>(
    options: EditorOptions<T> & { loadingList?: boolean[] }
): EditorProps<T> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const editor = useMemo(() => new Editor(options), [])

    if (!isReactNative()) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useBeforeUnloadWarning({ shouldRun: editor.isDirty, title: '' })
    }

    const stillLoading = some(options.loadingList)

    useEffect(() => {
        if (!isEqual(sortBy(editor.initialValues), sortBy(options.initialValues))) {
            editor.setInitialValues(options.initialValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor.initialValues, options.initialValues])

    useWaitFor(() => {
        if (!stillLoading) {
            editor.setInitialValues(options.initialValues!)
        }
    }, [stillLoading])

    return useSyncExternalStore(
        editor.subscribe,
        () => editor.getImmutableEditorProps(),
        () => editor.getImmutableEditorProps()
    )
}
