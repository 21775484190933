import { CashMachineId } from '@cashii/common/src/db/db'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { groupBy, map } from 'lodash'
import React from 'react'
import classes from './AtmAccountsPage.module.css'
interface Props {
    cashMachineId: CashMachineId
}

export default React.memo(AtmAccountsPage)
function AtmAccountsPage(props: Props): JSX.Element {
    const { cashMachineId } = props

    const alert = useAlertNotification()
    const [atms] = useDeliverLast(() => clientLiveQueries.atmInfo(cashMachineId), [cashMachineId])
    const atm = atms?.[0]

    return (
        <div className={classes.container}>
            <div className={classes.channelsContainer}>
                <h1>Atm Accounts</h1>
                {map(
                    groupBy(atm?.atm?.channels, (x) => x.currency),
                    (channels) => {
                        return (
                            <div className={classes.currencyGroup}>
                                {channels.map((channel, idx) => {
                                    return (
                                        <div key={idx} className={classes.card}>
                                            <div className={classes.cardRow}>
                                                <span className={classes.title}>currency</span>
                                                <span className={classes.value}>{channel.currency}</span>
                                            </div>
                                            <div className={classes.cardRow}>
                                                <span className={classes.title}>value</span>
                                                <span className={classes.value}>{channel.value / 100}</span>
                                            </div>
                                            <div className={classes.cardRow}>
                                                <span className={classes.title}>storedInPayout</span>
                                                <span className={classes.value}>{channel.storedInPayout}</span>
                                            </div>
                                            <div className={classes.cardRow}>
                                                <span className={classes.title}>storedInCashbox</span>
                                                <span className={classes.value}>{channel.storedInCashbox}</span>
                                            </div>
                                            <div className={classes.cardRow}>
                                                <span className={classes.title}>route</span>
                                                <span className={classes.value}>{channel.route}</span>
                                            </div>
                                            <div className={classes.cardRow}>
                                                <span className={classes.title}>enabled</span>
                                                <span className={classes.value}>{channel.enabled + ''}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                )}
            </div>
            <div>
                <div className={classes.infoBlock}>
                    <h1>Atm Info</h1>
                    <div className={classes.subBlock}>
                        <h3>status</h3>
                        <div>{atm?.atm?.status}</div>
                    </div>
                    <div className={classes.subBlock}>
                        <h3>reportedChannels</h3>
                        <div>{JSON.stringify(atm?.atm?.reportedChannels)}</div>
                    </div>
                </div>
                <div className={classes.infoBlock}>
                    <h1>ongoing operation</h1>
                    <div className={classes.subBlock}>
                        <h2>Payout Commands</h2>
                        {atm?.atm?.payoutCommands?.map((x, idx) => {
                            return <div key={idx}>{JSON.stringify(x)}</div>
                        })}
                    </div>
                    <div className={classes.subBlock}>
                        <h2>Deposit</h2>
                        {atm?.atm?.deposit?.map((x, idx) => {
                            return <div key={idx}>{JSON.stringify(x)}</div>
                        })}
                    </div>
                    <div className={classes.subBlock}>
                        <h2>User</h2>
                        <div className={classes.subBlock}>
                            <h3>user</h3>
                            <div>{JSON.stringify(atm?.atm?.user)}</div>
                        </div>
                        <div className={classes.subBlock}>
                            <h3>market</h3>
                            <div>{JSON.stringify(atm?.atm?.market)}</div>
                        </div>
                        <div className={classes.subBlock}>
                            <h3>accounts</h3>
                            <div>{JSON.stringify(atm?.atm?.accounts)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
