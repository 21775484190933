import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { UserId } from '@hypatia/serverer-common/database/nominalTypes'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import { Button } from '@mui/material'
import {
    GridColDef
} from '@mui/x-data-grid-premium'
import { omit } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { CustomToolbar } from '../../components/CustomToolbar'
import { useGroups } from '../GroupNotifications/useGroups'
import { useCities } from '../Markets/MarketsPage'
import { OpenInWindow } from '../VerifyUsersIds/OpenInWindow'
import StoredDataGridPremium from './StoredDataGridPremium'
import classes from './UsersPage.module.css'

export default React.memo(UsersPage)

type User = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getAllUsers>
function UsersPage(): JSX.Element {
    const [groups] = useGroups()

    const [_users] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllUsers(), [])
    const [users, setUsers] = React.useState<User[] | null>(null)

    const [cities] = useCities()
    useEffect(() => {
        if (_users) {
            setUsers(_users)
        }
    }, [_users])

    const alert = useAlertNotification()

    const askToReUploadId = useCallback(
        async (userId: UserId) => {
            const [err] = await clientUpdates.controlPanel.updateUser(userId, {
                uploadedIdPhotos: false,
                sendReUploadIdNotification: true,
            })
            if (err) {
                alert({
                    variant: 'error',
                    alertText: err,
                })
                return
            }
            alert({
                variant: 'success',
                alertText: 'User updated',
            })
        },
        [alert]
    )

    const getRowId = useCallback((row: User) => row._id, [])
    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            {
                field: 'fullName',
                headerName: 'Full Name',
                editable: true,
                type: 'string',
            },
            {
                field: 'fullNameInEnglish',
                headerName: 'Full Name In English',
                editable: true,
                type: 'string',
            },
            {
                field: 'city',
                headerName: 'City',
                editable: true,
                type: 'singleSelect',
                valueOptions: cities?.map((city) => ({ value: city._id, label: city.name })),
            },
            {
                field: 'phone',
                headerName: 'Phone',
                editable: true,
                type: 'string',
            },
            {
                field: 'groupId',
                headerName: 'Group',
                editable: true,
                type: 'singleSelect',
                valueOptions: groups?.map((group) => ({ value: group._id, label: group.name })),
            },
            {
                field: 'sex',
                headerName: 'Sex',
                editable: true,
                type: 'singleSelect',
                valueOptions: ['male', 'female'],
            },
            { field: 'createdAt', headerName: 'Created At', type: 'datetime' },
            {
                field: 'uploadedIdPhotos',
                headerName: 'Uploaded Id Photos',
                editable: true,
                type: 'boolean',
            },
            {
                field: 'gotInitialCredit',
                headerName: 'Got Initial Credit',
                type: 'boolean',
            },
            {
                field: 'idVerified',
                headerName: 'Id Verified',
                editable: true,
                type: 'boolean',
            },
            {
                field: 'nationalNumber',
                headerName: 'National Number',
                editable: true,
                type: 'string',
            },
            {
                field: 'idVerificationNote',
                headerName: 'Id Verification Note',
                editable: true,
                type: 'string',
            },
            {
                field: 'disabled',
                headerName: 'Disabled',
                editable: true,
                type: 'boolean',
            },
            {
                field: 'idPhotos',
                headerName: 'Id Photos',
                renderCell: (params) =>
                    params.row.idPhotos ? (
                        <>
                            <OpenInWindow href={params.row.idPhotos.front}>Front</OpenInWindow>
                            <OpenInWindow href={params.row.idPhotos.back}>back</OpenInWindow>
                        </>
                    ) : null,
            },
            {
                field: 'actions',
                headerName: 'Actions',
                renderCell: ({ row }) => (
                    <div>
                        {row.uploadedIdPhotos && !row.idVerified && (
                            <Button onClick={() => askToReUploadId(row._id)} color="primary">
                                ReUpload Id
                            </Button>
                        )}
                    </div>
                ),
            },
        ] as GridColDef<User>[]
    }, [askToReUploadId, cities, groups])

    const processRowUpdate = useCallback(
        async (newRow: User) => {
            const [err] = await clientUpdates.controlPanel.updateUser(newRow._id, omit(newRow, '_id'))
            if (err) {
                throw err
            }
            alert({
                variant: 'success',
                alertText: 'User updated',
            })
            return newRow
        },
        [alert]
    )

    const handleProcessRowUpdateError = useCallback(
        (error: string) => {
            alert({
                variant: 'error',
                alertText: error,
            })
        },
        [alert]
    )



    if (!users) {
        return <div>Loading...</div>
    }
    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="users"
                components={{
                    Toolbar: CustomToolbar,
                }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                className={classes.table}
                getRowId={getRowId}
                rows={users}
                columns={columns}
            />
        </div>
    )
}


