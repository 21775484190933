// import '@hypatia/serverer-client/opentelemetry/sentry'
import { setAutoFreeze } from 'immer'
import React from 'react'
import { createRoot } from 'react-dom/client'
import './config/index'
import Root from './containers/Root'
import './index.css'

// eslint-disable-next-line no-console
console.info(`running version ${process.env.VERSION}`)
setAutoFreeze(false)

const correctedHref = location.href
    .replace('www.', '')
    .replace('http://', process.env.SKIP_HTTPS_REDIRECTS === 'true' ? 'http://' : 'https://')

if (location.href !== correctedHref) {
    location.href = correctedHref
}

const App = (
    <React.Suspense fallback={<p>loading...</p>}>
        <div id="portal" />
        <div id="global-loading" />

        <Root />
    </React.Suspense>
)

const root = createRoot(document.getElementById('root')!)
root.render(App)
