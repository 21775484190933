import { asyncStorage } from '@hypatia/utils/async-storage'
import generateUniqueId from '@hypatia/utils/generators/generateUniqueId'
import decodeToken from '@hypatia/utils/jwt/decodeToken'
import { useEffect } from 'react'

declare global {
    interface Window {
        clarity?: any
    }
}

asyncStorage.setIfNotExists('opx-device-id', generateUniqueId({ size: 'long' }))
const anonymousIdFromQuery = typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get('a') : null
asyncStorage.setIfNotExists('opx-anonymous-id', anonymousIdFromQuery ?? generateUniqueId({ size: 'long' }))

export function useTrackUserId() {
    useEffect(() => {
        function updateId() {
            if (typeof window !== 'undefined' && window.clarity) {
                ;(async () => {
                    const token = await asyncStorage.get('opx-token')
                    const userId = token ? decodeToken(token).userId : null
                    window.clarity('identify', userId || (await asyncStorage.get('opx-anonymous-id')))
                })()
            }
        }
        const unsub: Array<() => void> = []
        unsub.push(asyncStorage.onchange('opx-anonymous-id', updateId))
        unsub.push(asyncStorage.onchange('opx-token', updateId))

        return () => {
            unsub.forEach((fn) => fn())
        }
    }, [])
}
