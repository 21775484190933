import React from 'react'
import CSSTransition from 'react-transition-group/CSSTransition'
import SwitchTransition from 'react-transition-group/SwitchTransition'

export type AnimationsTypes =
    | 'fade'
    | 'scale'
    | 'translate'
    | 'translate-right'
    | 'translate-up'
    | 'translate-down'
    | 'translate-swipe'
interface Props {
    children: React.ReactNode // only one element, the effect will be applied to the element children
    changeKey: number | string | boolean | undefined
    animation?: AnimationsTypes
    timeout?: number
}

export default React.memo(Animate)

function Animate(props: Props): JSX.Element {
    const { children, changeKey, animation = 'fade', timeout = 200 } = props

    return (
        <SwitchTransition mode="out-in">
            <CSSTransition timeout={timeout} key={changeKey + ''} classNames={animation}>
                {children}
            </CSSTransition>
        </SwitchTransition>
    )
}
