import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import React from 'react'
import { A } from '../Home/A'
import classes from './CashMachinesPage.module.css'

export default React.memo(CashMachines)
function CashMachines(): JSX.Element {
    const [cashMachines] = useDeliverLast(() => clientLiveQueries.controlPanel_getAccessibleCashMachines(), [])

    return (
        <div>
            <ul className={classes.list}>
                {cashMachines?.map((cashMachine) => (
                    <li key={cashMachine._id}>
                        <A href={'/cash-machines/' + cashMachine._id}>{cashMachine.systemName}</A>
                    </li>
                ))}
            </ul>
        </div>
    )
}
