import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Log<SV, SE = any, SC = any> extends Pass<SV, SE, SC> {
    protected logKey: string
    protected toJson: boolean | undefined

    constructor(logKey: string, source?: AnySourceStream<SV, SE, SC>, toJson?: boolean) {
        super(source)
        this.logKey = logKey
        this.toJson = toJson
    }

    override value(value: SV): void {
        if (this.toJson) {
            // eslint-disable-next-line no-console
            console.log(this.logKey, JSON.stringify(value))
        } else {
            // eslint-disable-next-line no-console
            console.log(this.logKey, value)
        }
        this._value(value as any)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        log(logKey: string, toJson?: boolean): Stream<DV, DE, DC, DV, DE, DC>
    }
}

Stream.prototype.log = function <SV, SE, SC>(this: AnySourceStream<SV, SE, SC>, logKey: string, toJson?: boolean) {
    return new Log(logKey, this, toJson) // tslint:disable-line:no-invalid-this
}
