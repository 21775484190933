import fp from '@hypatia/utils/promise/fp'
import promiseWithTimeout from '@hypatia/utils/promise/promiseWithTimeout'
import { AllRpcErrors, RpcArgs, RpcName, RpcResults } from './RpcType'
import './ServererFrontend'
import { servererFrontend } from './ServererFrontend'

export interface RpcCallerConfig {
    timeout?: number
    skipApm?: boolean
}

export function getRpcCaller<Rpc>(
    rpcName: RpcName<Rpc>,
    settings?: RpcCallerConfig
): (args: RpcArgs<Rpc>) => Promise<[undefined, RpcResults<Rpc>] | [AllRpcErrors<Rpc>, undefined]> {
    const { timeout /* skipApm */ } = settings ?? {}
    function caller(args: RpcArgs<Rpc>): Promise<RpcResults<Rpc>> {
        const sfe = servererFrontend.get()
        const promise = sfe.callRPC(
            {
                method: rpcName,
                args,
            }
            /* { skipApm } */
        )
        if (timeout) {
            return promiseWithTimeout(promise, timeout, rpcName)
        }
        return promise
    }

    return function (args: RpcArgs<Rpc>) {
        return fp(caller(args)) as Promise<[undefined, RpcResults<Rpc>] | [AllRpcErrors<Rpc>, undefined]>
    }
}
