import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import values from 'lodash/values'
import { AnySourceStream, Stream } from '../stream'

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        distinctUntilChangedProps(...props: (keyof DV)[]): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.distinctUntilChangedProps = function <V, E, C>(this: AnySourceStream<V, E, C>, ...props: (keyof V)[]) {
    return this.distinctUntilChanged((obj) => values(pick(obj, props)), isEqual)
}
