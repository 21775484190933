import { UserContext } from './UserContext'
import { userRecordStream } from './userIdStream'
import useDeliverLast from '../hooks/useDeliverLast'
import React from 'react'
import useIsClient from '@hypatia/react-utils/hooks/useIsClient'
import { deliverLast } from '@hypatia/utils/streams/operators/deliver-last'

interface Props {
    children?: JSX.Element
    waitForClient?: boolean
}

export default function UserProvider({ children, waitForClient }: Props): JSX.Element | null {
    const isClient = useIsClient()
    const [userRecord, loading] = useDeliverLast(
        () => {
            return !waitForClient || isClient ? userRecordStream : deliverLast(undefined)
        },
        { suspense: true },
        [],
        [waitForClient ? isClient : null]
    )

    if (loading) {
        return null
    }

    return <UserContext.Provider value={{ loading: loading, user: userRecord }}>{children}</UserContext.Provider>
}
