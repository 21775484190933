import React, { useState } from 'react'
import { useEffectRightNow } from '../hooks/useEffectRightNow'
import { history } from './history'
import { LocationContext } from './LocationContext'

interface Props {
    children: React.ReactNode
}

export default React.memo(LocationProvider)

LocationContext.initialValue = history.location

function LocationProvider({ children }: Props): JSX.Element {
    const [location, setLocation] = useState(history.location)

    useEffectRightNow(() => {
        return history.listen((newLocation) => {
            setLocation(newLocation.location)
        })
    }, [])

    return <LocationContext.Provider value={location}>{children}</LocationContext.Provider>
}
