import { AnySourceStream, Stream } from '../stream'

export class Pass<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> extends Stream<SV, SE, SC, DV, DE, DC> {
    constructor(protected source?: AnySourceStream<SV, SE, SC>) {
        super()
    }

    override connect(): void {
        super.connect()
        if (this.source) {
            this.source.subscribe(this)
        }
    }

    override disconnect(): void {
        super.disconnect()
        if (this.source) {
            this.source.unsubscribe(this)
        }
    }
}
