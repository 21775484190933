import defined from '@hypatia/utils/object/defined'
import SettableValue from '@hypatia/utils/SettableValue'
import type { OnlyString, Values } from '@hypatia/utils/types'
import type { List, String } from 'ts-toolbelt'
import type { RouteDict, RouteObj } from './RouteObj'

export const webRoutesGetter = new SettableValue<() => RouteDict>('webRoutesGetter')

let flattened: Record<string, RouteObj>

export const getFlattenRoutes = (): Record<string, RouteObj> => {
    if (flattened) {
        return flattened
    }

    flattened = {}

    function flatten(dict: RouteDict): void {
        for (const [key, route] of Object.entries(dict)) {
            if (flattened[key]) {
                if (process.env.NODE_ENV === 'development') {
                    throw new Error(`duplicated route key ${key}`)
                }
            }
            flattened[key] = route
            if (route.children) {
                flatten(route.children)
            }
        }
    }

    const routes = webRoutesGetter.get()()
    flatten(routes)
    return flattened
}

export default function getPathForRoute<Routes extends Record<string, string>, K extends keyof Routes>(
    route: OnlyString<K>,
    args: ArgsFromPath<Routes[K]>,
    gp?: Record<string, string>
): string {
    const routes = getFlattenRoutes()
    let path = routes[route].path
    for (const [key, value] of Object.entries(args)) {
        path = path.replace(`:${key}`, value as string)
    }
    if (defined(gp)) {
        path = path.concat('?')
        for (const [key, value] of Object.entries(gp)) {
            if (value) {
                path = path.concat(`${key}=${value}&`)
            }
        }
        path = path.slice(0, path.length - 1)
    }

    return path
}

type ArgsListFromPath<Path extends string> = String.Split<List.Tail<String.Split<Path, ':'>>[number], '/'>[0]
export type ArgsFromPath<Path extends string> = Record<ArgsListFromPath<Path>, string>
type HasChildren<T extends RouteDict> = { children: T }

type WithChildren<T extends RouteDict> = {
    [key in keyof T]: T[key] extends HasChildren<infer C>
        ? Record<key, T[key]['path']> & Values<WithChildren<C>>
        : Record<key, T[key]['path']>
}

export type Routes<T extends RouteDict> = Values<WithChildren<T>>
