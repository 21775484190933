import _debounce from 'lodash/debounce'
import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Debounce<V, E = never, C = never, T = V> extends Pass<V, E, C> {
    protected last: T | undefined

    constructor(
        protected override source?: AnySourceStream<V, E, C>,
        protected wait?: number,
        protected options?: { leading?: boolean; trailing?: boolean; maxWait?: number }
    ) {
        super(source)
        this.debouncedValue = _debounce(this._value, wait, options)
    }

    debouncedValue

    override value(value: V): void {
        this.debouncedValue(value)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        debounce(
            wait?: number,
            options?: { leading?: boolean; trailing?: boolean; maxWait?: number }
        ): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.debounce = function <V, E, C>(
    this: AnySourceStream<V, E, C>,
    wait?: number,
    options?: { leading?: boolean; trailing?: boolean; maxWait?: number }
) {
    return new Debounce(this, wait, options) // tslint:disable-line:no-invalid-this
}
