import { CashMachineId, Money } from '@cashii/common/src/db/db'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import { formatAmount } from '@cashii/common/src/utils/formatAmount'
import { getCurrencySymbol } from '@cashii/common/src/utils/getCurrencySymbol'
import useIntervalDynamic from '@hypatia/react-utils/hooks/time/useIntervalDynamic'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import QRCodeStyling from 'qr-code-styling'
import React, { useLayoutEffect } from 'react'
import logo from '../../assets/images/logo.png'
import logo2 from './Atm/assets/atm-logo.png'
import cover from './Atm/assets/cover.jpg'
import { getAtmMessage } from './Atm/getAtmMessage'
import classes from './CashMachineLiveQrCode.module.css'
interface Props {
    cashMachineId: CashMachineId
}

export default React.memo(CashMachineLiveQrCode)
function CashMachineLiveQrCode(props: Props): JSX.Element {
    const { cashMachineId } = props

    const [qr] = useDeliverLast(() => clientLiveQueries.cashMachineInfo(cashMachineId), [cashMachineId])
    const qrRef = React.useRef<HTMLDivElement>(null)
    const qrItem = qr?.[0]
    const liveQrCode = qrItem?.liveQrCode
    const currentOperation = qrItem?.currentOperation

    const [isExpired, setIsExpired] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    useIntervalDynamic(() => {
        if (
            Date.now() - +(currentOperation?.startedAt ?? 0) > 1000 * 60 * 5 ||
            (currentOperation?.executedAt && Date.now() - +currentOperation.executedAt > 1000 * 10)
        ) {
            setIsExpired(true)
        } else {
            setIsExpired(false)
        }

        if (Date.now() - +(currentOperation?.executedAt ?? 0) < 1000 * 10) {
            setSuccess(true)
        } else {
            setSuccess(false)
        }
    }, 1000 * 1)
    const showQr = !currentOperation || isExpired
    useLayoutEffect(() => {
        if (!qrRef.current || !showQr) {
            return
        }
        const qrCode = new QRCodeStyling({
            width: window.innerWidth * 0.35,
            height: window.innerWidth * 0.35,
            backgroundOptions: {
                color: 'transparent',
            },

            type: 'svg',
            data: liveQrCode ?? 'no data',
        })
        qrRef.current!.innerHTML = ''
        qrCode.append(qrRef.current!)
    }, [liveQrCode, qr, showQr])

    const message = getAtmMessage()

    let UI: React.ReactNode

    if (showQr) {
        return (
            <div className={classes.container2} key="qr">
                <img src={cover} className={classes.cover} />
                <div className={classes.content}>
                    <div className={classes.homeLogoContainer}>
                        <img src={logo2} className={classes.logo} />
                    </div>

                    {UI}
                    <div className={classes.centerContentContainer}>
                        <div className={classes.qrContainer} ref={qrRef} />
                        <div className={classes.welcomeContainer}>{message}</div>
                    </div>
                    <div />
                </div>
            </div>
        )
    }

    const signedFee = ((currentOperation.type === 'deposit' ? -currentOperation.fee! : +currentOperation.fee!) ||
        0) as Money
    return (
        <div className={classes.container} key="no-qr">
            <img src={logo} className={classes.logo} />
            {currentOperation?.user && !isExpired ? (
                <>
                    <h1 className={classes.name}>مرحباً {currentOperation.user.fullName} 👋</h1>
                    <div className={classes.operationDetailsContainer}>
                        {success ? (
                            <>
                                <h1 className={classes.successMessage}>تم العملية بنجاح 🎉</h1>
                                <p className={classes.operationType}>
                                    {getCurrencySymbol(currentOperation.currency!)}
                                    {formatAmount(currentOperation.amount!)}
                                </p>
                            </>
                        ) : currentOperation.amount ? (
                            <>
                                <p className={classes.operationTypeTitle}>
                                    <span>الرسوم</span>
                                    <span>
                                        {getCurrencySymbol(currentOperation.currency!) +
                                            formatAmount(currentOperation.fee!)}
                                    </span>
                                </p>
                                <p className={classes.operationTypeTitle}>
                                    <span>
                                        المبلغ الذي سيتم
                                        {currentOperation.type === 'deposit' ? ' إيداعه ' : ' اقتطاعه '}
                                    </span>
                                    <span>
                                        {getCurrencySymbol(currentOperation.currency!) +
                                            formatAmount((currentOperation.amount + signedFee) as Money)}
                                    </span>
                                </p>
                                <p className={classes.operationType}>
                                    {getCurrencySymbol(currentOperation.currency!)}
                                    {formatAmount(currentOperation.amount)}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className={classes.operationTypeTitle2}>نوع العملية</p>
                                <p className={classes.operationType}>
                                    {currentOperation.type === 'deposit' ? 'إيداع' : 'سحب'}
                                </p>
                            </>
                        )}
                    </div>
                </>
            ) : null}
        </div>
    )
}
