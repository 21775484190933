import cashiiRPCs from '@cashii/common/src/cashiiRpcs'
import { AccountNumber, Currency, Money, TransactionId } from '@cashii/common/src/db/db'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import { formatAmount } from '@cashii/common/src/utils/formatAmount'
import { getCurrencySymbol } from '@cashii/common/src/utils/getCurrencySymbol'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useAsync from '@hypatia/react-utils/hooks/async/useAsync'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { orderBy } from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import classes from './ManualOperationPage.module.css'

export default React.memo(ManualOperationPage)

function ManualOperationPage(): JSX.Element {
    const alert = useAlertNotification()
    const [systemAccounts] = useDeliverLast(
        () =>
            clientLiveQueries
                .controlPanel_getAllSystemAccounts()
                .map((list) => orderBy(list, (x) => x.accountingParentNumber))
                .deliverLast(),
        []
    )
    const [currency, setCurrency] = useState<Currency>('TRY')
    const [from, setFrom] = useState<AccountNumber | undefined>(undefined)
    const [to, setTo] = useState<AccountNumber | undefined>(undefined)
    const [note, setNote] = useState('')

    const [amount, setAmount] = useState('')

    const updateCurrency = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrency(e.target.value as Currency)
        setFrom(undefined)
        setTo(undefined)
    }, [])

    const updateAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = Number(e.target.value)
        const value = Math.round(v * 100) / 100
        if (value !== v) {
            setAmount(value + '')
        } else {
            setAmount(e.target.value)
        }
    }, [])

    const savedRef = useRef<TransactionId>()
    const [fakeId, setFakeId] = useState(Math.random())
    const [res] = useAsync(() => cashiiRPCs.transactions.bookTransactionNumber(), [fakeId])
    const submit = useCallback(async () => {
        const transactionId = res?.[1]
        if (!transactionId) {
            alert({
                variant: 'error',
                alertText: 'Transaction Id not found',
            })
        }
        if (savedRef.current === 'transactionId') {
            return
        }
        savedRef.current = transactionId
        if (!from || !to || !amount) {
            alert({
                variant: 'error',
                alertText: 'Please fill all fields',
            })
            return
        }

        if (from === to) {
            alert({
                variant: 'error',
                alertText: 'From and To cannot be same',
            })
            return
        }

        const moneyAmount = (Number(amount) * 100) as Money
        const result = prompt(
            `transfer ${from}=>${to} ${formatAmount(
                moneyAmount,
                currency
            )}\nplease enter the amount to confirm`
        )
        if (Number(result) * 100 !== moneyAmount) {
            return
        }
        const [err] = await cashiiRPCs.controlPanel.systemOperation({
            transactionId: transactionId as TransactionId,
            from: from as AccountNumber,
            to: to as AccountNumber,
            amount: moneyAmount,
            currency: currency,
            operation: 'transfer',
            note,
        })

        if (err) {
            alert({
                variant: 'error',
                alertText: err,
            })
            return
        } else {
            alert({
                variant: 'success',
                alertText: 'Success',
            })
        }

        savedRef.current = undefined
        setAmount('')
        setNote('')
        setFrom(undefined)
        setTo(undefined)
        setCurrency('TRY')

        setFakeId(Math.random())
    }, [res, amount, from, to, currency, note, alert])

    return (
        <div className={classes.container} key={fakeId}>
            <FormControl className={classes.input}>
                <InputLabel>Currency</InputLabel>
                <Select label="Currency" value={currency} onChange={updateCurrency} className={classes.input}>
                    <MenuItem value="TRY">TRY</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                </Select>
            </FormControl>
            <FormControl className={classes.input}>
                <InputLabel>From</InputLabel>
                <Select
                    label="from"
                    value={from}
                    onChange={(e) => setFrom(e.target.value as AccountNumber)}
                    className={classes.input}
                >
                    {systemAccounts
                        ?.filter((x) => x.currency === currency)
                        .map((o) => (
                            <MenuItem key={o._id} value={o._id}>
                                {o.accountingNumber} - {o.nickname}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <FormControl className={classes.input}>
                <InputLabel>To</InputLabel>
                <Select
                    label="to"
                    value={to}
                    onChange={(e) => setTo(e.target.value as AccountNumber)}
                    className={classes.input}
                >
                    {systemAccounts
                        ?.filter((x) => x.currency === currency)
                        .map((o) => (
                            <MenuItem key={o._id} value={o._id}>
                                {o.accountingNumber} - {o.nickname}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <TextField
                label="Amount"
                variant="outlined"
                value={amount + ''}
                onChange={updateAmount}
                className={classes.input}
            />

            <TextField
                label="Note"
                variant="outlined"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className={classes.input}
            />

            <Button variant="contained" className={classes.input} onClick={submit}>
                Submit
            </Button>
        </div>
    )
}
