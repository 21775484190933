import replaceWithEqual from '../../object/replaceWithEqual'
import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class ReplaceWithEqual<V extends Record<string, any>, E = never, C = never> extends Pass<V, E, C, V> {
    private _lastValue?: V
    override value(value: V): void {
        this._lastValue = replaceWithEqual<V>(value, this._lastValue)
        this._value(this._lastValue!)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        replaceWithEqual(): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.replaceWithEqual = function <SV extends Record<string, any>, SE, SC>(
    this: AnySourceStream<SV, SE, SC>
) {
    return new ReplaceWithEqual(this) // tslint:disable-line:no-invalid-this
}
