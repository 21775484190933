import { getCustomizedMessage } from './getCustomizedMessage'
import { FieldValidation, FieldValidationArgs, ValidationMessage } from './types'

export default function lessOrEqual<O = any>(
    lessOrEqualValue: number | ((args: FieldValidationArgs<number | undefined, O>) => number),
    customMessage?: ValidationMessage
): FieldValidation<number | undefined, O> {
    return {
        message: getCustomizedMessage(customMessage, 'auth.latencyAllowedShouldNotBeGraterThanExamDuration', {
            lessOrEqualValue,
        }),
        validate: (args) => {
            if (args.value === undefined) {
                return false
            }
            let n: number

            if (typeof lessOrEqualValue === 'function') {
                n = lessOrEqualValue(args)
            } else {
                n = lessOrEqualValue
            }

            return args.value <= n
        },
    }
}
