export function isEqualShallow(objA?: any, objB?: any) {
    if (objA == objB) {
        return true
    } // tslint:disable-line:triple-equals

    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
        return false
    }

    if (objA instanceof Array && objB instanceof Array) {
        const l = objA.length
        if (l !== objB.length) {
            return false
        }

        for (let i = 0; i < l; i++) {
            if (objA[i] !== objB[i]) {
                return false
            }
        }
        return true
    }

    if (objA instanceof Array || objB instanceof Array) {
        return false
    }

    const keysA = Object.keys(objA)
    const keysB = Object.keys(objB)

    if (keysA.length !== keysB.length) {
        return false
    }

    // Test for A's keys different from B.
    const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB)
    for (const key of keysA) {
        if (!bHasOwnProperty(key) || objA[key] !== objB[key]) {
            return false
        }
    }

    return true
}
