import * as fs from 'fs/promises'

let config: Record<string, string> = {}
// create file if not exists
const loadConfigPromise = fs.readFile('./config.json', 'utf-8').catch(() => '{}').then((data) => {
    config = JSON.parse(data)
    saveConfig()
})
function saveConfig() {
    return fs.writeFile('./config.json', JSON.stringify(config))
}
// async storage for node
const changeListeners: Record<string, Array<(value: string | null) => void>> = {}
export const asyncStorage = {
    async get(key: string) {
        await loadConfigPromise
        return config[key]
    },

    async set(key: string, value: string) {
        await loadConfigPromise
        if (config[key] === value) {
            return
        }
        config[key] = value
        await saveConfig()
        changeListeners[key]?.forEach((fn) => fn(value))
    },

    async remove(key: string) {
        await loadConfigPromise
        if (config[key] === null || config[key] === undefined) {
            return
        }
        delete config[key]
        await saveConfig()
        changeListeners[key]?.forEach((fn) => fn(null))
    },

    async clear() {
        config = {}
        await saveConfig()
        Object.values(changeListeners).forEach((fn) => fn.forEach((f) => f(null)))
    },

    async setIfNotExists(key: string, value: string) {
        if (!asyncStorage.get(key)) {
            return asyncStorage.set(key, value)
        }
    },

    onchange(key: string, callback: (value: string | null) => void): () => void {
        changeListeners[key] = changeListeners[key] || []
        changeListeners[key].push(callback)
        return () => {
            changeListeners[key] = changeListeners[key].filter((c) => c !== callback)
        }
    },
}
