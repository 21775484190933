import * as openTelemetry from '@opentelemetry/api'
import * as Sentry from '@sentry/core'

export default function captureException(error: unknown) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const currentClient = typeof window === 'undefined' ? eval("require")('@hypatia/serverer-server/registry/client').getClient() : undefined
    Sentry.withScope((scope) => {
        if (currentClient) {
            scope.setUser({ id: currentClient.userId ?? currentClient.anonymousUserId })
            scope.setContext('client', {
                sessionId: currentClient.sessionId,
                anonymousUserId: currentClient.anonymousUserId,
                userId: currentClient.userId,
            })
        }
        Sentry.captureException(error)
    })
    const activeSpan = openTelemetry.trace.getActiveSpan()
    if (activeSpan) {
        activeSpan.setStatus({
            code: openTelemetry.SpanStatusCode.ERROR,
        })
        activeSpan.recordException(error as openTelemetry.Exception)
    }
}
