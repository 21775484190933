import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class BufferCount<V, E = never, C = never> extends Pass<V, E, C, V[]> {
    protected bufferQueue: V[] = []
    protected _count: number
    protected skipCount: number
    protected remainingSkip = 0

    constructor(count: number, skipCount = count, source?: AnySourceStream<V, E, C>) {
        super(source)
        this.skipCount = skipCount
        this._count = count
    }

    override value(value: V): void {
        if (this.remainingSkip !== 0) {
            this.remainingSkip--
            return
        }

        this.bufferQueue.push(value)
        if (this.bufferQueue.length === this._count) {
            this._value(this.bufferQueue)
            this.bufferQueue = this.bufferQueue.slice(this.skipCount)

            const rem = this.skipCount - this._count
            if (rem > 0) {
                this.remainingSkip = rem
            }
        }
    }

    override error(error: E): void {
        if (this.bufferQueue.length !== 0) {
            this._value(this.bufferQueue)
        }
        this._error(error)
    }

    override complete(complete: C): void {
        if (this.bufferQueue.length !== 0) {
            this._value(this.bufferQueue)
        }
        this._complete(complete)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        bufferCount(count: number, skipCount?: number): BufferCount<DV, DE, DC>
    }
}

Stream.prototype.bufferCount = function <DV, DE, DC>(
    this: AnySourceStream<DV, DE, DC>,
    count: number,
    skipCount?: number
): BufferCount<DV, DE, DC> {
    return new BufferCount(count, skipCount, this) // tslint:disable-line:no-invalid-this
}
