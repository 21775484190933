import all from './all'
import any from './any'
import atLeastXYearsOld from './atLeastXYearsOld'
import contain from './contain'
import custom from './custom'
import date from './date'
import dateAfter from './dateAfter'
import email from './email'
import equal from './equal'
import futureDate from './futureDate'
import greaterThan from './greaterThan'
import lessOrEqual from './lessOrEqual'
import lessThan from './lessThan'
import maxLength from './maxLength'
import minLength from './minLength'
import not from './not'
import oneOf from './oneOf'
import onlyArabicLetters from './onlyArabicLetters'
import onlyEnglishLetters from './onlyEnglishLetters'
import onlyDigits from './onlyDigits'
import phone from './phone'
import required from './required'
import validateField from './validateField'
import _if from './_if'

export default {
    all,
    any,
    custom,
    email,
    date,
    dateAfter,
    futureDate,
    equal,
    minLength,
    maxLength,
    required,
    validateField,
    if: _if,
    not,
    greaterThan,
    oneOf,
    lessThan,
    lessOrEqual,
    onlyDigits,
    onlyEnglishLetters,
    onlyArabicLetters,
    phone,
    atLeastXYearsOld,
    contain,
}
