import { AnySourceStream, AnyStream, AnySubscription, Stream, Subscription } from '../stream'

export class LastOn<V, E = never, C = never> extends Stream<V, E, C> {
    protected flushSubscription: AnySubscription = {
        value: () => {
            this.flush()
        },
        error: () => {
            this.flush()
            this.close()
        },
        complete: () => {
            this.flush()
            this.close()
        },
    }

    protected last: V | undefined
    protected receive = false

    private shouldComplete = false
    private completeValue: C | undefined = undefined
    private subscription: Subscription<V, E, C> = {
        value: this.value.bind(this),
        error: this.error.bind(this),
        complete: (completeValue) => {
            this.shouldComplete = true
            this.completeValue = completeValue
        },
    }

    constructor(protected signal: AnyStream, protected source?: AnySourceStream<V, E, C>) {
        super()
        this.signal = signal
    }

    override connect(): void {
        super.connect()
        if (this.source) {
            this.source.subscribe(this.subscription)
        }

        this.signal.subscribe(this.flushSubscription)
    }

    override disconnect(): void {
        super.disconnect()

        if (this.source) {
            this.source.unsubscribe(this.subscription)
        }

        this.signal.unsubscribe(this.flushSubscription)
    }

    flush() {
        if (this.receive) {
            this._value(this.last!)
        }
        this.receive = false

        if (this.shouldComplete) {
            this.complete(this.completeValue!)
        }
    }

    override value(value: V): void {
        this.receive = true
        this.last = value
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        lastOn(signal: AnyStream): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.lastOn = function <V, E, C>(this: AnySourceStream<V, E, C>, signal: AnyStream) {
    return new LastOn(signal, this) // tslint:disable-line:no-invalid-this
}
