import { ResetPinCodeRequestId } from '@cashii/common/src/db/db'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import { Button } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'
import React, { useCallback, useEffect, useMemo } from 'react'
import { CustomToolbar } from '../../components/CustomToolbar'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import { OpenInWindow } from '../VerifyUsersIds/OpenInWindow'
import classes from './ResetPinRequestsPage.module.css'

export default React.memo(ResetPinRequestsPage)

type ResetPinCodeRequestType = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_resetPinRequestsList>
function ResetPinRequestsPage(): JSX.Element {
    const [_requests] = useDeliverLast(() => clientLiveQueries.controlPanel_resetPinRequestsList(), [])
    const [requests, setRequests] = React.useState<ResetPinCodeRequestType[] | null>(null)

    useEffect(() => {
        if (_requests) {
            setRequests(_requests)
        }
    }, [_requests])

    const alert = useAlertNotification()

    const approve = useCallback(
        async (resetPinCodeRequestId: ResetPinCodeRequestId) => {
            const [err] = await clientUpdates.controlPanel.approveResetPinRequest(resetPinCodeRequestId)
            if (err) {
                alert({
                    variant: 'error',
                    alertText: err,
                })
                return
            }
            alert({
                variant: 'success',
                alertText: 'Approved',
            })
        },
        [alert]
    )

    const reject = useCallback(
        async (resetPinCodeRequestId: ResetPinCodeRequestId) => {
            const [err] = await clientUpdates.controlPanel.rejectResetPinRequest(resetPinCodeRequestId)
            if (err) {
                alert({
                    variant: 'error',
                    alertText: err,
                })
                return
            }
            alert({
                variant: 'success',
                alertText: 'Rejected',
            })
        },
        [alert]
    )

    const getRowId = useCallback((row: ResetPinCodeRequestType) => row._id, [])
    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            {
                field: 'userId',
                headerName: 'User Id',
                editable: true,
                type: 'string',
            },
            { field: 'createdAt', headerName: 'Created At', type: 'datetime' },
            { field: 'status', headerName: 'Status', type: 'singleSelect', valueOptions: ['pending', 'approved', 'rejected'] },
            {
                field: 'idPhotos',
                headerName: 'Id Photos',
                renderCell: (params) =>
                    params.row.idPhotos ? (
                        <>
                            <OpenInWindow href={params.row.idPhotos.front}>Front</OpenInWindow>
                            <OpenInWindow href={params.row.idPhotos.back}>back</OpenInWindow>
                        </>
                    ) : null,
            },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 200,
                renderCell: ({ row }) => (
                    <div>
                        {row.status === 'pending' ? (
                            <>
                                <Button onClick={() => approve(row._id)} color="primary">
                                    Approve
                                </Button>
                                <Button onClick={() => reject(row._id)} color="primary">
                                    Reject
                                </Button>
                            </>
                        ) : null}
                    </div>
                ),
            },
        ] as GridColDef<ResetPinCodeRequestType>[]
    }, [approve, reject])

    const processRowUpdate = useCallback(
        async (newRow: ResetPinCodeRequestType) => {
            alert({
                variant: 'error',
                alertText: 'Cannot update request',
            })
            // const [err] = await clientUpdates.controlPanel.updateResetPinCodeRequestType(newRow._id, omit(newRow, '_id'))
            // if (err) {
            //     throw err
            // }
            // alert({
            //     variant: 'success',
            //     alertText: 'ResetPinCodeRequestType updated',
            // })
            return newRow
        },
        [alert]
    )

    const handleProcessRowUpdateError = useCallback(
        (error: string) => {
            alert({
                variant: 'error',
                alertText: error,
            })
        },
        [alert]
    )

    if (!requests) {
        return <div>Loading...</div>
    }
    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="resetPinRequests"
                components={{
                    Toolbar: CustomToolbar,
                }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                className={classes.table}
                getRowId={getRowId}
                rows={requests}
                columns={columns}
            />
        </div>
    )
}
