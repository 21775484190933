import {
    AccountNumber,
    Currency,
    FeeableOperation,
    FeeableOperationReceiverSide,
    FeeableOperationSenderSide,
    FeeableOperations,
    FeeableOperationsAllGroupsReceiverSide,
    FeeableOperationsAllGroupsSenderSide,
    FeeableSide,
    GroupId,
    Money,
    PerThousand
} from '@cashii/common/src/db/db'
import clientInserts from '@cashii/common/src/queries/clientInserts'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import defined from '@hypatia/utils/object/defined'
import { Button } from '@mui/material'
import exceljs from 'exceljs'
import React, { useCallback } from 'react'
import classes from './FeesPage.module.css'

export default React.memo(FeesPage)

function FeesPage(): JSX.Element {
    const [fees] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllFees(), [])
    const [groups] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllGroups(), [])

    const downloadFees = useCallback(() => {
        const workbook = new exceljs.Workbook()
        const worksheet = workbook.addWorksheet('Fees')

        worksheet.columns = [
            { header: 'Operation', key: 'operation', width: 20 },
            { header: 'Side', key: 'side', width: 20 },
            { header: 'Currency', key: 'currency', width: 20 },

            { header: 'Sender Group', key: 'senderGroup', width: 20 },
            { header: 'Receiver Group', key: 'receiverGroup', width: 20 },

            { header: 'Fee Percentage *', key: 'feePercentage', width: 20 },
            { header: 'Minimum Fee *', key: 'minimumFee', width: 20 },
            { header: 'Maximum Fee', key: 'maximumFee', width: 20 },
            { header: 'Min Limit Per Operation', key: 'minLimitPerOperation', width: 20 },
            { header: 'Max Limit Per Operation', key: 'maxLimitPerOperation', width: 20 },
            { header: 'Max Limit Per Day', key: 'maxLimitPerDay', width: 20 },
            { header: 'Cashback Percentage *', key: 'cashbackPercentage', width: 20 },
            { header: 'Max Daily Cashback', key: 'maxDailyCashback', width: 20 },
            { header: 'Fee Account *', key: 'feeAccount', width: 20 },
            { header: 'Cashback Account *', key: 'cashbackAccount', width: 20 },
        ]

        const operations = Object.values(FeeableOperations)
        const sides = Object.values(FeeableSide)
        const currencies = ['USD', 'TRY']

        const senderGroups = ['default', ...groups!.map((group) => group._id)]
        const receiverGroups = ['default', ...groups!.map((group) => group._id)]

        for (const operation of operations) {
            for (const side of sides) {
                for (const currency of currencies) {
                    for (const senderGroup of senderGroups) {
                        for (const receiverGroup of receiverGroups) {
                            const fee = fees!.find((f) => {
                                return (
                                    f.operation === operation &&
                                    f.side === side &&
                                    f.currency === currency &&
                                    f.senderGroup === senderGroup &&
                                    f.receiverGroup === receiverGroup
                                )
                            })
                            if (side === 'sender' && !FeeableOperationSenderSide.includes(operation as any)) {
                                continue
                            }
                            if (side === 'receiver' && !FeeableOperationReceiverSide.includes(operation as any)) {
                                continue
                            }

                            if (side === 'sender' && receiverGroup !== 'default' && !FeeableOperationsAllGroupsSenderSide.includes(operation as any)) {
                                continue
                            }
                            if (side === 'receiver' && senderGroup !== 'default' && !FeeableOperationsAllGroupsReceiverSide.includes(operation as any)) {
                                continue
                            }
                            worksheet.addRow({
                                operation,
                                side,
                                currency,
                                senderGroup,
                                receiverGroup,
                                feePercentage: fee?.feePercentage,
                                minimumFee: fee?.minimumFee,
                                maximumFee: fee?.maximumFee,
                                minLimitPerOperation: fee?.minLimitPerOperation,
                                maxLimitPerOperation: fee?.maxLimitPerOperation,
                                maxLimitPerDay: fee?.maxLimitPerDay,
                                cashbackPercentage: fee?.cashbackPercentage,
                                maxDailyCashback: fee?.maxDailyCashback,
                                feeAccount: fee?.feeAccount,
                                cashbackAccount: fee?.cashbackAccount,
                            })
                        }
                    }
                }
            }
        }

        // add all operations x sides x currencies x senderGroups x receiverGroups
        // worksheet.addRows()
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'fees.xlsx'
            a.click()
        })
    }, [fees, groups])

    const uploadFees = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0]
            event.target.value = ''
            if (!file) {
                return
            }
            const reader = new FileReader()
            reader.onload = async (event) => {
                const data = event.target?.result
                if (!data) {
                    return
                }
                const workbook = new exceljs.Workbook()
                await workbook.xlsx.load(data as ArrayBuffer)
                const worksheet = workbook.getWorksheet(1)
                const rows = worksheet.getRows(2, worksheet.rowCount)!

                const errors: string[] = []
                function ensureInt(name: string, value: any, row: number, column: number, allowUndefined = false): number | undefined {
                    const int = parseInt(value, 10)
                    if (allowUndefined && (value === undefined || value === null)) {
                        return undefined
                    }
                    if (isNaN(int) || int != value) {
                        errors.push(`Invalid name: "${name}" value: "${value}" at row "${row}" column "${column}"`)
                        return 0
                    }
                    return int
                }

                function ensureAccountNumber(name: string, value: any, row: number, column: number): string {
                    if (typeof value !== 'string' || value.trim().length !== 8) {
                        errors.push(`Invalid name: "${name}" value: "${value}" at row "${row}" column "${column}"`)
                        return ''
                    }
                    return value.trim()
                }

                function ensureOperation(name: string, value: any, row: number, column: number): FeeableOperation {
                    if (!FeeableOperations.includes(value)) {
                        errors.push(`Invalid name: "${name}" value: "${value}" at row "${row}" column "${column}"`)
                    }
                    return value
                }

                function ensureSide(name: string, value: any, row: number, column: number): FeeableSide {
                    if (!FeeableSide.includes(value)) {
                        errors.push(`Invalid name: "${name}" value: "${value}" at row "${row}" column "${column}"`)
                    }
                    return value
                }

                function ensureCurrency(name: string, value: any, row: number, column: number): Currency {
                    if (!['USD', 'TRY'].includes(value)) {
                        errors.push(`Invalid name: "${name}" value: "${value}" at row "${row}" column "${column}"`)
                    }
                    return value
                }

                function ensureGroup(name: string, value: any, row: number, column: number): GroupId {
                    if (value === 'default') {
                        return 'default' as GroupId
                    }
                    if (!groups?.find((group) => group._id === value)) {
                        errors.push(`Invalid name: "${name}" value: "${value}" at row "${row}" column "${column}"`)
                    }
                    return value
                }

                const fees = rows
                    .map((row, idx) => {
                        if (row.getCell(1).value == null) {
                            return undefined
                        }
                        return {
                            operation: ensureOperation("operation", row.getCell(1).value, idx + 1, 1) as FeeableOperation,
                            side: ensureSide("side", row.getCell(2).value, idx + 1, 2) as FeeableSide,
                            currency: ensureCurrency("currency", row.getCell(3).value, idx + 1, 3) as Currency,
                            senderGroup: ensureGroup("senderGroup", row.getCell(4).value, idx + 1, 4) as GroupId,
                            receiverGroup: ensureGroup("receiverGroup", row.getCell(5).value, idx + 1, 5) as GroupId,
                            feePercentage: ensureInt("feePercentage", row.getCell(6).value, idx + 1, 6) as PerThousand,
                            minimumFee: ensureInt("minimumFee", row.getCell(7).value, idx + 1, 7) as Money,
                            maximumFee: ensureInt("maximumFee", row.getCell(8).value, idx + 1, 8, true) as Money,
                            minLimitPerOperation: ensureInt("minLimitPerOperation", row.getCell(9).value, idx + 1, 9, true) as Money,
                            maxLimitPerOperation: ensureInt("maxLimitPerOperation", row.getCell(10).value, idx + 1, 10, true) as Money,
                            maxLimitPerDay: ensureInt("maxLimitPerDay", row.getCell(11).value, idx + 1, 11, true) as Money,
                            cashbackPercentage: ensureInt("cashbackPercentage", row.getCell(12).value, idx + 1, 12) as PerThousand,
                            maxDailyCashback: ensureInt("maxDailyCashback", row.getCell(13).value, idx + 1, 13, true) as Money,
                            feeAccount: ensureAccountNumber("feeAccount", row.getCell(14).value, idx + 1, 14) as AccountNumber,
                            cashbackAccount: ensureAccountNumber("cashbackAccount", row.getCell(15).value, idx + 1, 15) as AccountNumber,
                        }
                    })
                    .filter(defined)
                if (errors.length > 0) {
                    alert(errors.join('\n'))
                    console.log(errors)
                    console.log(fees)
                    return
                }

                console.log(fees)
                const [err] = await clientInserts.controlPanel.setAllFees(fees)
                if (err) {
                    alert(err)
                    return
                }
                alert('Fees uploaded successfully')
                window.location.reload()
            }
            reader.readAsArrayBuffer(file)
        },
        [groups]
    )

    if (!fees) {
        return <div>Loading...</div>
    }
    return (
        <div className={classes.container}>
            <Button onClick={downloadFees}>Download</Button>
            <input type="file" onChange={uploadFees} />
        </div>
    )
}
