/* eslint-disable react/jsx-no-useless-fragment */
import { createContext, useContext } from './useContextSelector'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Fragment from './Fragment'

interface Props {
    fallback: NonNullable<React.ReactNode> | null
    children: React.ReactNode
    className?: string
    fragmentStyle?: any
    loadingFragmentStyle?: any
    contentFragmentStyle?: any
    dontHideContent?: boolean
}

export const SoftSuspenseContext = createContext<(promise: Promise<any>) => void>()
export const useSuspenseFn = () => useContext(SoftSuspenseContext)

export const useSuspense = (promise: Promise<any>, deps: any[]) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoPromise = useMemo(() => promise, deps)
    const lastMemoPromise = useRef<Promise<any>>()
    const suspense = useContext(SoftSuspenseContext)

    if (lastMemoPromise.current !== memoPromise) {
        suspense(memoPromise)
        lastMemoPromise.current = memoPromise
    }
}

export const useSuspenseBoolean = (shouldSuspense: boolean) => {
    const suspense = useContext(SoftSuspenseContext)
    const isMounted = useRef(false)
    const resolveRef = useRef<() => void>()
    const promiseRef = useRef<Promise<any>>()
    const suspended = useRef(false)

    shouldSuspense = Boolean(shouldSuspense)
    if (suspended.current !== shouldSuspense) {
        suspended.current = shouldSuspense
        if (!shouldSuspense) {
            resolveRef.current!()
        } else if (!promiseRef.current) {
            promiseRef.current = new Promise<void>((resolve) => {
                resolveRef.current = resolve
            })
            suspense(promiseRef.current)

            // un suspense after 1 second if not mounted
            setTimeout(() => {
                if (!isMounted.current) {
                    resolveRef.current!()
                }
            }, 1000)
        }
    }

    useEffect(() => {
        isMounted.current = true
        return () => {
            resolveRef.current?.()
        }
    }, [])
}

const SoftSuspense = (props: Props) => {
    const {
        fallback,
        children,
        fragmentStyle,
        className,
        dontHideContent,
        contentFragmentStyle,
        loadingFragmentStyle,
    } = props

    const [status, setStatus] = useState<'pending' | 'resolved'>('resolved')
    const lastPromise = useRef(Promise.resolve())

    const suspense = useMemo(
        () => (promise: Promise<any>) => {
            if (process.env.NODE_ENV === 'development') {
                // console.info('suspending...')
            }
            setTimeout(() => {
                setStatus('pending')
                const newPromise = lastPromise.current.finally(() => promise)
                lastPromise.current = newPromise
                newPromise.finally(() => {
                    if (lastPromise.current === newPromise) {
                        if (process.env.NODE_ENV === 'development') {
                            // console.info('suspending resolved')
                        }
                        setStatus('resolved')
                    }
                })
            }, 0)
        },
        []
    )

    return (
        <>
            <Fragment
                className={className}
                hidden={status === 'pending' && !dontHideContent}
                style={status === 'pending' && !dontHideContent ? undefined : contentFragmentStyle ?? fragmentStyle}
            >
                <SoftSuspenseContext.Provider value={suspense}>{children}</SoftSuspenseContext.Provider>
            </Fragment>

            <Fragment
                className={className}
                hidden={status !== 'pending'}
                style={status !== 'pending' ? undefined : loadingFragmentStyle ?? fragmentStyle}
            >
                {status === 'pending' ? fallback : null}
            </Fragment>
        </>
    )
}

export default SoftSuspense
