export default function getServerUrl() {
    if (process.env.SERVER_URL) {
        return process.env.SERVER_URL
    }

    if (typeof window !=='undefined' && window.location?.host) {
        return `https://server.${window.location.host.split('.').slice(-2).join('.')}/`
    }

    throw new Error('unable to find the server url')
}
