import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Delay<V, E = never, C = never> extends Pass<V, E, C> {
    constructor(protected override source?: AnySourceStream<V, E, C>, protected delayTimeout?: number) {
        super(source)
    }

    override value(value: V): void {
        setTimeout(() => {
            this._value(value)
        }, this.delayTimeout)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        delay(delay: number): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.delay = function <V, E, C>(this: AnySourceStream<V, E, C>, delay: number) {
    return new Delay(this, delay) // tslint:disable-line:no-invalid-this
}
