// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BDGzFHh2iPyuZP1nCN1I {\n    direction: ltr;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n    padding: 48px;\n}\n\n.X8yoG0wqTNU8QY4MgBUk {\n    width: 350px;\n    margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ManualOperation2/ManualOperationPage.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".container {\n    direction: ltr;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n    padding: 48px;\n}\n\n.input {\n    width: 350px;\n    margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BDGzFHh2iPyuZP1nCN1I",
	"input": "X8yoG0wqTNU8QY4MgBUk"
};
export default ___CSS_LOADER_EXPORT___;
