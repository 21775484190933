import { Dispatch, SetStateAction, useEffect, useState } from "react"

export default function useLocalStorageState<S>(stateName: string, initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] {
    const [state, setState] = useState(() => {
        // @ts-ignore
        const item = window.localStorage.getItem(stateName)
        if (item) {
            return JSON.parse(item)
        }
        return typeof initialState === 'function' ? (initialState as () => S)() : initialState
    })

    useEffect(() => {
        // @ts-ignore
        window.localStorage.setItem(stateName, JSON.stringify(state))
    }, [state, stateName])

    return [state, setState]
}
