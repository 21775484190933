// TODO: lazy load the languages
import { useContext } from '../hooks/useContextSelector'
import i18n from 'i18next'
import moment from 'moment'
import 'moment/locale/ar-ly'
import 'moment/locale/tr'
import React, { useEffect } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { SettingsContext } from './SettingsContext'

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    fallbackNS: 'default',
    lng: 'en',
    interpolation: {
        escapeValue: false, // react already safes from xss
        format: function (value, format) {
            // if (format === 'uppercase') return value.toUpperCase();
            if (value instanceof Date) {
                return moment(value).format(format)
            }
            return value
        },
    },
})

interface Props {
    children: React.ReactNode
}

export default function LocaleProvider({ children }: Props): JSX.Element {
    const [{ locale }] = useContext(SettingsContext)

    useEffect(() => {
        i18n.changeLanguage(locale)
        moment.locale(locale === 'ar' ? 'ar-ly' : locale)
    }, [locale])

    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
