import clientInserts from '@cashii/common/src/queries/clientInserts'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid-premium'
import { omit } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { CustomToolbar } from '../../components/CustomToolbar'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import classes from './SystemUsersPage.module.css'

function EditToolbar() {
    const _alert = useAlertNotification()
    const handleClick = async () => {
        const username = prompt('Enter username')!
        if (!username) {
            return
        }
        const [err, data] = await clientInserts.controlPanel.insertSystemUser({
            username,
        })

        if (err) {
            _alert({
                variant: 'error',
                alertText: err.message,
            })
            return
        }

        console.log('username: ' + username + '\n password: ' + data!.password + '\n otpSecret: ' + data!.otpSecret)
    }

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    )
}

export default React.memo(SystemUsersPage)

type User = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getSystemUsers>
// eslint-disable-next-line react/no-multi-comp
function SystemUsersPage(): JSX.Element {
    const [_users] = useDeliverLast(() => clientLiveQueries.controlPanel_getSystemUsers(), [])
    const [users, setUsers] = React.useState<User[] | null>(null)

    useEffect(() => {
        if (_users) {
            setUsers(_users)
        }
    }, [_users])

    const alert = useAlertNotification()

    const getRowId = useCallback((row: User) => row._id, [])
    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            {
                field: 'username',
                headerName: 'Username',
                editable: true,
                type: 'string',
            },
            {
                field: 'disabled',
                headerName: 'Disabled',
                editable: true,
                type: 'boolean',
            },
            ...(
                [
                    'verifyUsersIds',
                    'users',
                    'systemUsers',
                    'accounts',
                    'transactions',
                    'movements',
                    'markets',
                    'offers',
                    'groups',
                    'groupNotifications',
                    'packages',
                    'manualOperation',
                    'businessActivityTypes',
                    'systemAccounts',
                    'cashMachines',
                    'cashMachinesManagement',
                    'feesManagement',
                    'resetPinRequests',
                    'cityManagement',
                ] as const
            ).map(
                (key) =>
                    ({
                        field: 'systemPermissions.' + key,
                        headerName: key,
                        editable: true,
                        type: 'boolean',
                        valueGetter: ({ row }) => row.systemPermissions?.[key],
                        valueSetter: ({ row, value }) => {
                            row.systemPermissions = {
                                ...row.systemPermissions,
                                [key]: value,
                            }
                            return row
                        },
                    } as GridColDef<User>)
            ),
        ] as GridColDef<User>[]
    }, [])

    const processRowUpdate = useCallback(
        async (newRow: User) => {
            const [err] = await clientUpdates.controlPanel.updateUser(newRow._id, omit(newRow, '_id'))
            if (err) {
                throw err
            }
            alert({
                variant: 'success',
                alertText: 'User updated',
            })
            return newRow
        },
        [alert]
    )

    const handleProcessRowUpdateError = useCallback(
        (error: string) => {
            alert({
                variant: 'error',
                alertText: error,
            })
        },
        [alert]
    )

    if (!users) {
        return <div>Loading...</div>
    }
    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="systemUsers"
                components={{
                    Toolbar: CustomToolbar,
                }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                className={classes.table}
                getRowId={getRowId}
                rows={users}
                columns={columns}
                slots={{
                    toolbar: EditToolbar,
                }}
            />
        </div>
    )
}
