import { useGlobalLoading } from '@hypatia/react-utils/connect/GlobalLoadingProvider'
import React from 'react'

export default React.memo(GlobalLoadingSupplier)

interface Props {
    children: React.ReactNode
}
function GlobalLoadingSupplier(props: Props): JSX.Element {
    const { loading } = useGlobalLoading()

    return (
        <>
            {props.children}
            {loading && <p>{"Loading..."}</p>}
        </>
    )
}
