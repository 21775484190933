import 'i18next'
import i18n from 'i18next'
import ar from './ar'
import moment from 'moment'

i18n.init({
    fallbackLng: 'ar',
    lng: 'ar',
    compatibilityJSON: 'v3',
    interpolation: {
        escapeValue: false,
        format: function (value, format) {
            // if (format === 'uppercase') return value.toUpperCase();
            if (value instanceof Date) {
                return moment(value).format(format)
            }
            return value
        },
    }
})

i18n.addResourceBundle('ar', 'default', ar)
i18n.addResourceBundle('ar', 'translation', ar)

declare module 'i18next' {
    interface CustomTypeOptions {
        defaultNS: 'translation'
        resources: {
            translation: typeof ar
        }
    }
}
