import { useUser } from '@hypatia/react-utils/containers/useUser'
import React from 'react'
import { A } from './A'
import classes from './HomePage.module.css'

export default React.memo(HomePage)
function HomePage(): JSX.Element {
    const { user } = useUser()

    return (
        <div>
            <ul className={classes.list}>
                {user?.systemPermissions?.verifyUsersIds && (
                    <li>
                        <A href="/verify-users-ids">Verify Users Ids</A>
                    </li>
                )}
                {user?.systemPermissions?.users && (
                    <li>
                        <A href="/users">Users</A>
                    </li>
                )}
                {user?.systemPermissions?.systemUsers && (
                    <li>
                        <A href="/system-users">System Users</A>
                    </li>
                )}
                {user?.systemPermissions?.accounts && (
                    <li>
                        <A href="/accounts">Accounts</A>
                    </li>
                )}
                {user?.systemPermissions?.transactions && (
                    <li>
                        <A href="/transactions">Transactions</A>
                    </li>
                )}
                {user?.systemPermissions?.movements && (
                    <li>
                        <A href="/movements">Movements</A>
                    </li>
                )}
                {user?.systemPermissions?.markets && (
                    <li>
                        <A href="/markets">Markets</A>
                    </li>
                )}
                {user?.systemPermissions?.offers && (
                    <li>
                        <A href="/offers">Offers</A>
                    </li>
                )}
                {user?.systemPermissions?.groups && (
                    <li>
                        <A href="/groups">Groups</A>
                    </li>
                )}
                {user?.systemPermissions?.cityManagement && (
                    <li>
                        <A href="/city-management">City Management</A>
                    </li>
                )}
                {user?.systemPermissions?.businessActivityTypes && (
                    <li>
                        <A href="/business-activity-types">Business Activity Types</A>
                    </li>
                )}
                {user?.systemPermissions?.businessActivityTypes && (
                    <li>
                        <A href="/sub-business-activity-types">Sub Business Activity Types</A>
                    </li>
                )}
                {user?.systemPermissions?.groupNotifications && (
                    <li>
                        <A href="/group-notifications">Group Notifications</A>
                    </li>
                )}
                {user?.systemPermissions?.packages && (
                    <li>
                        <A href="/packages">Packages</A>
                    </li>
                )}
                {user?.systemPermissions?.manualOperation && (
                    <li>
                        <A href="/manual-operation">Manual Operation</A>
                    </li>
                )}
                {user?.systemPermissions?.systemAccounts && (
                    <li>
                        <A href="/system-accounts">System Accounts</A>
                    </li>
                )}
                {user?.systemPermissions?.cashMachines && (
                    <li>
                        <A href="/cash-machines">Cash Machines</A>
                    </li>
                )}
                {user?.systemPermissions?.cashMachinesManagement && (
                    <li>
                        <A href="/cash-machines-management">Cash Machines Management</A>
                    </li>
                )}
                {user?.systemPermissions?.feesManagement && (
                    <li>
                        <A href="/fees-management">Fees Management</A>
                    </li>
                )}
                {user?.systemPermissions?.resetPinRequests && (
                    <li>
                        <A href="/reset-pin-requests">Reset Pin Requests</A>
                    </li>
                )}
                <li>
                    <A href="/cashii-icons/demo.html">Cashii Icons</A>
                </li>
                <li>
                    <A href="https://icons.expo.fyi/">Material Icons</A>
                </li>
            </ul>
        </div>
    )
}
