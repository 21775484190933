import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import { GridColDef } from '@mui/x-data-grid-premium'
import React, { useCallback, useMemo } from 'react'
import { CustomToolbar } from '../../components/CustomToolbar'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import classes from './MovementsPage.module.css'

export default React.memo(MovementsPage)

type Movement = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getAllMovements>
function MovementsPage(): JSX.Element {
    const [movements] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllMovements(), [])

    const getRowId = useCallback((row: Movement) => row._id, [])
    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            { field: 'createdAt', headerName: 'Created At' },
            { field: 'executedAt', headerName: 'Executed At' },
            { field: 'amount', headerName: 'Amount', type: 'number', valueGetter: (doc) => doc.row.amount / 100 },
            { field: 'accountId', headerName: 'Account Id' },
            { field: 'transactionId', headerName: 'Transaction Id' },
        ] as GridColDef<Movement>[]
    }, [])

    if (!movements) {
        return <div>Loading...</div>
    }
    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="movements"
                className={classes.table}
                components={{
                    Toolbar: CustomToolbar,
                }}
                initialState={{
                    aggregation: {
                        model: {
                            amount: 'sum',
                        },
                    },
                }}
                getRowId={getRowId}
                rows={movements}
                columns={columns}
            />
        </div>
    )
}
