export function getAtmMessage() {
    return (
        messagesAccordingToTime.find((x) => {
            const now = new Date()
            const minHour = new Date()
            const maxHour = new Date()
            minHour.setHours(parseInt(x.minHour.split(':')[0]))
            minHour.setMinutes(parseInt(x.minHour.split(':')[1]))
            maxHour.setHours(parseInt(x.maxHour.split(':')[0]))
            maxHour.setMinutes(parseInt(x.maxHour.split(':')[1]))
            return now >= minHour && now <= maxHour
        })?.message || 'يسعد مساك 👋'
    )
}
const messagesAccordingToTime = [
    // { minHour: '00:00', maxHour: '24:00', message: 'عيدك سعيد 🌙' },
    { minHour: '00:00', maxHour: '04:00', message: 'يسعد مساك 👋' },
    { minHour: '04:00', maxHour: '11:30', message: 'يسعد صباحك 👋' },
    { minHour: '11:30', maxHour: '18:00', message: 'نهارك سعيد 👋' },
    { minHour: '18:00', maxHour: '24:00', message: 'يسعد مساك 👋' },
]
