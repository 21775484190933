/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, useMemo, useRef } from 'react'
import { useEffectRightNow } from '../useEffectRightNow'
import { useStateWaitForMounting } from '../useStateWaitForMounting'

export type UseAsyncResult<V, E> = [undefined, true, undefined] | [V, false, undefined] | [undefined, false, E]
interface UseAsyncOptions {
    dontThrow?: boolean
}

export default function useAsync<V, E>(
    promiseGetter: Promise<V> | (() => Promise<V> | undefined) | undefined,
    deps: DependencyList
): UseAsyncResult<V, E>

export default function useAsync<V, E>(
    promiseGetter: Promise<V> | (() => Promise<V> | undefined) | undefined,
    loadingList: boolean[],
    deps: DependencyList
): UseAsyncResult<V, E>

export default function useAsync<V, E>(
    promiseGetter: Promise<V> | (() => Promise<V> | undefined) | undefined,
    options: UseAsyncOptions,
    loadingList: boolean[],
    deps: DependencyList
): UseAsyncResult<V, E>

export default function useAsync<V, E>(
    promiseGetter: Promise<V> | (() => Promise<V> | undefined) | undefined,
    second: boolean[] | DependencyList | UseAsyncOptions,
    third?: boolean[] | DependencyList,
    forth?: DependencyList
): UseAsyncResult<V, E> {
    let loadingList: boolean[] = []
    let deps: DependencyList
    let options: UseAsyncOptions = {}

    if (forth) {
        loadingList = third as boolean[]
        deps = forth as DependencyList
        options = second as UseAsyncOptions
    } else if (third) {
        loadingList = second as boolean[]
        deps = third as DependencyList
    } else {
        deps = second as DependencyList
    }

    const waitingLoadingList = loadingList.some((x) => x)

    const shouldWatchStreamGetter = promiseGetter === undefined || promiseGetter instanceof Promise

    const [, setForceRender] = useStateWaitForMounting(0)

    const resultRef = useRef<UseAsyncResult<V, E>>([undefined, true, undefined])

    const promise = useMemo(() => {
        if (waitingLoadingList || !promiseGetter) {
            return undefined
        }
        if (promiseGetter instanceof Promise) {
            return promiseGetter
        }
        return promiseGetter()
    }, [...deps, waitingLoadingList, shouldWatchStreamGetter ? promiseGetter : undefined])

    useEffectRightNow(() => {
        let stopped = false

        if (!promise) {
            updateResult([undefined, true, undefined])
            return
        }

        function updateResult(result: UseAsyncResult<V, E>) {
            if (stopped) {
                return
            }
            resultRef.current = result
            setForceRender((x) => x + 1)
        }

        updateResult([undefined, true, undefined])

        promise
            .then((r) => {
                updateResult([r, false, undefined])
            })
            .catch((e) => {
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.warn(e)
                }
                if (options.dontThrow) {
                    updateResult([undefined, false, e])
                } else {
                    setForceRender(() => {
                        throw e
                    })
                }
            })

        return () => {
            stopped = true
        }
    }, [promise])

    return resultRef.current
}
