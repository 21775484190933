import { getAnonymousId } from '@hypatia/react-utils/utils/getAnonymousId'
import { getDeviceId } from '@hypatia/react-utils/utils/getDeviceId'
import isReactNative from '@hypatia/utils/os/isReactNative'
import getStoredToken from '@hypatia/serverer-client/auth/getStoredToken'
import logout from '@hypatia/serverer-client/auth/logout'
import { Client } from '@hypatia/serverer-common/Client'
import { RPCRequest } from '@hypatia/serverer-common/rpc/RPCRequest'
import { ServererFrontend } from '@hypatia/serverer-common/rpc/ServererFrontend'
import { sessionId } from '@hypatia/serverer-common/sessionId'
import * as msgPackParser from '@hypatia/serverer-common/utils/msgPackParser'
import * as msgPackParserBase64 from '@hypatia/serverer-common/utils/msgPackParserBase64'
import { asyncStorage } from '@hypatia/utils/async-storage'
import generateUniqueId from '@hypatia/utils/generators/generateUniqueId'
import { io, Socket } from 'socket.io-client'
import { BaseSocketServererFrontend } from './BaseSocketServererFrontend'
import connectionStatus$ from './connectionStatus$'

const initialHref = typeof window !== 'undefined' ? window.location?.href : ''
export default class WebSocketServererFrontend extends BaseSocketServererFrontend implements ServererFrontend {
    constructor(public readonly client: Promise<Client>, public readonly SERVER_URL: string) {
        super()
        getStoredToken().then((token) => {
            this.createSocket(token || null)
        })
    }

    socket: Socket | undefined
    usedToken: string | undefined | null = undefined

    override async send(req: RPCRequest): Promise<void> {
        const currentToken = await getStoredToken()
        if (this.usedToken !== currentToken) {
            await this.createSocket(currentToken || null)
        }

        await super.send(req)
    }

    emit(req: RPCRequest): void {
        this.socket!.emit('message', req)
    }

    async createSocket(useToken: string | null): Promise<void> {
        if (this.usedToken === useToken) {
            return
        }
        this.isConnecting = true
        this.usedToken = useToken
        const deviceId = await getDeviceId()
        const anonymousId = await getAnonymousId()

        const newSocket = io(this.SERVER_URL, {
            parser: isReactNative() ? msgPackParserBase64 : msgPackParser,
            path: isReactNative() ? '/react-native/socket.io' : '/socket.io',
            // transports: ['websocket'],
            // upgrade: false,
            autoConnect: true,
            reconnection: true,
            reconnectionAttempts: Infinity, // default
            reconnectionDelay: 1000, // default
            reconnectionDelayMax: 2000, // 5000 is the default
            timeout: 75 * 1000,
            query: {
                h: generateUniqueId(),
            },
            auth: {
                token: this.usedToken,
                sessionId,
                deviceId,
                anonymousId,
                initialHref,
            },
            closeOnBeforeunload: false,
        })

        // on connection failure
        newSocket.on('connect_error', () => {
            connectionStatus$.value('offline')
        })

        if (this.socket) {
            const oldSocket = this.socket
            this.socket = newSocket
            setTimeout(() => {
                oldSocket.close()
            }, 60 * 1000)
        } else {
            this.socket = newSocket
        }

        newSocket.on('softConnected', async () => {
            if (newSocket !== this.socket) {
                return
            }
            newSocket.emit('client', await this.client)
            this.onConnect()
        })

        newSocket.on('reload', () => {
            location.reload()
        })

        newSocket.on('update-anonymous-id', (newAnonymousId: string) => {
            asyncStorage.set('opx-anonymous-id', newAnonymousId)
        })

        newSocket.on('unauthenticated', () => {
            logout()
        })

        newSocket.on('disconnect', () => {
            if (newSocket !== this.socket) {
                return
            }
            this.onDisconnect()
        })

        newSocket.on('message', this.onMessage)
    }
}
