/* eslint-disable react-hooks/exhaustive-deps */
import { DeliverLast } from '@hypatia/utils/streams/operators/deliver-last'
import { Stream } from '@hypatia/utils/streams/stream'
import { DependencyList, useMemo } from 'react'
import useDeliverLastOld from './useDeliverLastOld'
import { UseStreamResultAsObject } from './useStreamOrPromise'

export interface StreamConfig<V, E, C> {
    dontThrow?: boolean
    suspense?: boolean
    onValue?: (value: V) => void
    onError?: (error: E) => void
    onComplete?: (complete: C) => void
}

export type UseStreamResultAsArray<V, E, C> =
    | [undefined, true, 'loading', undefined, undefined]
    | [V, false, 'running', undefined, undefined]
    | [undefined, false, 'completed', undefined, C]
    | [undefined, false, 'error', E, undefined]

export type UseStreamResult<V, E, C> = UseStreamResultAsArray<V, E, C> & UseStreamResultAsObject<V, E, C>

export default function useDeliverLast<V, E, C>(
    streamGetter: DeliverLast<V, E, C> | (() => DeliverLast<V, E, C> | undefined) | undefined,
    deps: DependencyList
): UseStreamResult<V, E, C>

export default function useDeliverLast<V, E, C>(
    streamGetter: DeliverLast<V, E, C> | (() => DeliverLast<V, E, C> | undefined) | undefined,
    loadingList: boolean[],
    deps: DependencyList
): UseStreamResult<V, E, C>

export default function useDeliverLast<V, E, C>(
    streamGetter: DeliverLast<V, E, C> | (() => DeliverLast<V, E, C> | undefined) | undefined,
    options: StreamConfig<V, E, C>,
    loadingList: boolean[],
    deps: DependencyList
): UseStreamResult<V, E, C>

export default function useDeliverLast<V, E, C>(
    streamGetter: DeliverLast<V, E, C> | (() => DeliverLast<V, E, C> | undefined) | undefined,
    second: StreamConfig<V, E, C> | boolean[] | DependencyList,
    third?: boolean[] | DependencyList,
    forth?: DependencyList
): UseStreamResult<V, E, C> {
    let config: StreamConfig<V, E, C> = {}
    let loadingList: boolean[] = []
    let deps: DependencyList

    if (forth) {
        config = second as StreamConfig<V, E, C>
        loadingList = third as boolean[]
        deps = forth
    } else {
        if (third) {
            loadingList = second as boolean[]
            deps = third as DependencyList
        } else {
            deps = second as DependencyList
        }
    }

    const waitingLoadingList = loadingList.some((x) => x)

    const shouldWatchStreamGetter = streamGetter === undefined || streamGetter instanceof Stream

    const stream = useMemo(() => {
        if (waitingLoadingList || !streamGetter) {
            return undefined
        }
        if (streamGetter instanceof Stream) {
            return streamGetter
        }
        return streamGetter()
    }, [...deps, waitingLoadingList, shouldWatchStreamGetter ? streamGetter : undefined])

    const resultAsObject = useDeliverLastOld(stream, { ...config, throwIfError: !config.dontThrow })
    const { loading, status, complete, error, value } = resultAsObject

    const resultAsArray = [value, loading, status, error, complete] as UseStreamResultAsArray<V, E, C>

    return Object.assign(resultAsArray, resultAsObject)
}
