// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VK7PQaZBxvNwlNpl2pC_ {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    width: 350px;\n}\n\n.VK7PQaZBxvNwlNpl2pC_ .Eth_wvVVusAIt6TlrskG {\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.tbkssfQZwz8GIqYjLNNS li {\n    list-style: none;\n}\n.tbkssfQZwz8GIqYjLNNS li a {\n    background: #eee;\n    margin-block: 8px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 8px 16px;\n    text-decoration: none;\n    color: #2870B8;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Home/HomePage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    width: 350px;\n}\n\n.form .input {\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.list li {\n    list-style: none;\n}\n.list li a {\n    background: #eee;\n    margin-block: 8px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 8px 16px;\n    text-decoration: none;\n    color: #2870B8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "VK7PQaZBxvNwlNpl2pC_",
	"input": "Eth_wvVVusAIt6TlrskG",
	"list": "tbkssfQZwz8GIqYjLNNS"
};
export default ___CSS_LOADER_EXPORT___;
