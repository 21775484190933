import * as asyncHooks from 'async_hooks'
import noop from 'lodash/noop'

export let AsyncResource: {
    new (type: string, triggerAsyncId?: number | asyncHooks.AsyncResourceOptions): asyncHooks.AsyncResource
}

if (asyncHooks.AsyncResource) {
    AsyncResource = asyncHooks.AsyncResource
} else {
    AsyncResource = class _AsyncResource {
        constructor(type: string, triggerAsyncId?: number | asyncHooks.AsyncResourceOptions) {
            noop(type, triggerAsyncId)
        }

        runInAsyncScope<T>(fn: (...args: any[]) => T, thisArg?: any, ...args: any[]): T {
            return fn.call(thisArg, ...args)
        }

        bind<T>(fn: T): T {
            return fn
        }

        emitDestroy(): this {
            return this
        }

        asyncId(): number {
            return -1
        }

        triggerAsyncId(): number {
            return -1
        }
    } as any
}
