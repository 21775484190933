import cashiiRPCs from '@cashii/common/src/cashiiRpcs'
import useEditor from '@hypatia/react-utils/hooks/Editor/useEditor'
import storeUserToken from '@hypatia/react-utils/utils/storeUserToken'
import { Button, TextField } from '@mui/material'
import React, { useCallback } from 'react'
import classes from './LoginPage.module.css'

export default React.memo(LoginPage)
function LoginPage(): JSX.Element {
    const loginEditor = useEditor({
        initialValues: {
            username: '',
            password: '',
            authCode: '',
        },
    })

    const onSubmit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault()
            const [err, token] = await cashiiRPCs.controlPanel.loginSystemUser(loginEditor.values)
            if (err) {
                alert(err)
                return
            }
            storeUserToken(token.token)
        },
        [loginEditor.values]
    )
    return (
        <form onSubmit={onSubmit} className={classes.form}>
            <TextField
                className={classes.input}
                variant="outlined"
                label="Username"
                value={loginEditor.values.username}
                onChange={loginEditor.handleChangeEvent('username')}
            />
            <TextField
                className={classes.input}
                variant="outlined"
                label="Password"
                type="password"
                value={loginEditor.values.password}
                onChange={loginEditor.handleChangeEvent('password')}
            />
            <TextField
                className={classes.input}
                variant="outlined"
                label="Auth Code"
                value={loginEditor.values.authCode}
                onChange={loginEditor.handleChangeEvent('authCode')}
            />

            <Button variant="contained" fullWidth type="submit">Login</Button>
        </form>
    )
}
