import { shallowClone } from '../../object/shallow-clone'
import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Clone<V, E = never, C = never> extends Pass<V, E, C, V> {
    override value(value: V): void {
        this._value(shallowClone(value))
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        clone(): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.clone = function <SV, SE, SC>(this: AnySourceStream<SV, SE, SC>) {
    return new Clone(this) // tslint:disable-line:no-invalid-this
}
