// @ts-ignore
import * as timesync from 'timesync'
import getServerUrl from '../channels/getServerUrl'

const ts = timesync.create({
    interval: 60 * 60 * 1000,
    server: `${getServerUrl()}timesync`,
})

export const getServerTime = (): Date => new Date(ts.now())
