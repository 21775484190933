import subscribeToQuery from '@hypatia/serverer-common/database/queries/subscribeToQuery'
import { BaseUserType } from '@hypatia/serverer-common/database/BaseUserTable'
import { DeliverLast } from '@hypatia/utils/streams/operators/deliver-last'
import mapValues from 'lodash/mapValues'
import { SLiveQuery as SQuery } from './SLiveQuery'

export const clientLiveQueryRegistry = new Map<
    string,
    (user: BaseUserType, ...args: any[]) => Promise<SQuery<any, any>>
>()

function registerClientLiveQuery<Args extends any[], T, K extends keyof T>(
    name: string,
    fn: (user: BaseUserType, ...args: Args) => Promise<SQuery<T, K>>
): (...args: Args) => DeliverLast<Pick<T, K>[]> {
    clientLiveQueryRegistry.set(name, fn as any)
    return (...args: Args) => subscribeToQuery({ queryName: name, args }).deliverLast()
}

type RegisterLiveQueriesReturnType<Obj> = {
    [K in keyof Obj]: Obj[K] extends (user: BaseUserType, ...args: infer Args) => Promise<SQuery<infer T, infer Keys>>
        ? (...args: Args) => DeliverLast<Pick<T, Keys>[]>
        : never
}

export function registerClientLiveQueries<
    T extends Record<string, (user: BaseUserType, ...args: any[]) => any /* SQuery<any, any> */>
>(namespace: string, obj: T): RegisterLiveQueriesReturnType<T> {
    return mapValues(obj, (fn, key) => registerClientLiveQuery(`${namespace}.${key}`, fn)) as any
}
