import { UserType } from '@cashii/common/src/db/db'
import clientLiveQueries, { currentUserFields } from '@cashii/common/src/queries/clientLiveQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { updateUsersSettings } from '@hypatia/react-utils/containers/SettingsProvider'
import '@hypatia/react-utils/containers/UserContext'
import { liveUserStreamGetter } from '@hypatia/react-utils/containers/userIdStream'
import { UArray } from '@hypatia/utils/types'

liveUserStreamGetter.set(() =>
    clientLiveQueries
        .currentUser()
        .map((users) => users[0])
        .deliverLast()
)
updateUsersSettings.set((settings) => clientUpdates.users.updateUser({ settings }))

declare module '@hypatia/react-utils/containers/UserContext' {
    // eslint-disable-next-line no-shadow
    export interface UserRecord extends Pick<UserType, UArray<typeof currentUserFields>> {}
}
