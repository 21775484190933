import type { AsyncErrorOrValue } from '../types'

export default function pf<T, E = any>(promise: AsyncErrorOrValue<E, T>): Promise<T> {
    return promise.then(([err, res]) => {
        if (err !== undefined) {
            throw err
        }
        return res!
    })
}
