import { createRoutesDict } from '@hypatia/react-utils/router/RouteObj'
import { webRoutesGetter } from '@hypatia/react-utils/router/getPathForRoute'
import defined from '@hypatia/utils/object/defined'
import AccountsPage from '../../pages/Accounts/AccountsPage'
import BusinessActivityTypesPage from '../../pages/BusinessActivityTypes/BusinessActivityTypesPage'
import CashMachinePage from '../../pages/CashMachine/CashMachinePage'
import Atm from '../../pages/CashMachineLiveQrCode/Atm/Atm'
import CashMachineLiveQrCode from '../../pages/CashMachineLiveQrCode/CashMachineLiveQrCode'
import OperatorPage from '../../pages/CashMachineLiveQrCode/OperatorPage'
import CashMachinesPage from '../../pages/CashMachines/CashMachinesPage'
import CashMachinesManagementPage from '../../pages/CashMachinesManagement/CashMachinesManagementPage'
import FeesPage from '../../pages/Fees/FeesPage'
import GroupNotificationsPage from '../../pages/GroupNotifications/GroupNotificationsPage'
import GroupsPage from '../../pages/Groups/GroupsPage'
import HomePage from '../../pages/Home/HomePage'
import LoginPage from '../../pages/Login/LoginPage'
import ManualOperationPage from '../../pages/ManualOperation/ManualOperationPage'
import ManualOperationPage2 from '../../pages/ManualOperation2/ManualOperationPage'
import MarketsPage from '../../pages/Markets/MarketsPage'
import MovementsPage from '../../pages/Movements/MovementsPage'
import OffersPage from '../../pages/Offers/OffersPage'
import PackagesPage from '../../pages/Packages/PackagesPage'
import SystemAccountsPage from '../../pages/SystemAccounts/SystemAccountsPage'
import SystemUsersPage from '../../pages/SystemUsers/SystemUsersPage'
import TransactionsPage from '../../pages/Transactions/TransactionsPage'
import UsersPage from '../../pages/Users/UsersPage'
import VerifyUsersIdsPage from '../../pages/VerifyUsersIds/VerifyUsersIdsPage'
import Soon from '../../pages/CashMachineLiveQrCode/Atm/Soon'
import SubBusinessActivityTypesPage from '../../pages/SubBusinessActivityTypes/SubBusinessActivityTypesPage'
import ResetPinRequestsPage from '../../pages/ResetPinRequests/ResetPinRequestsPage'
import AtmAccountsPage from '../../pages/CashMachineLiveQrCode/Atm/AtmAccountsPage'
import CitiesPage from '../../pages/Cities/CitiesPage'

declare module '@hypatia/react-utils/router/RouteObj' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    export interface RouteObj<P = any> {}
}

function pageTitle(...list: (string | undefined)[]): string {
    const filtered = list.filter(defined)
    if (!filtered.length) {
        return `Cashii`
    }
    return `${list.filter(defined).join(' - ')} | Cashii`
}

export const getCashiiRoutes = () =>
    createRoutesDict({
        login: {
            path: '',
            component: LoginPage,
            key: 'login',
            auth: 'unauthenticated',
        },
        atmSoon: {
            path: '/cash-machines/:cashMachineId/soon',
            component: Soon,
            key: 'atmSoon',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.cashMachines,
        },
        atm: {
            path: '/cash-machines/:cashMachineId/atm',
            component: Atm,
            key: 'atm',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.cashMachines,
        },
        atmAccounts: {
            path: '/cash-machines/:cashMachineId/accounts',
            component: AtmAccountsPage,
            key: 'atm',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.cashMachines,
        },
        cashMachineLiveQr: {
            path: '/cash-machines/:cashMachineId/live-qr',
            component: CashMachineLiveQrCode,
            key: 'cashMachineLiveQr',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.cashMachines,
        },
        cashMachineOperator: {
            path: '/cash-machines/:cashMachineId/operator',
            component: OperatorPage,
            key: 'cashMachineOperator',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.cashMachines,
        },
        cashMachine: {
            path: '/cash-machines/:cashMachineId',
            component: CashMachinePage,
            key: 'cashMachineOperator',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.cashMachines,
        },
        cashMachines: {
            path: '/cash-machines',
            component: CashMachinesPage,
            key: 'cashMachineOperator',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.cashMachines,
        },
        verifyUsersIds: {
            path: '/verify-users-ids',
            component: VerifyUsersIdsPage,
            key: 'verifyUsersIds',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.verifyUsersIds,
        },
        users: {
            path: '/users',
            component: UsersPage,
            key: 'users',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.users,
        },
        systemUsers: {
            path: '/system-users',
            component: SystemUsersPage,
            key: 'system-users',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.systemUsers,
        },
        accounts: {
            path: '/accounts',
            component: AccountsPage,
            key: 'accounts',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.accounts,
        },
        transactions: {
            path: '/transactions',
            component: TransactionsPage,
            key: 'transactions',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.transactions,
        },
        movements: {
            path: '/movements',
            component: MovementsPage,
            key: 'movements',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.movements,
        },
        markets: {
            path: '/markets',
            component: MarketsPage,
            key: 'markets',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.markets,
        },
        offers: {
            path: '/offers',
            component: OffersPage,
            key: 'offers',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.offers,
        },
        feesManagement: {
            path: '/fees-management',
            component: FeesPage,
            key: 'feesManagement',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.feesManagement,
        },
        groups: {
            path: '/groups',
            component: GroupsPage,
            key: 'groups',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.groups,
        },
        cityManagement: {
            path: '/city-management',
            component: CitiesPage,
            key: 'cityManagement',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.cityManagement,
        },
        businessActivityTypes: {
            path: '/business-activity-types',
            component: BusinessActivityTypesPage,
            key: 'businessActivityTypes',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.businessActivityTypes,
        },
        subBusinessActivityTypes: {
            path: '/sub-business-activity-types',
            component: SubBusinessActivityTypesPage,
            key: 'subBusinessActivityTypes',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.businessActivityTypes,
        },
        groupNotifications: {
            path: '/group-notifications',
            component: GroupNotificationsPage,
            key: 'group-notifications',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.groupNotifications,
        },
        packages: {
            path: '/packages',
            component: PackagesPage,
            key: 'packages',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.packages,
        },
        systemAccounts: {
            path: '/system-accounts',
            component: SystemAccountsPage,
            key: 'systemAccounts',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.systemAccounts,
        },
        cashMachinesManagement: {
            path: '/cash-machines-management',
            component: CashMachinesManagementPage,
            key: 'cashMachines',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.cashMachinesManagement,
        },
        resetPinRequests: {
            path: '/reset-pin-requests',
            component: ResetPinRequestsPage,
            key: 'resetPinRequests',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.resetPinRequests,
        },
        manualOperation: {
            path: '/manual-operation',
            component: ManualOperationPage,
            key: 'manualOperation',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.manualOperation,
        },
        manualOperation2: {
            path: '/secret-manual-operation',
            component: ManualOperationPage2,
            key: 'manualOperation',
            auth: 'authenticated',
            authRule: ({ user }) => !!user?.systemPermissions?.manualOperation,
        },
        home: {
            path: '',
            component: HomePage,
            key: 'home',
            auth: 'authenticated',
        },
    } as const)

webRoutesGetter.set(getCashiiRoutes)
