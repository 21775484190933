/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
import { CityId } from '@cashii/common/src/db/db'
import clientInserts from '@cashii/common/src/queries/clientInserts'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Button } from '@mui/material'
import {
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    GridToolbarContainer,
    MuiEvent
} from '@mui/x-data-grid-premium'
import { omit } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import classes from './CitiesPage.module.css'

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props

    const handleClick = () => {
        const _id = prompt('Enter id') as CityId
        if (!_id) {
            return
        }
        setRows((oldRows) => [...oldRows, { _id, name: '', age: '', isNew: true }])
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [_id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }))
    }

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    )
}

export default React.memo(CitiesPage)

type CityType = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getAllCities> & {
    isNew?: boolean
}
// eslint-disable-next-line react/no-multi-comp
function CitiesPage(): JSX.Element {
    const [_cities] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllCities(), [])
    const [rows, setRows] = React.useState<CityType[] | null>(null)

    useEffect(() => {
        if (_cities) {
            setRows(oldRows => {
                if (!oldRows) {
                    return _cities
                }
                const isNew = oldRows.filter(row => row.isNew)
                return [..._cities, ...isNew]
            })
        }
    }, [_cities])

    const alert = useAlertNotification()

    const getRowId = useCallback((row: CityType) => row._id, [])
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})

    const handleRowEditStart = useCallback((params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        event.defaultMuiPrevented = true
    }, [])

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        event.defaultMuiPrevented = true
    }, [])

    const handleEditClick = useCallback(
        (id: CityId) => () => {
            setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }))
        },
        []
    )

    const handleSaveClick = useCallback(
        (id: CityId) => () => {
            setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }))
        },
        []
    )

    const handleCancelClick = useCallback(
        (id: CityId) => () => {
            setRowModesModel((rowModesModel) => ({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            }))

            setRows((rows) => {
                const editedRow = rows!.find((row) => row._id === id)
                if (editedRow!.isNew) {
                    return rows!.filter((row) => row._id !== id)
                }
                return rows
            })
        },
        []
    )

    const processRowUpdate = useCallback(
        async (newRow: CityType) => {
            const updatedRow = { ...newRow, isNew: false }
            if (newRow.isNew) {
                const [err] = await clientInserts.controlPanel.insertCity(omit(newRow, 'isNew'))
                if (err) {
                    alert({
                        variant: 'error',
                        alertText: err,
                    })
                    throw err
                }
                alert({
                    variant: 'success',
                    alertText: 'CityType created',
                })
            } else {
                const [err] = await clientUpdates.controlPanel.updateCity(
                    newRow._id as CityId,
                    omit(newRow, '_id', 'isNew')
                )
                if (err) {
                    alert({
                        variant: 'error',
                        alertText: err,
                    })
                    throw err
                }

                alert({
                    variant: 'success',
                    alertText: 'CityType updated',
                })
            }
            setRows((rows) => rows!.map((row) => (row._id === newRow._id ? updatedRow : row)))
            return updatedRow
        },
        [alert]
    )

    const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }, [])

    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            { field: 'name', headerName: 'Name', editable: true, type: 'string' },
            {
                field: 'listed',
                headerName: 'Listed',
                editable: true,
                type: 'boolean',
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                key="save"
                                icon={<SaveIcon />}
                                label="Save"
                                onClick={handleSaveClick(id as CityId)}
                            />,
                            <GridActionsCellItem
                                key="cancel"
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id as CityId)}
                                color="inherit"
                            />,
                        ]
                    }

                    return [
                        <GridActionsCellItem
                            key="edit"
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id as CityId)}
                            color="inherit"
                        />,
                    ]
                },
            },
        ] as GridColDef<CityType>[]
    }, [handleCancelClick, handleEditClick, handleSaveClick, rowModesModel])

    if (!rows) {
        return <div>Loading...</div>
    }

    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="cities"
                className={classes.table}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                getRowId={getRowId}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
        </div>
    )
}
