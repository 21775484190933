// eslint-disable-next-line no-shadow
export enum MessageType {
    Value,
    Error,
    Complete,
}

export interface ValueMessage<V = unknown> {
    type: MessageType.Value
    value: V
}
export interface CompleteMessage<C = unknown> {
    type: MessageType.Complete
    complete: C
}

export interface ErrorMessage<E = unknown> {
    type: MessageType.Error
    error: E
}

export type Message<V, E = never, C = never> = ValueMessage<V> | CompleteMessage<C> | ErrorMessage<E>

export type AnyMessage = Message<unknown, unknown, unknown>
export type EmptyMessage = Message<never>
