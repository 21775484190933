import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function equal<O>(
    valueGetter: (doc: O) => unknown,
    customMessage?: ValidationMessage
): FieldValidation<unknown, O> {
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.notEqual'),
        validate: ({ value, doc }) => valueGetter(doc) === value,
    }
}
