/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
import { AccountNumber, AccountingNumber, AccountingParentNumber, Money } from '@cashii/common/src/db/db'
import clientInserts from '@cashii/common/src/queries/clientInserts'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Button } from '@mui/material'
import {
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    GridToolbarContainer,
    MuiEvent
} from '@mui/x-data-grid-premium'
import { pick, sumBy } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import classes from './SystemAccountsPage.module.css'

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props

    const handleClick = () => {
        const _id = prompt('Enter id') as AccountNumber
        if (!_id) {
            return
        }
        setRows((oldRows) => [...oldRows, { _id, name: '', age: '', isNew: true }])
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [_id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }))
    }

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    )
}

export default React.memo(SystemAccountsPage)

type SystemAccount = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getAllSystemAccounts> & { isNew?: boolean }
// eslint-disable-next-line react/no-multi-comp
function SystemAccountsPage(): JSX.Element {
    const [_systemAccounts] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllSystemAccounts(), [])
    const [rows, setRows] = React.useState<SystemAccount[] | null>(null)

    useEffect(() => {
        if (_systemAccounts) {
            setRows((oldRows) => {
                if (!oldRows) {
                    return _systemAccounts
                }
                const isNew = oldRows.filter((row) => row.isNew)
                return [..._systemAccounts, ...isNew]
            })
        }
    }, [_systemAccounts])

    const alert = useAlertNotification()

    const getRowId = useCallback((row: SystemAccount) => row._id, [])
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})

    const handleRowEditStart = useCallback((params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        event.defaultMuiPrevented = true
    }, [])

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        event.defaultMuiPrevented = true
    }, [])

    const handleEditClick = useCallback(
        (id: AccountNumber) => () => {
            setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }))
        },
        []
    )

    const handleSaveClick = useCallback(
        (id: AccountNumber) => () => {
            setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }))
        },
        []
    )

    const handleCancelClick = useCallback(
        (id: AccountNumber) => () => {
            setRowModesModel((rowModesModel) => ({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            }))

            setRows((rows) => {
                const editedRow = rows!.find((row) => row._id === id)
                if (editedRow!.isNew) {
                    return rows!.filter((row) => row._id !== id)
                }
                return rows
            })
        },
        []
    )

    const processRowUpdate = useCallback(
        async (newRow: SystemAccount) => {
            const updatedRow = { ...newRow, isNew: false }
            if (newRow.isNew) {
                const [err] = await clientInserts.controlPanel.insertSystemAccount({
                    _id: newRow._id,
                    accountingParentNumber: newRow.accountingParentNumber as AccountingParentNumber,
                    accountingNumber: (newRow.accountingParentNumber + newRow._id) as AccountingNumber,
                    nickname: newRow.nickname,
                    minimumBalance: newRow.minimumBalance as Money,
                    currency: newRow.currency,
                })
                if (err) {
                    alert({
                        variant: 'error',
                        alertText: err,
                    })
                    throw err
                }
                alert({
                    variant: 'success',
                    alertText: 'System Account created',
                })
            } else {
                const [err] = await clientUpdates.controlPanel.updateSystemAccount(newRow._id as AccountNumber, {
                    ...pick(newRow, 'accountingNumber', 'accountingParentNumber', 'nickname', 'minimumBalance'),
                    accountingNumber: (newRow.accountingParentNumber + newRow._id) as AccountingNumber,
                })
                if (err) {
                    alert({
                        variant: 'error',
                        alertText: err,
                    })
                    throw err
                }

                alert({
                    variant: 'success',
                    alertText: 'System Account updated',
                })
            }
            setRows((rows) => rows!.map((row) => (row._id === newRow._id ? updatedRow : row)))
            return updatedRow
        },
        [alert]
    )

    const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }, [])

    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            { field: 'accountingParentNumber', headerName: 'Accounting Parent Number', type: 'string', editable: true },
            { field: 'accountingNumber', headerName: 'Accounting Number', type: 'string' },
            {
                field: 'minimumBalance',
                headerName: 'Minimum Balance',
                type: 'number',
                editable: true,
                valueGetter: ({ row }) => (row.minimumBalance || 0) / 100,
                valueSetter: ({ row, value }) => {
                    return { ...row, minimumBalance: Math.round(value * 100) }
                },
            },
            { field: 'nickname', headerName: 'Nickname', type: 'string', editable: true },
            {
                field: 'currency',
                headerName: 'Currency',
                type: 'singleSelect',
                valueOptions: ['USD', 'TRY'],
                editable: true,
            },
            {
                field: 'balance',
                headerName: 'Balance',
                type: 'number',
                valueGetter: (doc) => doc.row.balance / 100,
                aggregable: true,
                availableAggregationFunctions: ['sum'],
            },
            {
                field: 'lockedBalance',
                headerName: 'Locked Balance',
                type: 'number',
                valueGetter: (doc) => sumBy(doc.row.lockedBalance, (x) => x.amount) / 100,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                key="save"
                                icon={<SaveIcon />}
                                label="Save"
                                onClick={handleSaveClick(id as AccountNumber)}
                            />,
                            <GridActionsCellItem
                                key="cancel"
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id as AccountNumber)}
                                color="inherit"
                            />,
                        ]
                    }

                    return [
                        <GridActionsCellItem
                            key="edit"
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id as AccountNumber)}
                            color="inherit"
                        />,
                    ]
                },
            },
        ] as GridColDef<SystemAccount>[]
    }, [handleCancelClick, handleEditClick, handleSaveClick, rowModesModel])

    if (!rows) {
        return <div>Loading...</div>
    }

    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="systemAccounts"
                className={classes.table}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                getRowId={getRowId}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
        </div>
    )
}
