import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Deadend<V, E = never, C = never> extends Pass<V, E, C> {
    override value(): void {
        // do nothing :)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        deadend(): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.deadend = function <V, E, C>(this: AnySourceStream<V, E, C>) {
    return new Deadend(this) // tslint:disable-line:no-invalid-this
}
