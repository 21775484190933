import { Currency } from '@cashii/common/src/db/db'

export function getCurrencySymbol(currency: Currency): string {
    switch (currency) {
        case 'USD':
            return '$'
        case 'TRY':
            return '₺'
    }
}
