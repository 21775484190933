export const shallowDiff = <T extends object>(objA: T, objB: T): Partial<T> => {
    const diff: Partial<T> = {}
    if (objA === objB) {
        return diff
    }

    const keysA = Object.keys(objA)
    const keysB = Object.keys(objB)

    // Test for A's keys different from B.
    const aHasOwnProperty = Object.prototype.hasOwnProperty.bind(objA)
    const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB)

    for (const key of keysA) {
        if (!bHasOwnProperty(key) || (objA as any)[key] !== (objB as any)[key]) {
            ;(diff as any)[key] = (objB as any)[key]
        }
    }

    for (const key of keysB) {
        if (!aHasOwnProperty(key) || (objA as any)[key] !== (objB as any)[key]) {
            ;(diff as any)[key] = (objB as any)[key]
        }
    }

    return diff
}
