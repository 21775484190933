/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
import { CashMachineId } from '@cashii/common/src/db/db'
import clientInserts from '@cashii/common/src/queries/clientInserts'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'
import clientUpdates from '@cashii/common/src/queries/clientUpdates'
import { useAlertNotification } from '@hypatia/react-utils/containers/AlertNotificationContext'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { TypeFromLiveQuery } from '@hypatia/serverer-common/database/queries/types'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Autocomplete, Button, TextField } from '@mui/material'
import {
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp,
    GridToolbarContainer,
    MuiEvent
} from '@mui/x-data-grid-premium'
import { omit } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSystemUsers } from '../GroupNotifications/useSystemUsers'
import StoredDataGridPremium from '../Users/StoredDataGridPremium'
import classes from './CashMachinesManagementPage.module.css'

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props

    const handleClick = () => {
        const _id = prompt('Enter id') as CashMachineId
        if (!_id) {
            return
        }

        setRows((oldRows) => [...oldRows, { _id, name: '', age: '', isNew: true }])
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [_id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }))
    }

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    )
}

export default React.memo(CashMachinesManagementPage)

type CashMachine = TypeFromLiveQuery<typeof clientLiveQueries.controlPanel_getAllCashMachines> & { isNew?: boolean }
// eslint-disable-next-line react/no-multi-comp
function CashMachinesManagementPage(): JSX.Element {
    const [systemUsers] = useSystemUsers()

    const [_cashMachines] = useDeliverLast(() => clientLiveQueries.controlPanel_getAllCashMachines(), [])
    const [rows, setRows] = React.useState<CashMachine[] | null>(null)

    useEffect(() => {
        if (_cashMachines) {
            setRows(oldRows => {
                if (!oldRows) {
                    return _cashMachines
                }
                const isNew = oldRows.filter(row => row.isNew)
                return [..._cashMachines, ...isNew]
            })
        }
    }, [_cashMachines])

    const alert = useAlertNotification()

    const getRowId = useCallback((row: CashMachine) => row._id, [])
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})

    const handleRowEditStart = useCallback((params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        event.defaultMuiPrevented = true
    }, [])

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        event.defaultMuiPrevented = true
    }, [])

    const handleEditClick = useCallback(
        (id: CashMachineId) => () => {
            setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }))
        },
        []
    )

    const handleSaveClick = useCallback(
        (id: CashMachineId) => () => {
            setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }))
        },
        []
    )

    const handleCancelClick = useCallback(
        (id: CashMachineId) => () => {
            setRowModesModel((rowModesModel) => ({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            }))

            setRows((rows) => {
                const editedRow = rows!.find((row) => row._id === id)
                if (editedRow!.isNew) {
                    return rows!.filter((row) => row._id !== id)
                }
                return rows
            })
        },
        []
    )

    const processRowUpdate = useCallback(
        async (newRow: CashMachine) => {
            const updatedRow = { ...newRow, isNew: false }
            if (newRow.isNew) {
                const [err] = await clientInserts.controlPanel.insertCashMachine(omit(newRow, 'isNew'))
                if (err) {
                    alert({
                        variant: 'error',
                        alertText: err,
                    })
                    throw err
                }
                alert({
                    variant: 'success',
                    alertText: 'CashMachine created',
                })
            } else {
                const [err] = await clientUpdates.controlPanel.updateCashMachine(
                    newRow._id as CashMachineId,
                    omit(newRow, '_id', 'isNew')
                )
                if (err) {
                    alert({
                        variant: 'error',
                        alertText: err,
                    })
                    throw err
                }

                alert({
                    variant: 'success',
                    alertText: 'CashMachine updated',
                })
            }
            setRows((rows) => rows!.map((row) => (row._id === newRow._id ? updatedRow : row)))
            return updatedRow
        },
        [alert]
    )

    const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }, [])

    const columns = useMemo(() => {
        return [
            { field: '_id', headerName: 'Id' },
            {
                field: 'systemName',
                headerName: 'System Name',
                editable: true,
                type: 'string',
            },
            {
                field: 'tryAccountNumber',
                headerName: 'TRY Account Number',
                editable: true,
                type: 'string',
                valueGetter: ({ row }) => row.accounts?.TRY,
                valueSetter: ({ row, value }) => {
                    row.accounts = { ...row.accounts, TRY: value }
                    return row
                },
            },

            {
                field: 'usdAccountNumber',
                headerName: 'USD Account Number',
                editable: true,
                type: 'string',
                valueGetter: ({ row }) => row.accounts?.USD,
                valueSetter: ({ row, value }) => {
                    row.accounts = { ...row.accounts, USD: value }
                    return row
                },
            },
            {
                field: 'modelNumber',
                headerName: 'Model Number',
                editable: true,
                type: 'string',
            },
            {
                field: 'type',
                headerName: 'Type',
                editable: true,
                type: 'singleSelect',
                valueOptions: ['ATM', 'teller'],
            },
            {
                field: 'accessibleBy',
                headerName: 'AccessibleBy',
                editable: true,
                type: 'multiSelect',
                valueOptions: systemUsers,
                minWidth: 200,
                renderCell: ({ value }) => {
                    return value?.map((x) => systemUsers?.find((y) => y._id === x)?.username).join(', ')
                },
                renderEditCell: (params) => {
                    return (
                        <Autocomplete
                            style={{ width: '100%', height: '100%', overflowY: 'auto' }}
                            multiple
                            options={systemUsers?.map((x) => x._id) ?? []}
                            value={params.value}
                            onChange={(e, newValue) => {
                                params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                            }}
                            renderInput={(params) => <TextField {...params} variant="standard" />}
                            getOptionLabel={(option) => systemUsers?.find((x) => x._id === option)?.username ?? ''}
                        />
                    )
                },
            },

            {
                field: 'active',
                headerName: 'Active',
                editable: true,
                type: 'boolean',
            },

            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                key="save"
                                icon={<SaveIcon />}
                                label="Save"
                                onClick={handleSaveClick(id as CashMachineId)}
                            />,
                            <GridActionsCellItem
                                key="cancel"
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id as CashMachineId)}
                                color="inherit"
                            />,
                        ]
                    }

                    return [
                        <GridActionsCellItem
                            key="edit"
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id as CashMachineId)}
                            color="inherit"
                        />,
                    ]
                },
            },
        ] as GridColDef<CashMachine>[]
    }, [handleCancelClick, handleEditClick, handleSaveClick, rowModesModel, systemUsers])

    if (!rows) {
        return <div>Loading...</div>
    }

    return (
        <div className={classes.container}>
            <StoredDataGridPremium
                name="cashMachines"
                className={classes.table}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                getRowId={getRowId}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
        </div>
    )
}
