import { withFileUrl } from '@hypatia/react-utils/containers/withFileUrl'
import React from 'react'

export const OpenInWindow = withFileUrl(_OpenInWindow, 'href')
interface Props {
    href: string
    children: React.ReactNode
}
function _OpenInWindow(props: Props) {
    const { href, children } = props
    return (
        <div
            style={{ cursor: 'pointer', marginInline: 4 }}
            onClick={() => {
                window.open(href, '_blank', 'left='+document.documentElement.clientWidth*2+',screenY=0,width=600,height=800')
            }}
        >
            {children}
        </div>
    )
}
