import { getSystemDefaultLocale } from './getSystemDefaultLocale'
import { LocaleType } from './locales'

const languageLabels: Record<LocaleType, string> = { en: 'English', tr: 'Türkçe', ar: 'عربي' }

const getDefaultLocale = () => {
    let defaultLocale: LocaleType = getSystemDefaultLocale()

    if (!(defaultLocale in languageLabels)) {
        defaultLocale = 'en'
    }

    return defaultLocale
}

export default getDefaultLocale
