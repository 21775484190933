import identity from 'lodash/identity'
import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class DistinctUntilChanged<V, E = never, C = never, T = V> extends Pass<V, E, C> {
    protected last: T | undefined
    protected first = true

    constructor(
        protected override source?: AnySourceStream<V, E, C>,
        protected mapper?: (current: V) => T,
        protected areEqual?: (current: T, old: T | undefined) => boolean
    ) {
        super(source)
    }

    override value(value: V): void {
        const v: T = (this.mapper || identity)(value) as any
        if (this.areEqual) {
            if (this.areEqual(v, this.last)) {
                return
            }
        } else if (!this.first && v === this.last) {
            return
        }
        this.first = false
        this.last = v
        this._value(value)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        distinctUntilChanged<T = DV>(
            mapper?: (current: DV) => T,
            areEqual?: (current: T, old: T | undefined) => boolean
        ): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.distinctUntilChanged = function <V, E, C, T = V>(
    this: AnySourceStream<V, E, C>,
    mapper?: (current: V) => T,
    areEqual?: (current: T, old: T | undefined) => boolean
) {
    return new DistinctUntilChanged(this, mapper, areEqual) // tslint:disable-line:no-invalid-this
}
