/* eslint-disable @typescript-eslint/no-explicit-any */
import { A, Union } from 'ts-toolbelt'

export type OnlyString<T> = T extends string ? T : never
export type OnlyObject<T> = T extends object ? T : never
export type OnlyArray<T> = T extends Array<any> ? T : never

export type Values<T extends Record<string | number, object>> = Union.Merge<T[keyof T]>

export declare type NestedPaths<Type> = Type extends
    | string
    | number
    | boolean
    | Date
    | RegExp
    | Buffer
    | Uint8Array
    | ((...args: any[]) => any)
    | Function
    ? []
    : Type extends ReadonlyArray<infer ArrayType>
    ? [] | [number, ...NestedPaths<ArrayType>]
    : Type extends Map<string, any>
    ? [string]
    : Type extends object
    ? {
          [Key in Extract<keyof Type, string>]: Type[Key] extends ReadonlyArray<infer ArrayType>
              ? Type extends ArrayType
                  ? [Key]
                  : ArrayType extends Type
                  ? [Key]
                  : [Key, ...NestedPaths<Type[Key]>]
              : [Key, ...NestedPaths<Type[Key]>]
      }[Extract<keyof Type, string>]
    : []

export declare type Join<T extends unknown[], D extends string> = T extends []
    ? ''
    : T extends [string | number]
    ? `${T[0]}`
    : T extends [string | number, ...infer R]
    ? `${T[0]}${D}${Join<R, D>}`
    : string

export type NestedKeys<T> = Join<NestedPaths<T>, '.'>
export type AllKeys<T> = keyof T | NestedKeys<T>

export type Writable<T> = {
    -readonly [K in keyof T]: T[K]
}
export type UArray<Arr extends Readonly<Array<any>>> = Arr[number]
export type PickFields<O, K extends Readonly<Array<keyof O>>> = Pick<O, UArray<K>>

export type Keys<T> = T[keyof T]

export type ErrorOrValue<E, R> = [E, undefined] | [undefined, R]
export type AsyncErrorOrValue<E, R> = Promise<ErrorOrValue<E, R>>

export function error<E>(err: E): [E, undefined] {
    return [err, undefined]
}
export function value<T>(val: T): [undefined, T] {
    return [undefined, val]
}
export const _void: [undefined, undefined] = [undefined, undefined]

export type AnyRecord = Record<string, any>

export interface CancelablePromise<T> extends Promise<T> {
    cancel: () => Promise<void>
}

type ObjValues<O> = UnionToIntersection<O[keyof O]>
export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
export type Obj<K extends string, V> = { [x in K]: V }

type RemoveAny<T> = A.Equals<T, any> extends 1 ? never : T
export type Paths<O extends object, PrePath extends string = ''> = ObjValues<{
    [K in OnlyString<keyof O> as `${PrePath}${K}`]: O[K] extends Function
        ? never
        : O[K] extends string | number | boolean | null | undefined | Date
        ? Obj<`${PrePath}${K}`, O[K]>
        : Exclude<O[K], null | undefined> extends Array<any>
        ? (Exclude<O[K], null | undefined>[number] extends object
              ? Paths<
                    RemoveAny<Merge<Exclude<Exclude<O[K], null | undefined>[number], null | undefined>>>,
                    `${PrePath}${K}.$item.`
                >
              : {}) &
              Obj<`${PrePath}${K}`, O[K]> &
              Obj<`${PrePath}${K}.$item`, Exclude<O[K], null | undefined>[number]>
        : Exclude<O[K], null | undefined> extends object
        ? (Record<string, any> extends Required<O[K]>
              ? {}
              : Paths<RemoveAny<Merge<Exclude<O[K], null | undefined>>>, `${PrePath}${K}.`>) &
              Obj<`${PrePath}${K}`, O[K]> &
              Obj<`${PrePath}${K}.$key`, keyof O[K]> &
              Obj<`${PrePath}${K}.$value`, O[K][keyof O[K]]> &
              (O[K][keyof O[K]] extends object ? Paths<RemoveAny<O[K][keyof O[K]]>, `${PrePath}${K}.$value.`> : {})
        : Obj<`${PrePath}${K}`, O[K]>
}>

type CommonKeys<T extends object> = keyof T
type _AllKeys<T> = T extends any ? keyof T : never

type Subtract<T, C> = T extends C ? never : T
type NonCommonKeys<T extends object> = Subtract<_AllKeys<T>, CommonKeys<T>>

type PickType<T, K extends _AllKeys<T>> = T extends { [k in K]?: any } ? T[K] : undefined
type PickTypeOf<T, K extends string | number | symbol> = K extends _AllKeys<T> ? PickType<T, K> : never

type Merge<T extends object> = {
    [k in CommonKeys<T>]: PickTypeOf<T, k>
} & {
    [k in NonCommonKeys<T>]?: PickTypeOf<T, k>
}

export type PathsKeys<T extends object> = keyof Paths<T>
