import { getDeviceId } from '@hypatia/react-utils/utils/getDeviceId'
import i18next from 'i18next'
import { Client } from '@hypatia/serverer-common/Client'
import { sessionId } from '@hypatia/serverer-common/sessionId'
import userAgent from 'ua-parser-js'

export async function getClient(source: string): Promise<Client> {
    const ua = userAgent(navigator.userAgent)
    const client: Client = {
        sessionId: sessionId,
        app: {
            source,
            version: process.env.VERSION!,
        },
        browser: {
            browser: ua.browser.name,
            version: ua.browser.version,
            engine: ua.engine.name,
            engineVersion: ua.engine.version,
        },
        device: {
            id: await getDeviceId(),
            screenWidth: screen.width,
            screenHeight: screen.height,
            osType: 'web',
            os: ua.os.name,
            osVersion: ua.os.version,
            cpuArch: ua.cpu.architecture,
            type: ua.device.type,
            vendor: ua.device.vendor,
            model: ua.device.model,
        },
        session: {
            host: location.host,
            ip: '',
            internet: (navigator as any).connection?.effectiveType,
            wifi: !(navigator as any).connection?.saveData,
            origin: location.origin,
            referrer: document.referrer,
            timezoneOffset: new Date().getTimezoneOffset(),
            language: i18next.language,
        },
    }

    return client
}
