import useAsRef from '../useAsRef'
import { useEffect } from 'react'

export default function useIntervalDynamic(callback: () => void, delay: number, pause?: boolean): void {
    const savedCallback = useAsRef(callback)

    const delayRef = useAsRef(delay)

    useEffect(() => {
        if (pause) {
            return
        }

        let id: any

        function tick(): void {
            savedCallback.current()
            id = setTimeout(tick, delayRef.current)
        }

        tick()

        return () => clearTimeout(id)
    }, [pause, savedCallback, delayRef])
}
