import { AnySourceStream, AnySubscription, Stream } from '../stream'
import { Pass } from './pass'

export class TakeUntil<V, E = never, C = never, N2 = V> extends Pass<V, E, C, V, E, C | N2 | undefined> {
    protected signal: AnySourceStream<N2, never, never>

    protected signalSubscription: AnySubscription = {
        value: (value: N2) => this._complete(value),
        complete: () => this._complete(undefined),
        error: () => this._complete(undefined),
    }

    constructor(signal: AnySourceStream<N2, never, never>, source?: AnySourceStream<V, E, C>) {
        super(source)
        this.signal = signal
    }

    override connect(): void {
        super.connect()
        this.signal.subscribe(this.signalSubscription)
    }

    override disconnect(): void {
        super.disconnect()
        this.signal.unsubscribe(this.signalSubscription)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        takeUntil<N2>(
            signal: AnySourceStream<N2, never, never>
        ): Stream<DV, DE, DC | undefined, DV, DE, DC | N2 | undefined>
    }
}

Stream.prototype.takeUntil = function <V, E, C, N2>(
    this: AnySourceStream<V, E, C>,
    signal: AnySourceStream<N2, never, never>
) {
    return new TakeUntil(signal, this) // tslint:disable-line:no-invalid-this
}
