import React, { ProviderProps, useEffect, useMemo } from 'react'
import useAsRef from './useAsRef'
import { useSyncExternalStore } from 'use-sync-external-store/shim'
interface ContextEncapsulator<T> {
    valueRef: React.MutableRefObject<T>
    addListener: (fn: () => void) => () => void
}

export interface Context<T> {
    initialValue?: T
    Provider: React.ComponentType<ProviderProps<T>>
    context: React.Context<ContextEncapsulator<T>>
}

export function createContext<T>(initialValue?: T): Context<T> {
    const ContextValue = React.createContext<ContextEncapsulator<T>>(undefined as any)

    return {
        context: ContextValue,
        initialValue,
        Provider: function Provider({ value, children }: ProviderProps<T>) {
            const listeners = useMemo(() => {
                const list = new Set<() => void>()
                return {
                    list,
                    add: (fn: () => void) => {
                        list.add(fn)
                        return () => {
                            list.delete(fn)
                        }
                    },
                }
            }, [])

            const valueRef = useAsRef(value)

            const valueToPass = useMemo(
                () =>
                    ({
                        valueRef: valueRef,
                        addListener: listeners.add,
                    } as ContextEncapsulator<T>),
                [listeners.add, valueRef]
            )

            useEffect(() => {
                listeners.list.forEach((fn) => fn())
            }, [listeners.list, value])

            return <ContextValue.Provider value={valueToPass}>{children}</ContextValue.Provider>
        },
    }
}

export function useContext<T>(context: Context<T>): T {
    const ctx = React.useContext(context.context)
    if (ctx) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useSyncExternalStore(
            ctx.addListener,
            () => ctx?.valueRef.current ?? context.initialValue,
            () => ctx?.valueRef.current ?? context.initialValue
        )!
    }
    return context.initialValue!
}
