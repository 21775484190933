import { getRpcCaller } from '@hypatia/serverer-common/rpc/rpcCaller'
import { RpcType } from '@hypatia/serverer-common/rpc/RpcType'
import { DOCUMENT_NOT_FOUND } from '@hypatia/utils/communication/errors'
import { FileToken } from '../tables/FileTable'

export type GetDownloadUrlFromFileTokenArgs = {
    token: FileToken
}

export const GetDownloadUrlFromFileTokenRpcName = 'storage.getDownloadUrlFromFileToken'
export type GetDownloadUrlFromFileTokenResults = string
export type GetDownloadUrlFromFileTokenErrors = typeof DOCUMENT_NOT_FOUND
export type GetDownloadUrlFromFileTokenRpc = RpcType<
    typeof GetDownloadUrlFromFileTokenRpcName,
    GetDownloadUrlFromFileTokenArgs,
    GetDownloadUrlFromFileTokenResults,
    GetDownloadUrlFromFileTokenErrors
>

export const getter = getRpcCaller<GetDownloadUrlFromFileTokenRpc>(GetDownloadUrlFromFileTokenRpcName)

