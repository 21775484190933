import { Client } from '@hypatia/serverer-common/Client'
import { BaseFieldsType } from '@hypatia/serverer-common/database/baseFields'
import { BaseUserType } from '@hypatia/serverer-common/database/BaseUserTable'
import { UserId } from '@hypatia/serverer-common/database/nominalTypes'
import { Table } from '@hypatia/serverer-common/database/table'
import { addTable } from '@hypatia/serverer-common/database/tableMap'
import { FileId, FileToken } from '@hypatia/serverer-common/storage/tables/FileTable'
import { A } from 'ts-toolbelt'

export type Currency = 'TRY' | 'USD'

export type Money = A.Type<number, 'Money'>
export type Percentage = A.Type<number, 'Percentage'>
export type PerThousand = A.Type<number, 'PerThousand'>

export type ResetPinCodeRequestId = A.Type<string, 'ResetPinCodeRequestId'>
export type LoginSessionId = A.Type<string, 'LoginSessionId'>
export type OfferId = A.Type<string, 'OfferId'>
export type DeviceId = A.Type<string, 'DeviceId'>
export type MarketId = A.Type<string, 'MarketId'>
export type MovementId = A.Type<string, 'MovementId'>
export type TransactionId = A.Type<string, 'TransactionId'>
export type AccountNumber = A.Type<string, 'AccountNumber'>
export type AccountingNumber = A.Type<string, 'AccountingNumber'>
export type AccountingParentNumber = A.Type<string, 'AccountingParentNumber'>
export type QrOperationId = A.Type<string, 'BuyFromMarketId'>
export type LiveQrCode = A.Type<string, 'LiveQrCode'>
export type CashMachineId = A.Type<string, 'CashMachineId'>
export type PackageId = A.Type<string, 'PackageId'>
export type BuyPackageId = A.Type<string, 'BuyPackageId'>
export type SavedUserId = A.Type<string, 'SavedUserId'>
export type CachedAvailablePackagesId = A.Type<string, 'CachedAvailablePackagesId'>
export type TelegramNotificationId = A.Type<string, 'TelegramNotificationId'>
export type TelegramTokenId = A.Type<string, 'TelegramTokenId'>
export type GiftCardId = A.Type<string, 'GiftCardId'>
export type OrderId = A.Type<string, 'OrderId'>
export type OrderFillId = A.Type<string, 'OrderFillId'>
export type OrderBookId = A.Type<string, 'OrderBookId'>
export type RemotePaymentRequestId = A.Type<string, 'RemotePaymentRequestId'>

export type AccountEnumType = 'personal' | 'market'
export type TransactionEnumType =
    | 'deposit'
    | 'withdraw'
    | 'transfer'
    | 'buyFromMarket'
    | 'buyPackage'
    | 'initialCredit'
    | 'depositGift'
    | 'gift'
    | 'cashback'
    | 'refundPackage'
    | 'redeemGiftCard'
    | 'referralCredit'
    | 'exchange'
    | 'remotePayment'
export type NotificationStatus = 'scheduled' | 'sent' | 'failed'

export interface SystemPermissions {
    verifyUsersIds?: boolean
    users?: boolean
    systemUsers?: boolean
    accounts?: boolean
    transactions?: boolean
    movements?: boolean
    markets?: boolean
    offers?: boolean
    groups?: boolean
    groupNotifications?: boolean
    packages?: boolean
    manualOperation?: boolean
    resetPinRequests?: boolean
    businessActivityTypes?: boolean
    systemAccounts?: boolean
    cashMachines?: boolean
    cashMachinesManagement?: boolean
    feesManagement?: boolean
    cityManagement?: boolean
}

export interface UserType extends BaseUserType { }
declare module '@hypatia/serverer-common/database/BaseUserTable' {
    // eslint-disable-next-line no-shadow
    interface BaseUserType {
        qrUser?: boolean
        ownedByUserId?: UserId
        ownedByMarketId?: MarketId

        fullNameInEnglish?: string

        phoneVerificationMethod?: 'whatsapp' | 'telegram' | 'sms' | 'qr'
        phone?: string

        birthDate?: Date

        systemUser?: boolean
        otpSecret?: string
        username?: string

        city?: CityId
        nationalNumber?: string

        accounts?: Pick<AccountType, '_id' | 'currency' | 'balance' | 'accountType' | 'marketId' | 'lockedBalance' | 'minimumBalance'>[]

        shouldGetDepositOffer?: boolean
        gotDepositOffer?: boolean

        finishedFirstDeposit?: boolean
        gotInitialCredit?: boolean

        uploadedIdPhotos?: boolean

        welcomeNotificationSent?: boolean
        idVerified?: boolean
        idVerifiedNotificationSent?: boolean

        sendReUploadIdNotification?: boolean

        idPhotos?: {
            front: FileId | FileToken
            back: FileId | FileToken
        }

        referredBy?: UserId
        referralCode?: string
        referralCreditProvided?: boolean
        toCheckReferralCredit?: boolean

        appSettings?: Pick<GroupType, 'features' | '_id'>

        approvedTermsOfServiceId?: TermsOfServiceId

        groupId?: GroupId

        systemPermissions?: SystemPermissions

        idVerificationNote?: string

        sex?: 'male' | 'female'
    }
}

export const UserTable: Table<UserType> = {
    collectionName: 'users',
    analytics: true,
    analyticsMap: user => [
        { actor: user._id, event: 'users', record: user },
        { actor: user._id, event: 'users_v2', record: user }
    ],
}

export interface DeviceType extends BaseFieldsType {
    _id: DeviceId
    userId: UserId
    deviceToken: string
    name: string
    os: 'ios' | 'android'
    manufacturer: string
    trusted: boolean
}

export const DeviceTable: Table<DeviceType> = {
    collectionName: 'devices',
    analytics: true,
}

export interface LoginSessionType extends BaseFieldsType {
    _id: LoginSessionId
    userId: UserId
    deviceId: DeviceId | null
    token: string
    expired: boolean
}

export const LoginSessionTable: Table<LoginSessionType> = {
    collectionName: 'loginSessions',
    analytics: true,
}

export interface ResetPinCodeRequestType extends BaseFieldsType {
    _id: ResetPinCodeRequestId
    userId: UserId
    password: string
    salt: string
    idPhotos?: {
        front: FileId | FileToken
        back: FileId | FileToken
    }
    status: 'pending' | 'approved' | 'rejected'
    phoneVerificationMethod: 'whatsapp' | 'telegram' | 'sms'
    notificationSent?: boolean
}

export const ResetPinCodeRequestTable: Table<ResetPinCodeRequestType> = {
    collectionName: 'resetPinCodeRequests',
}


// export interface UsageSessionType extends BaseFieldsType {
//     _id: UsageSessionId
//     userId: UserId
//     deviceId: DeviceId
//     loginSessionId: LoginSessionId
// }

// export const UsageSessionTable: Table<UsageSessionType> = {
//     collectionName: 'usageSessions'
// }

// interface AccountGroupType extends BaseFieldsType {
//     _id: AccountGroupId
//     name: string
// }

// export const AccountGroupTable: Table<AccountGroupType> = {
//     collectionName: 'accountGroups'
// }

interface LockedBalance {
    amount: Money
    reason: 'order' | 'atmWithdraw'
    orderId?: OrderId
    atmOperationId?: TransactionId
}
export interface AccountType extends BaseFieldsType {
    _id: AccountNumber
    accountingNumber: AccountingNumber
    accountingParentNumber: AccountingParentNumber
    nickname?: string
    userId: UserId
    user?: Pick<UserType, '_id' | 'phone' | 'fullName' | 'photo' | 'groupId'>
    market?: Pick<MarketType, '_id' | 'name' | 'logo' | 'activityType' | 'groupId'>
    marketId?: MarketId
    currency: Currency
    // usable balance = balance - lockedBalance
    balance: Money
    // sent but not executed
    lockedBalance: LockedBalance[]
    // received but not executed, when executed, it is added to balance
    expectedBalance: Money

    accountType: AccountEnumType

    minimumBalance?: Money
    systemAccount?: boolean
    // accountGroup?: AccountGroupId
}

export const AccountTable: Table<AccountType> = {
    collectionName: 'accounts',
    analytics: true,
}

export interface UserMarketPermission {
    userId: UserId

    qrUser: boolean

    transfer: boolean
    buy: boolean
    sell: boolean
    deposit: boolean
    withdraw: boolean
    preformWithdraw: boolean
    preformDeposit: boolean
    redeemGiftCard: boolean
    viewTransactions: boolean
    exchange?: boolean
    manageRemotePayments?: boolean
}

export interface MarketType extends BaseFieldsType {
    _id: MarketId
    userId: UserId
    name: string

    accounts?: Pick<AccountType, '_id' | 'currency' | 'balance' | 'lockedBalance' | 'minimumBalance'>[]

    active: boolean

    address: string
    details: string
    activityType: BusinessActivityTypeId
    subActivityType: SubBusinessActivityTypeId
    // description
    businessType: string
    city: CityId

    appSettings?: Pick<GroupType, 'features' | '_id'>

    approvedTermsOfServiceId: TermsOfServiceId
    fspCurrencyLimits?: Record<Currency, Money>

    users?: UserMarketPermission[]

    logo?: FileId | FileToken
    frontPhoto?: FileId | FileToken
    licensePhoto?: FileId | FileToken

    coordinates?: {
        lat: number
        lng: number
    }

    showInList?: boolean
    showInDepositList?: boolean

    approvedNotificationSent?: boolean

    groupId: GroupId
}

export const MarketTable: Table<MarketType> = {
    collectionName: 'markets',
    analytics: true,
    analyticsGroupMap: (record) => {
        return [{
            groupKey: 'marketId', groupId: record._id, data: {
                ...record,
                $name: record.name,
                $latitude: record.coordinates?.lat,
                $longitude: record.coordinates?.lng,
                $created: record.createdAt,
                // hmm, how to do this
                // $avatar: record.logo,
            }
        }]
    }
}

export type QrLoginRequestId = A.Type<string, 'QrLoginRequestId'>
export interface QrLoginRequestType extends BaseFieldsType {
    _id: QrLoginRequestId
    expiresAt: Date
    liveQrCode: LiveQrCode
    status: 'pending' | 'used'
    token?: string
    device: Client['device']
}

export const QrLoginRequestTable: Table<QrLoginRequestType> = {
    collectionName: 'qrLoginRequests',
}

export interface MovementType extends BaseFieldsType {
    _id: MovementId
    accountId: AccountNumber
    transactionId: TransactionId
    amount: Money
    executedAt: Date
}

export const MovementTable: Table<MovementType> = {
    collectionName: 'movements',
    analytics: true,
}

// transaction is collection of movements summing up to zero
// amount is the total amount subtracted from the from account
//  => amount = amount received + feeFromSender
// from and to are the accounts that the amount is subtracted from and added to with respect to Cashii
// deposit => from ATM, to user
// withdrawal => from user, to ATM

export interface TransactionType extends BaseFieldsType {
    _id: TransactionId
    type: TransactionEnumType

    from: AccountNumber
    fromUser?: Pick<UserType, '_id' | 'phone' | 'fullName' | 'photo'>
    fromMarket?: Pick<MarketType, '_id' | 'name' | 'logo'>
    feeFromSender?: Money
    feeAccountFromSender?: AccountNumber
    cashbackToSender?: Money
    cashbackAccountToSender?: AccountNumber
    // fromDevice?: DeviceId
    senderCashbackTransactionId?: TransactionId

    to: AccountNumber
    toUser?: Pick<UserType, '_id' | 'phone' | 'fullName' | 'photo'>
    toMarket?: Pick<MarketType, '_id' | 'name' | 'logo'>
    feeFromReceiver?: Money
    feeAccountFromReceiver?: AccountNumber
    cashbackToReceiver?: Money
    cashbackAccountToReceiver?: AccountNumber
    // toDevice?: DeviceId
    receiverCashbackTransactionId?: TransactionId

    amount: Money
    currency: Currency

    market?: MarketId
    buyFromMarket?: QrOperationId
    cashMachineId?: CashMachineId

    executedAt: Date

    note?: string

    refundedInTransactionId?: TransactionId | null
    refundForTransactionId?: TransactionId | null
    otherExchangeTransactionId?: TransactionId | null
    orderId?: OrderId | null

    exchangeRate?: ExchangeRate

    giftCardSecret?: string

    senderBalanceAfter: Money
    receiverBalanceAfter: Money

    emailNotificationSent?: boolean

    senderActingUserId: UserId
    senderActingUser?: Pick<UserType, '_id' | 'fullName' | 'systemUser'>
    receiverActingUserId: UserId
    receiverActingUser?: Pick<UserType, '_id' | 'fullName' | 'systemUser'>

    packageInfo?: {
        packageId: PackageId
        provider: 'avea' | 'turkcell' | 'vodafone'
        systemStatus: 'pending' | 'processing' | 'sent' | 'failed' | 'success'
        systemMessage?: string

        // for mobile packages it's the phone number,
        // for utility bills it's the account number of the customer in the utility company
        externalAccount: string
        externalTrackingId?: string | number

        cost: Money
        costToAccount: AccountNumber
        profit: Money
        profitToAccount: AccountNumber
    }
}

export const TransactionTable: Table<TransactionType> = {
    collectionName: 'transactions',
    analytics: true,
    analyticsMap: transaction => {
        if (transaction.type === 'buyFromMarket') {
            if (!transaction.fromUser?._id || !transaction.toUser?._id) {
                return []
            }
            return [
                { record: { ...transaction, marketId: transaction.fromMarket?._id }, event: 'buyFromMarket', actor: transaction.fromUser._id },
                { record: { ...transaction, marketId: transaction.toMarket?._id }, event: 'sell', actor: transaction.toUser._id },
            ]
        }
        if (transaction.type === 'transfer') {
            if (!transaction.fromUser?._id || !transaction.toUser?._id) {
                return []
            }
            return [
                { record: { ...transaction, marketId: transaction.fromMarket?._id }, event: 'transfer', actor: transaction.fromUser._id },
                { record: { ...transaction, marketId: transaction.toMarket?._id }, event: 'receiveTransfer', actor: transaction.toUser._id },
            ]
        }
        const reportSender = [
            'withdraw',
            'buyPackage',
            'exchange',
        ].includes(transaction.type)
        const actor = reportSender ? transaction.fromUser?._id : transaction.toUser?._id
        if (!actor) {
            return []
        }
        const marketId = transaction.fromMarket?._id || transaction.toMarket?._id
        return [{ record: { ...transaction, marketId }, event: transaction.type, actor },]
    },
}

export type QrOperationEnumType = 'deposit' | 'withdraw' | 'buyFromMarket'
export interface QrOperationType extends BaseFieldsType {
    _id: QrOperationId
    type: QrOperationEnumType
    marketId: MarketId
    currency?: Currency
    amount?: Money
    forUserId?: UserId
    forMarketId?: MarketId
    forUser?: Pick<UserType, '_id' | 'phone' | 'fullName' | 'photo'>
    forMarket?: Pick<MarketType, '_id' | 'name' | 'logo'>

    // @deprecated in favor of feeAccountFromSender and feeAccountFromReceiver
    fee?: Money
    // @deprecated in favor of feeAccountFromSender and feeAccountFromReceiver
    feeAccount?: AccountNumber

    executedAt: Date | null
    canceled?: boolean
    from?: AccountNumber
    to?: AccountNumber
    liveQrCode?: LiveQrCode
    transactionId?: TransactionId
    market?: Pick<MarketType, '_id' | 'name' | 'logo'>

    feeFromSender?: Money
    feeAccountFromSender?: AccountNumber
    cashbackToSender?: Money
    cashbackAccountToSender?: AccountNumber

    feeFromReceiver?: Money
    feeAccountFromReceiver?: AccountNumber
    cashbackToReceiver?: Money
    cashbackAccountToReceiver?: AccountNumber

    confirmedEndUser?: boolean

    placedByUserId: UserId
}

export const QrOperationTable: Table<QrOperationType> = {
    collectionName: 'qrOperations',
    analytics: true,
}

export interface RemotePaymentRequestType extends BaseFieldsType {
    _id: RemotePaymentRequestId
    amount: Money
    currency: Currency
    status: 'pending' | 'paid' | 'canceled'

    toUserId: UserId
    toMarketId?: MarketId
    to: AccountNumber

    from?: AccountNumber

    feeFromSender?: Money
    feeAccountFromSender?: AccountNumber
    cashbackToSender?: Money
    cashbackAccountToSender?: AccountNumber

    feeFromReceiver?: Money
    feeAccountFromReceiver?: AccountNumber
    cashbackToReceiver?: Money
    cashbackAccountToReceiver?: AccountNumber

    note?: string
}

export const RemotePaymentRequestTable: Table<RemotePaymentRequestType> = {
    collectionName: 'remotePaymentRequests',
    analytics: true,
}

export interface CashMachineType extends BaseFieldsType {
    _id: CashMachineId
    liveQrCode?: LiveQrCode | null
    systemName: string
    active: boolean
    accessibleBy: UserId[]

    accounts: {
        [currency in Currency]: AccountNumber
    }

    type: 'ATM' | 'teller'
    modelNumber?: 'smart payout'

    // used only for teller
    currentOperation?: {
        operationId: TransactionId
        startedAt: Date
        type: 'deposit' | 'withdraw'
        amount?: Money
        currency?: Currency
        // account: AccountNumber
        user: Pick<UserType, '_id' | 'phone' | 'fullName'>
        market?: Pick<MarketType, '_id' | 'name'>
        groupId: GroupId
        executedAt?: Date
        fee?: Money
        feeAccount?: AccountNumber
        account?: AccountNumber
        placedByUserId: UserId
    } | null

    atm?: {
        channels: { value: Money, storedInPayout: number, storedInCashbox: number, currency: Currency, enabled: boolean, route: 'cashbox' | 'payout' }[]
        reportedChannels: { value: Money, storedInPayout: number, currency: Currency }[]
        commander: 'atm' | 'server'
        status: 'notReady' | 'qr' | 'deposit' | 'withdraw' | 'success' | 'partialSuccess' | 'error' | 'errorDuringOperation' | 'operationNotInService'

        step:
        | 'notSet'
        | 'payout' | 'payoutInProgress'/*  | 'payoutConfirmed' */
        | 'acceptMoney' | 'finishAcceptingMoney'
        | 'syncingChannels'

        commandId?: TransactionId
        feeRate?: PerThousand
        feeAccount?: AccountNumber
        payoutCommands: { number: number, currency: Currency, value: Money, executed: number }[]
        deposit: { value: Money, currency: Currency, position: 'cashbox' | 'payout' }[]

        user: Pick<UserType, '_id' | 'phone' | 'fullName' | 'photo'>
        market?: Pick<MarketType, '_id' | 'name' | 'logo'>
        accounts: Pick<AccountType, '_id' | 'balance' | 'currency' | 'minimumBalance'>[]
        lastReadyAt?: Date
        placedByUserId: UserId

        requestedWithdrawAmount?: Money
    }
}

export const CashMachineTable: Table<CashMachineType> = {
    collectionName: 'cashMachines',
    analytics: true,
}

export interface PackageContent {
    packageDurationInDays?: number
    smsAllowance?: number
    internetGBAllowance?: number
    localVoiceAllowance?: number
    internationalVoiceAllowance?: number
}

export interface PackageType extends BaseFieldsType {
    _id: PackageId
    provider: 'avea' | 'turkcell' | 'vodafone'

    name: string
    description?: string
    localizedName?: string

    cost: Money
    price: Money
    currency: Currency

    type: 'balance' | 'packet'
    operatorPackageType: 'tam' | 'ses'
    balance: number
    durationInDays: number
    internetGb?: number
    internetMb?: number
    dk: number
    sms: number
    code: number

    active: boolean
    costToAccount: AccountNumber
    profitToAccount: AccountNumber
}

export const PackageTable: Table<PackageType> = {
    collectionName: 'packages',
    analytics: true,
}

export interface CachedAvailablePackagesType extends BaseFieldsType {
    _id: CachedAvailablePackagesId
    packages: PackageId[]
    phone: string
}

export const CachedAvailablePackagesTable: Table<CachedAvailablePackagesType> = {
    collectionName: 'cachedAvailablePackages',
    analytics: true,
}

export interface SavedUserType extends BaseFieldsType {
    _id: SavedUserId
    belongsToUser: UserId
    userId?: UserId
    marketId?: MarketId

    // cached data
    user?: Pick<UserType, '_id' | 'fullName' | 'phone' | 'photo'>
    market?: Pick<MarketType, '_id' | 'name' | 'logo'>
}

export const SavedUserTable: Table<SavedUserType> = {
    collectionName: 'savedUsers',
    analytics: true,
}

export interface NotificationType extends BaseFieldsType {
    message: string
    scheduledAt: Date
    sentAt: Date | null
    status: NotificationStatus
}

export interface TelegramNotificationType extends NotificationType {
    _id: TelegramNotificationId
    phone: string
}

export const TelegramNotificationTable: Table<TelegramNotificationType> = {
    collectionName: 'telegramNotifications'
}

export interface TelegramTokenType extends BaseFieldsType {
    _id: TelegramTokenId
    phone: string
    chatId: number
    deactivatedAt: Date | null
}

export const TelegramTokenTable: Table<TelegramTokenType> = {
    collectionName: 'telegramTokens'
}

export interface OfferType extends BaseFieldsType {
    _id: OfferId
    title: string
    description: string
    icon: string
    iconFamily: 'material' | 'material-community' | 'cashii'
    iconColor: string
    tag: string
    active: boolean
    groups: GroupId[]
}

export const OfferTable: Table<OfferType> = {
    collectionName: 'offers',
    analytics: true,
}

export interface GiftCardType extends BaseFieldsType {
    _id: GiftCardId
    secret: string
    amount: Money
    currency: Currency
    active: boolean
    redeemedAt: Date | null
    redeemedByTransactionId: TransactionId | null
    redeemedToAccount: AccountNumber | null
    parentAccount: AccountNumber
    expiresAt: Date | null

    externalVoucherData?: {
        walletId: VoucherWalletId
        topUpId: string
    }
}

export const GiftCardTable: Table<GiftCardType> = {
    collectionName: 'giftCards'
}

export type VoucherWalletId = A.Type<string, 'VoucherWalletId'>
export interface VoucherWalletType extends BaseFieldsType {
    _id: VoucherWalletId
    token: string
    drawFromAccount: AccountNumber
    active: boolean
    syncedUpTo?: string
    currency: Currency
}

export const VoucherWalletTable: Table<VoucherWalletType> = {
    collectionName: 'voucherWallets'
}

export type TermsOfServiceId = A.Type<string, 'TermsOfServiceId'>
export interface TermsOfServiceType extends BaseFieldsType {
    _id: TermsOfServiceId
    // type: 'personal' | 'market'
    text: string
}

export const TermsOfServiceTable: Table<TermsOfServiceType> = {
    collectionName: 'termsOfServices'
}

// initial credit
// cashback
// referral
// buy package
// identity verification
// 2fa

// 1 = 10000
// type ExchangeRate = A.Type<number, 'MoneyPrice'>
export type ExchangeRate = Money
export interface OrderType extends BaseFieldsType {
    _id: OrderId

    userId: UserId
    marketId: MarketId | undefined

    orderBookId: OrderBookId

    accountId: AccountNumber
    baseAccountId: AccountNumber

    currency: Currency // (USD)
    baseCurrency: Currency // (TRY)

    // always full number (we don't process cents for now)
    amount: Money // always in currency (USD)
    filledAmount: Money
    remainingAmount: Money
    canceledAmount: Money
    lockedAmount: Money

    price: ExchangeRate // always in base currency (TRY => 1 USD = 20 TRY => price = 20)

    feeRate: PerThousand
    feeAccount: AccountNumber

    type: 'buy' | 'sell'
    status: 'open' | 'filled' | 'partially-filled' | 'canceled' | 'canceled-partially-filled'

    baseTransactionId: TransactionId | null
    secondTransactionId: TransactionId | null
    finalFee?: Money

    mode: 'simple' | 'advanced'
    placedByUserId: UserId
}

export const OrderTable: Table<OrderType> = {
    collectionName: 'orders',
    analytics: true,
}

export interface OrderFillType extends BaseFieldsType {
    _id: OrderFillId
    sellOrderId: OrderId
    buyOrderId: OrderId
    orderBookId: OrderBookId

    currency: Currency
    baseCurrency: Currency
    amount: Money
    price: ExchangeRate

    actingSide: 'buy' | 'sell'
}

export const OrderFillTable: Table<OrderFillType> = {
    collectionName: 'orderFills',
    analytics: true,
}

export interface OrderBookType extends BaseFieldsType {
    _id: OrderBookId

    currency: Currency
    baseCurrency: Currency

    accountId: AccountNumber
    baseAccountId: AccountNumber
}

export const OrderBookTable: Table<OrderBookType> = {
    collectionName: 'orderBooks'
}

export type Feature =
    | 'allTransactions'
    | 'requestMarketAccount'
    | 'exchange'
    | 'deposit'
    | 'withdraw'
    | 'preformWithdraw'
    | 'preformDeposit'
    | 'buy'
    | 'sell'
    | 'buyPackage'
    | 'transferBetweenAccounts'
    | 'transferToOtherUsers'
    | 'redeemGiftCard'
    | 'referral'
    | 'manageRemotePayments'

export type GroupId = A.Type<string, 'GroupId'>
export interface GroupType extends BaseFieldsType {
    _id: GroupId
    name: string

    features: Record<Feature, boolean>
}

export const GroupTable: Table<GroupType> = {
    collectionName: 'groups',
    analytics: true,
}
export type BusinessActivityTypeId = A.Type<string, 'BusinessActivityTypeId'>
export interface BusinessActivityTypeType extends BaseFieldsType {
    _id: BusinessActivityTypeId
    name: string
    assignToGroup: GroupId
    listed: boolean
}

export const BusinessActivityTypeTable: Table<BusinessActivityTypeType> = {
    collectionName: 'businessActivityTypes',
}
export type CityId = A.Type<string, 'CityId'>
export interface CityType extends BaseFieldsType {
    _id: CityId
    name: string
    listed: boolean
}

export const CityTable: Table<CityType> = {
    collectionName: 'cities',
}

export type SubBusinessActivityTypeId = A.Type<string, 'SubBusinessActivityTypeId'>
export interface SubBusinessActivityTypeType extends BaseFieldsType {
    _id: SubBusinessActivityTypeId
    name: string
    parentActivityTypeId: BusinessActivityTypeId
    assignToGroup: GroupId
    listed: boolean
}

export const SubBusinessActivityTypeTable: Table<SubBusinessActivityTypeType> = {
    collectionName: 'subBusinessActivityTypes',
    analytics: true,
}

export type AppNotificationId = A.Type<string, 'NotificationId'>
export interface AppNotificationType extends BaseFieldsType {
    _id: AppNotificationId
    userId: UserId
    marketId: MarketId | undefined
    targetId: UserId | MarketId

    // used in both app and push notification
    title?: string
    body?: string

    // used only in email
    emailSubject?: string
    emailBody?: string

    // used only in sms
    smsBody?: string

    // used only in app
    icon: string
    iconFamily: string
    iconColor: string
    read: boolean

    // used only in push notification
    image?: string
    link?: string

    inAppNotification: boolean

    smsNotification: boolean
    smsNotificationSentAt?: Date

    emailNotification: boolean
    emailNotificationSentAt?: Date

    pushNotification: boolean
    pushNotificationSentAt?: Date
    pushNotificationChannelId: string
}

export const AppNotificationTable: Table<AppNotificationType> = {
    collectionName: 'appNotifications',
    analytics: true,
}

export type GroupNotificationId = A.Type<string, 'GroupNotificationId'>
export interface GroupNotificationType {
    _id: GroupNotificationId
    groups: GroupId[]

    // used in both app and push notification
    title: string
    body: string

    // used only in email
    emailSubject: string
    emailBody: string

    // used only in sms
    smsBody: string

    // used only in app
    icon: string
    iconFamily: string
    iconColor: string

    // used only in push notification
    image?: string
    link?: string

    active: boolean
    notifiedAt?: Date

    pushNotification: boolean
    pushNotificationChannelId: string

    emailNotification: boolean
    smsNotification: boolean
    inAppNotification: boolean
}

export const GroupNotificationTable: Table<GroupNotificationType> = {
    collectionName: 'groupNotifications',
    analytics: true,
}

export const FeeableOperationSenderSide = [
    'withdrawFromOurOffice',
    'withdrawFromAtm',
    'withdrawFromMarket',
    'depositInMarket',
    'buyFromMarket',
    'exchange',
    'buyPackage',
    'transfer',
    'transferBetweenMyAccounts',
    // 'remotePayment',
] as const

export const FeeableOperationReceiverSide = [
    'depositInOurOffice',
    'depositInAtm',
    'withdrawFromMarket',
    'depositInMarket',
    'buyFromMarket',
    // 'redeemGiftCard',
    'remotePayment',
] as const

export const FeeableOperationsAllGroupsSenderSide = [
    'buyFromMarket',
    'transfer',
    // 'remotePayment',
] as const
export const FeeableOperationsAllGroupsReceiverSide = [
    // 'buyFromMarket',
    // 'remotePayment',
] as const

export const FeeableOperations = [
    'depositInOurOffice',
    'withdrawFromOurOffice',

    'depositInAtm',
    'withdrawFromAtm',

    'depositInMarket',
    'withdrawFromMarket',

    'buyFromMarket',
    'exchange',
    'buyPackage',
    // 'redeemGiftCard',
    'transfer',
    'transferBetweenMyAccounts',
    'remotePayment',
] as const

export type FeeableOperation = typeof FeeableOperations[number]

export const FeeableSide = ['sender', 'receiver'] as const
export type FeeableSide = typeof FeeableSide[number]

export type FeesAndLimitsId = A.Type<string, 'FeesAndLimitsId'>
export interface FeesAndLimitsType extends BaseFieldsType {
    _id: FeesAndLimitsId

    senderGroup: GroupId | 'default'
    receiverGroup: GroupId | 'default'
    side: FeeableSide
    operation: FeeableOperation
    currency: Currency

    feePercentage: PerThousand
    minimumFee: Money
    maximumFee?: Money

    minLimitPerOperation?: Money
    maxLimitPerOperation?: Money

    maxLimitPerDay?: Money

    cashbackPercentage: PerThousand
    maxDailyCashback?: Money

    feeAccount: AccountNumber
    cashbackAccount: AccountNumber
}

export const FeesAndLimitsTable: Table<FeesAndLimitsType> = {
    collectionName: 'feesAndLimits',
}


const allTables = [
    UserTable,
    DeviceTable,
    LoginSessionTable,
    // UsageSessionTable,
    // AccountGroupTable,
    MarketTable,
    AccountTable,
    MovementTable,
    TransactionTable,
    QrOperationTable,
    CashMachineTable,
    PackageTable,
    SavedUserTable,
    CachedAvailablePackagesTable,
    TelegramNotificationTable,
    TelegramTokenTable,
    OfferTable,
    GiftCardTable,
    TermsOfServiceTable,
    VoucherWalletTable,
    OrderTable,
    OrderFillTable,
    OrderBookTable,
    GroupTable,
    AppNotificationTable,
    GroupNotificationTable,
    BusinessActivityTypeTable,
    FeesAndLimitsTable,
    SubBusinessActivityTypeTable,
    ResetPinCodeRequestTable,
    CityTable,
    RemotePaymentRequestTable,
    QrLoginRequestTable,
]

allTables.forEach((table) => addTable(table))
