import { useEffect } from 'react'

export default function useBeforeUnloadWarning(args: { shouldRun: boolean; title: string }): void {
    const { shouldRun, title } = args

    useEffect(() => {
        if (!shouldRun) {
            return
        }

        const handler = (event: BeforeUnloadEvent): void => {
            event.returnValue = title
        }

        window.addEventListener('beforeunload', handler)

        return () => {
            window.removeEventListener('beforeunload', handler)
        }
    }, [shouldRun, title])
}
