/* eslint-disable @typescript-eslint/no-explicit-any */
import pf from '@hypatia/utils/promise/pf'
import { getRpcCaller } from './rpcCaller'

type PfT<T> = {
    [K in keyof T]: {
        [K2 in keyof T[K]]: T[K][K2] extends (...args: infer Args) => Promise<[any, infer R | undefined]>
            ? (...args: Args) => Promise<R>
            : never
    }
}
export function getRPCsCallersPf<T, NS extends string>(ns: NS): PfT<T> {
    function createSubProxy(prop: string): any {
        return new Proxy(
            {},
            {
                get: (target, subProp) => {
                    return (...args: any[]) => {
                        return pf((getRpcCaller as any)(`${ns}.${prop}.${subProp as string}`)(args))
                    }
                },
            }
        )
    }
    return new Proxy(
        {},
        {
            get: (target, prop) => {
                return createSubProxy(prop as string)
            },
        }
    ) as unknown as PfT<T>
}
export default function getRPCsCallers<T, NS extends string>(ns: NS): T {
    function createSubProxy(prop: string): any {
        return new Proxy(
            {},
            {
                get: (target, subProp) => {
                    return (...args: any[]) => {
                        return (getRpcCaller as any)(`${ns}.${prop}.${subProp as string}`)(args)
                    }
                },
            }
        )
    }
    return new Proxy(
        {},
        {
            get: (target, prop) => {
                return createSubProxy(prop as string)
            },
        }
    ) as unknown as T
}
