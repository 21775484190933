import isReactNative from '@hypatia/utils/os/isReactNative'
import { deleteTokens } from '@hypatia/serverer-client/notifications/notifications'
import { asyncStorage } from '@hypatia/utils/async-storage'
import setPathName from '@hypatia/utils/window/setPathName'
import analytics from '../analytics/Analytics'

export default async function logout(options?: { keepPath?: boolean; redirectTo?: string }): Promise<void> {
    deleteTokens()
    await asyncStorage.clear()
    const isNative = isReactNative()
    if (isNative) {
        return
    }

    await asyncStorage.clear()
    analytics.reset()

    if (!options?.keepPath) {
        setPathName(options?.redirectTo || '')
    }
}
