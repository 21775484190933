import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function required(customMessage?: ValidationMessage): FieldValidation {
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.thisFieldIsRequired'),
        validate: ({ value }) => {
            switch (typeof value) {
                case 'string':
                    return value.trim() !== ''
                case 'number':
                    return value != null
                default:
                    return value != null
            }
        },
    }
}
