import { useLocation } from '@hypatia/react-utils/router/LocationContext'
import React from 'react'
import { A } from '../Home/A'
import classes from './CashMachinePage.module.css'
import useDeliverLast from '@hypatia/react-utils/hooks/useDeliverLast'
import { CashMachineId } from '@cashii/common/src/db/db'
import clientLiveQueries from '@cashii/common/src/queries/clientLiveQueries'

interface Props {
    cashMachineId: CashMachineId
}
export default React.memo(CashMachine)
function CashMachine(props: Props): JSX.Element {
    const { cashMachineId } = props
    const [machines] = useDeliverLast(() => clientLiveQueries.cashMachineInfo(cashMachineId), [cashMachineId])
    const machine = machines?.[0]
    const { pathname } = useLocation()
    return (
        <div>
            <ul className={classes.list}>
                {machine?.type === 'ATM' ? (
                    <>
                        <li>
                            <A target="_blank" rel="noreferrer" href={pathname + '/atm'}>
                                atm
                            </A>
                        </li>
                        <li>
                            <A target="_blank" rel="noreferrer" href={pathname + '/accounts'}>
                                accounts
                            </A>
                        </li>
                    </>
                ) : (
                    <>
                        <li>
                            <A target="_blank" rel="noreferrer" href={pathname + '/operator'}>
                                operator
                            </A>
                        </li>
                        <li>
                            <A target="_blank" rel="noreferrer" href={pathname + '/live-qr'}>
                                live-qr
                            </A>
                        </li>
                    </>
                )}
            </ul>
        </div>
    )
}
