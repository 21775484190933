/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList } from 'react'
import useAsync from './useAsync'

type ResultAsArray<V, E> = [undefined, true, undefined] | [V, false, undefined] | [undefined, false, E]

type Either<V, E> = [E, undefined] | [undefined, V]
type FpPromise<V, E> = Promise<Either<V, E>>

export default function useAsyncFp<V, E>(
    promiseGetter: FpPromise<V, E> | (() => FpPromise<V, E> | undefined) | undefined,
    deps: DependencyList
): ResultAsArray<V, E>

export default function useAsyncFp<V, E>(
    promiseGetter: FpPromise<V, E> | (() => FpPromise<V, E> | undefined) | undefined,
    loadingList: boolean[],
    deps: DependencyList
): ResultAsArray<V, E>

export default function useAsyncFp<V, E>(
    promiseGetter: FpPromise<V, E> | (() => FpPromise<V, E> | undefined) | undefined,
    second: boolean[] | DependencyList,
    third?: DependencyList
) {
    const [[err, res] = [], loading] = useAsync<Either<V, E>, undefined>(promiseGetter, second as any, third as any)
    return [res, loading, err]
}
