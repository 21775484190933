import _throttle from 'lodash/throttle'
import { AnySourceStream, Stream } from '../stream'
import { Pass } from './pass'

export class Throttle<V, E = never, C = never, T = V> extends Pass<V, E, C> {
    protected last: T | undefined

    constructor(
        protected override source?: AnySourceStream<V, E, C>,
        protected wait?: number,
        protected options?: { leading?: boolean; trailing?: boolean }
    ) {
        super(source)
        this.throttledValue = _throttle(this._value, wait, options)
    }

    throttledValue

    override value(value: V): void {
        this.throttledValue(value)
    }
}

declare module '../stream' {
    // eslint-disable-next-line no-shadow
    interface Stream<SV, SE = never, SC = never, DV = SV, DE = SE, DC = SC> {
        throttle(wait?: number, options?: { leading?: boolean; trailing?: boolean }): MirrorStream<DV, DE, DC>
    }
}

Stream.prototype.throttle = function <V, E, C>(
    this: AnySourceStream<V, E, C>,
    wait?: number,
    options?: { leading?: boolean; trailing?: boolean }
) {
    return new Throttle(this, wait, options) // tslint:disable-line:no-invalid-this
}
