import createPromise from '@hypatia/utils/promise/createPromise'
import { DeliverLast } from '@hypatia/utils/streams/operators/deliver-last'
import { useRef } from 'react'
import { SoftSuspenseContext } from './SoftSuspense'
import { useContext } from './useContextSelector'
import { useEffectRightNow } from './useEffectRightNow'
import { useStateWaitForMounting } from './useStateWaitForMounting'
import { UseStreamResultAsObject } from './useStreamOrPromise'

export interface StreamConfig<V, E, C> {
    throwIfError?: boolean
    suspense?: boolean
    onValue?: (value: V) => void
    onError?: (error: E) => void
    onComplete?: (complete: C) => void
}

export default function useDeliverLastOld<V, E, C>(
    stream: DeliverLast<V, E, C> | undefined,
    { suspense = false, throwIfError = false, onComplete, onError, onValue } = {} as StreamConfig<V, E, C>
): UseStreamResultAsObject<V, E, C> {
    const suspenseFn = useContext(SoftSuspenseContext)
    const [, /* state */ setThrowableError] = useStateWaitForMounting()

    // eslint-disable-next-line prefer-const
    let [results, setResults] = useStateWaitForMounting<UseStreamResultAsObject<V, E, C>>(
        stream?.connect && stream.received
            ? {
                  loading: false,
                  status: 'running',
                  value: stream.last!,
                  complete: undefined,
                  error: undefined,
              }
            : {
                  loading: true,
                  status: 'loading',
                  complete: undefined,
                  error: undefined,
                  value: undefined,
              }
    )

    const promiseRef = useRef<{ promise: Promise<any>; resolve: () => void } | null>()

    // for suspending only
    if (suspense && results.status === 'loading' && (!stream || !stream.received)) {
        if (!stream) {
            if (!promiseRef.current) {
                const obj = createPromise()
                promiseRef.current = obj

                // calling suspense too early is causing react to warn against a child updating its parent state
                setTimeout(() => {
                    suspenseFn(promiseRef.current!.promise)
                }, 0)
            }
        } else {
            const promise = stream.getLast()
            if (promiseRef.current) {
                promise.then(() => promiseRef.current!.resolve())
            } else {
                // calling suspense too early is causing react to warn against a child updating its parent state
                setTimeout(() => {
                    suspenseFn(promise)
                }, 0)
            }
        }
    }

    useEffectRightNow(() => {
        if (!stream) {
            return
        }

        return stream.subscribe({
            value: (value) => {
                onValue?.(value)

                results = {
                    ...results,
                    loading: false,
                    status: 'running',
                    value: value,
                } as UseStreamResultAsObject<V, E, C>

                setResults(
                    (currentResults) =>
                        ({
                            ...currentResults,
                            loading: false,
                            status: 'running',
                            value: value,
                        } as UseStreamResultAsObject<V, E, C>)
                )
            },
            error: (error) => {
                if (process.env.development) {
                    // eslint-disable-next-line no-console
                    console.warn(error)
                }
                if (throwIfError) {
                    setThrowableError(() => {
                        throw error
                    })
                }
                onError?.(error)
                setResults(
                    (currentResults) =>
                        ({
                            ...currentResults,
                            loading: false,
                            status: 'error',
                            error,
                        } as UseStreamResultAsObject<V, E, C>)
                )
            },
            complete: (complete) => {
                onComplete?.(complete)
                setResults(
                    (currentResults) =>
                        ({
                            ...currentResults,
                            loading: false,
                            status: 'completed',
                            complete,
                        } as UseStreamResultAsObject<V, E, C>)
                )
            },
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stream])

    return results
}
