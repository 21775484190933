import { createContext, useContext } from '../hooks/useContextSelector'
import generateUniqueId from '@hypatia/utils/generators/generateUniqueId'
import React, { useCallback, useMemo, useState } from 'react'

interface Props {
    children?: JSX.Element
}
interface GlobalLoading {
    loading: boolean
    addPromise: <T>(promise: Promise<T>) => Promise<T>
}

const GlobalLoadingContext = createContext<GlobalLoading>()

export function useGlobalLoading(): GlobalLoading {
    return useContext(GlobalLoadingContext)
}

export default function GlobalLoadingProvider({ children }: Props): JSX.Element {
    const [loadingList, setLoadingList] = useState([] as string[])
    const addPromise = useCallback(<T,>(promise: Promise<T>) => {
        const id = generateUniqueId()
        setLoadingList((list) => [...list, id])
        promise.finally(() => {
            setLoadingList((list) => list.filter((x) => x !== id))
        })
        return promise
    }, [])

    const loading = loadingList.length > 0
    const value = useMemo(() => ({ loading, addPromise }), [addPromise, loading])

    return <GlobalLoadingContext.Provider value={value}>{children}</GlobalLoadingContext.Provider>
}
