import deleteFCMToken from '@hypatia/serverer-common/notifications/rpc/deleteFCMToken'
import { asyncStorage } from '@hypatia/utils/async-storage'
import { captureException } from '@sentry/core'
import uniq from 'lodash/uniq'

export async function saveToken(token: string) {
    await deleteTokens(token)
    const tokens: string[] = JSON.parse((await asyncStorage.get('pushNotificationsTokens')) ?? '[]')
    await asyncStorage.set('pushNotificationsTokens', JSON.stringify(uniq([...tokens, token])))
}

export async function deleteTokens(exceptionToken?: string) {
    try {
        const tokens = uniq(JSON.parse((await asyncStorage.get('pushNotificationsTokens')) ?? '[]') as string[])
        if (exceptionToken) {
            tokens.splice(tokens.indexOf(exceptionToken), 1)
        }
        if (!tokens.length) {
            return
        }
        
        Promise.all(
            tokens.map(async (token: string) => {
                await deleteFCMToken({ token })
            })
        )
    } catch (error) {
        captureException(error)
    } finally {
        await asyncStorage.remove('pushNotificationsTokens')
    }
}
