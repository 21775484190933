import getDefaultLocale from '@hypatia/utils/localization/getDefaultLocale'
import getDirFromLocale from '@hypatia/utils/localization/getDirFromLocale'
import { DirectionType, LocaleType } from '@hypatia/utils/localization/locales'
import SettableValue from './SettableValue'

export type ThemeMode = 'light' | 'dark'
export interface BaseSettings {
    locale?: LocaleType
    direction?: DirectionType
    themeMode?: ThemeMode
}
const defaultLang = getDefaultLocale()
const defaultSettings: BaseSettings = {
    locale: defaultLang,
    direction: getDirFromLocale(defaultLang),
    themeMode: 'light',
}

export const defaultUserSettings = new SettableValue<BaseSettings>('defaultUserSettings')

defaultUserSettings.set(defaultSettings)
