import isObject from 'lodash/isObject'
import mapValues from 'lodash/mapValues'
import { isEqualShallow } from '../comparators/is-equal-shallow'

export default function replaceWithEqual<T extends Record<string, any>>(
    newObj: T | undefined,
    oldObj: T | undefined
): T | undefined {
    if (!newObj || !oldObj || newObj === oldObj) {
        return newObj
    }

    const mappedNewObj: T = mapValues(newObj, (value, key) => {
        if (isObject(value)) {
            return replaceWithEqual(value, oldObj[key])
        }

        return value
    })

    if (isEqualShallow(mappedNewObj, oldObj)) {
        return oldObj
    }

    return mappedNewObj
}
