import { ClientChangeEvent } from '@hypatia/serverer-common/database/ClientChangeEvent'

export default function queriesStreamsUpdater(dbEvent: ClientChangeEvent, docs: any[]) {
    switch (dbEvent.operationType) {
        case 'delete': {
            return handleDeletedDocumentFromQuery(dbEvent.docId, docs)
        }
        case 'insert': {
            return handleInsertedDocumentFromQuery(dbEvent.docId, dbEvent.fullDocument!, docs)
        }
        case 'update': {
            return handleUpdatedDocumentFromQuery(dbEvent.docId, dbEvent.fullDocument!, docs)
        }
    }
}

const handleUpdatedDocumentFromQuery = (docId: string, document: any, docs: any[]) => {
    const docIdx = docs.findIndex((doc) => doc._id == docId)

    const alreadyExistsDoc = docIdx !== -1

    if (alreadyExistsDoc) {
        return [...docs.slice(0, docIdx), document, ...docs.slice(docIdx + 1)]
    } else {
        return [...docs, document]
    }
}

const handleInsertedDocumentFromQuery = (docId: string, document: any, docs: any[]) => {
    const existingDoc = docs.find((d) => d._id === docId)

    if (existingDoc) {
        const newDocs = docs.filter((d) => d._id !== docId)
        return [...newDocs, { ...existingDoc, ...document }]
    }

    return [...docs, document]
}

const handleDeletedDocumentFromQuery = (docId: string, docs: any[]) => docs.filter((doc) => doc._id !== docId)
