import { FieldValidation, ValidationMessage } from './types'
import { getCustomizedMessage } from './getCustomizedMessage'

export default function phone(customMessage?: ValidationMessage): FieldValidation {
    // phone number regex must be starting with + and followed by 10 digits at least and 15 digits at most and can contain spaces
    // +90 555 555 55 55
    const re = /^\+?\d{10,15}$/
    return {
        message: getCustomizedMessage(customMessage, 'validationErrors.phone'),
        validate: ({ value }) => {
            if (!value) {
                return true
            }
            switch (typeof value) {
                case 'string':
                    return re.test(value)
                default:
                    return false
            }
        },
    }
}
